import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../Animations/Alerts/AlertSlicer';
import Display_DB_Notifications from './Display_DB_Notifications';
import { RootState } from '../../App/store';
import FireBase_Notification from './FireBase_Notification';
import isDevMode from '../../Functionalities/smallFns';





export default function FinalLoader() {
  const isAuthenticated = useSelector( ( state: RootState ) => state.UserInfo.isAuthenticated )
  const isHotelOwner = useSelector( ( state: RootState ) => state.UserInfo.isHotelOwner )
  const isDeliveryBoy = useSelector( ( state: RootState ) => state.UserInfo.isDeliveryBoy )

  const isdevMode = isDevMode()
  const dispatch = useDispatch()
  //Function to load razorpay script for the display of razorpay payment SDK.
  function loadScript( src: any ) {
    return new Promise( ( resolve ) => {
      const script = document.createElement( "script" );
      script.src = src;
      script.onload = () => {
        resolve( true );
      };
      script.onerror = () => {
        resolve( false );
      };
      document.body.appendChild( script );
    } );
  }
  async function loadMiscellanousData() {
    axios.get( "/static/audio/pop_successNotification_sound.mp3" )
    axios.get( "/static/audio/pop_InfoNotification_sound.mp3" )
    // axios.get("static/audio/pop_ErrorNotification_sound.mp3")
    return
  }
  //function will get called when clicked on the pay button.
  async function loadRazorpayPaymentSdk() {
    const RazorPayScript = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    const QR_code_Script = await loadScript(
      "https://unpkg.com/qr-code-styling@1.5.0/lib/qr-code-styling.js"
    );

    if ( !RazorPayScript ) {
      dispatch( Alert( { "status": "error", "heading": "Network error", "message": "Check internet connectivity, and refresh the page" } ) )
      return;
    }
    loadMiscellanousData()
  }

  async function load_QR_Code_library() {//250KB
    const QR_code_Script = await loadScript(
      "https://unpkg.com/qr-code-styling@1.5.0/lib/qr-code-styling.js"
    );

    if ( !QR_code_Script ) {
      dispatch( Alert( { "status": "error", "heading": "Network error", "message": "Check internet connectivity, and refresh the page" } ) )
      return;
    }
  }




  
  if ( isAuthenticated ) {
    loadRazorpayPaymentSdk()
  }
  if ( isHotelOwner || isDeliveryBoy) {
    load_QR_Code_library()
  }
  return (
    <>
      {isAuthenticated && <Display_DB_Notifications />}
      {isAuthenticated && !isdevMode && window.location.hostname == "www.kallardo.com" && <FireBase_Notification />}
    </>
  )
}



