import React, { useState }  from 'react'
import { useDispatch } from 'react-redux';



import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';


import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { add } from 'date-fns'
import { timeFormatter24HR_for_api } from '../../../Functionalities/smallFns';

function HotelTimePicker({setInputs, timeType, initialTime}:any) {
    
    const todayDate = new Date();
    const [pickUpTime, setPickUpTime] = useState<dayjs.Dayjs|null| Date>(initialTime);
    
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className='scale-75 -ml-5 '>
        <MobileTimePicker
          label={timeType}
          value={new Date(initialTime)}
          onChange={(newValue) => {
            console.log(newValue);
            
            setPickUpTime(newValue)//For this JSX component
            let strValue = newValue?.toString()+""
            console.log("strValue - "+strValue);
            

            setInputs((values: any) => ({...values,
               [timeType==="Open Time"?"autoOpenTime":"autoCloseTime"]: (new Date(strValue)).toISOString()}))// For api Data
            }
          }
        //   disabled={isBefore(_4_30_PM, todayDate)}//Before 30min
          minutesStep={5}
        //   minTime={dayjs(_30_Min_later)}
        //   maxTime={dayjs(_5_01_PM)}// Let always be +1 min .. So the minutes Step dont get affected
          renderInput={(params) => <TextField {...params} />}
        />
        </div>
      
    </LocalizationProvider>
  );
}
export default HotelTimePicker