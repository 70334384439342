import React, { useEffect, useState } from 'react';


import { motion } from 'framer-motion';
import PersonIcon from '@mui/icons-material/Person';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AvTimerRoundedIcon from '@mui/icons-material/AvTimerRounded';
import { usePutReq } from '../../../../CustomHooks/putReq';
import RefreshWindow from '../../../../Functionalities/RefreshWindow';
import { timeFormatter12HR, timeFormatterDate } from '../../../../Functionalities/smallFns';
import DisplayCard from '../../../../Components/Card/DisplayCard';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { PopConstants } from '../../../../Animations/PopUp/PopUpManagerConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import PlaceIcon from '@mui/icons-material/Place';
import { getRestaurantOrderStatus } from '../../../../Functionalities/Formatter/StatusFormatter';



export default function HotelDashBoardCardList( { id, hotel_QR_OTP, processedFoodList, orderedTime,
  requestedPickupTime, status, userName, profit, img, address_Line_1, address_Line_2, pincode,
  latitude, longitude, isPickUp, isDelivery, isDineIn }: any ) {

  const [loadImage, setloadImage] = useState( false )


  const date = timeFormatterDate( orderedTime )
  const _orderedTime = timeFormatter12HR( orderedTime )
  let _requestedPickupTime = timeFormatter12HR( requestedPickupTime )
  useEffect( () => {
    // code to run after render goes here
    setloadImage( true )
  } );

  return (
    <DisplayCard Head={
      <>
        <div className=" bg-no-repeat bg-cover bg-center bg-blend-soft-light  bg-blue-800   w-full h-56 text-8xl tracking-widest text-center text-white dark:text-gray-100 flex justify-center items-center" style={{
          backgroundImage: `url(${ loadImage ? img : "" })`
        }}>
          {( '' + ( id % 1000 ) ).padStart( 2, '0' )}
        </div>
        <div className="flex items-center px-6 py-3 bg-neutral-900">
          <PersonIcon sx={{ color: "#fff" }} />

          <h3 className="mx-3 text-lg font-semibold text-white">{userName}</h3>
        </div>
      </>

    } BodyTop={

      <>
        <div className='right-0 absolute dark:text-gray-100 pr-5 '>
          {date}
        </div>
        <div className='text-gray-800 dark:text-white'>
          <StatusButton id={id} hotel_QR_OTP={hotel_QR_OTP} isDineIn={isDineIn} isPickUp={isPickUp}
            isDelivery={isDelivery} latitude={latitude} longitude={longitude} /><div className=''>
            <p className="text-xl font-semibold text-gray-800 dark:text-white">
              {getRestaurantOrderStatus(status , isPickUp, isDineIn, isDelivery )}
              </p>

            <p className="py-2 text-gray-700 dark:text-gray-400 whitespace-pre">{processedFoodList}</p>
          </div>

        </div>


      </>

    } BodyLow={
      <div className='text-gray-700 dark:text-gray-200'>

        <div className="flex items-center mt-4 ">
          <AccessTimeRoundedIcon />
          <p className="px-2 text-sm"><b>Ordered Time :</b> {_orderedTime}</p>
        </div>
        {
          (isPickUp || isDineIn) &&
          <div className="flex items-center mt-4 ">
            <AvTimerRoundedIcon />
            <p className="px-2 text-sm"><b>Requested Time :</b> {_requestedPickupTime}</p>
          </div>
        }
        {
          isDelivery &&
          <div className="flex items-center mt-4 ">
            <AvTimerRoundedIcon />
            <p className="px-2 text-sm"><b>Address :</b>
              {address_Line_1} <br />{address_Line_2} <br /> {pincode} <br /></p>
          </div>
          //  https://www.google.com/maps/@41.403384,2.1714497,17z?entry=ttu
        }

        <div className="flex items-center mt-4 ">

          <PaidOutlinedIcon />
          <div className='flex justify-between text-xl px-2'>
            <b>Total : &nbsp; </b>  ₹ {profit}
          </div>
        </div>
        <div className='h-20'>{/*! DONT edit .. height for absolute position status button */}         </div>

      </div>
    } />









  )
}

function StatusButton( { id, hotel_QR_OTP, isPickUp,isDineIn, isDelivery, latitude, longitude }: any ) {
  const queryClient = useQueryClient()

  const { mutate: PutFoodCooked } = usePutReq( () => {
    queryClient.invalidateQueries( { queryKey: ['fetchHotelDashboardValue-' + hotelID] } )
  }, () => { }, false )

  const navigate = useNavigate();
  const { hotelID } = useParams();
  const [miniPop, setMiniPop] = useState( false )
  function toggleMiniPop() {
    setMiniPop( !miniPop )
  }
  function cooked() {
    toggleMiniPop()
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify( { "id": id } )
    const url = '/api/hotel/food/cooked/'
    PutFoodCooked( { url, body, config } )
  }
  function showDeliverPopUp() {
    toggleMiniPop()
    navigate( window.location.pathname + PopConstants.CHOOSE_DELIVERY_BOY + "&hotelID=" + hotelID + "&orderID=" + id )
  }



  return (
    <div className='absolute right-0 bottom-0 text-lg mr-2 mb-3 flex flex-col w-full '>


      {miniPop &&
        <div className='w-full flex justify-end'>
          <div id="dropdownTop" className="z-10 w-44 mb-1 mr-8 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
            {(isPickUp || isDineIn) &&

              <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownTopButton">
                <li onClick={cooked}>
                  <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Cooked</span>
                </li>
                <li onClick={() => { show_FoodPicked_QR( id, hotel_QR_OTP, navigate, toggleMiniPop, hotelID ) }}>
                  <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Show QR</span>
                </li>
              </ul>
            }
            {
              isDelivery &&
              <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownTopButton">
                <li onClick={showDeliverPopUp}>
                  <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Set Delivery</span>
                </li>
              </ul>
            }

          </div>
        </div>

      }

      <div className='flex flex-row justify-between w-full   px-8'>
      {
      isDelivery?(<a target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${ latitude },${ longitude }`}
          className="mb-3 justify-self-end right-0 md:mb-0 w-28 flex justify-between text-white bg-blue-700 hover:bg-blue-800  font-medium rounded-lg text-sm px-4 py-2.5 text-center items-center dark:bg-blue-600 dark:hover:bg-blue-700  " >
          <PlaceIcon sx={{ color: "#fff", fontSize: "medium" }} />
          <span>Location</span>
        </a>):(<div></div>)
      }
        
        <button onClick={() => { toggleMiniPop() }} id="dropdownTopButton" data-dropdown-toggle="dropdownTop" data-dropdown-placement="top"
          className="  mb-3 justify-self-end right-0 md:mb-0 w-28 flex justify-between text-white bg-blue-700 hover:bg-blue-800  font-medium rounded-lg text-sm px-4 py-2.5 text-center items-center dark:bg-blue-600 dark:hover:bg-blue-700 " type="button">
          Status <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
        </button>
      </div>

    </div>



  )
}

export function show_FoodPicked_QR( id: any, hotel_QR_OTP: any, navigate: any, toggleMiniPop: any, hotelID: any ) {
  navigate( window.location.pathname + PopConstants.QR_FOOD_PICKED + "&id=" + id + "&hotel_QR_OTP=" + hotel_QR_OTP + "&hotelID=" + hotelID )
  toggleMiniPop()
}