import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../Animations/Alerts/AlertSlicer';
import LoadUser, { LogoutUser } from './LoadUser';
import Cookies from 'js-cookie'
import plainAxios from '../../Functionalities/Axios/plainAxios';
import { RootState } from '../../App/store';
function InitialLoader() {
    let csrftoken = Cookies.get('csrftoken')+""
    // ! This axios.defaults.headers.common will be deleted in when SignIn user and inserted while page refresh

    // axios.defaults.headers.common['X-CSRFToken'] =csrftoken;
    // axios.defaults.headers.common['xsrfCookieName'] =csrftoken;

    axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('accessToken')}`;
    const { isLoading, isError:isShutDown,isSuccess, data, error } = useQuery(['fetchCheckShutDown'], () => {
        return plainAxios("/api/checkShutDown/")
      },{
        refetchOnMount:true,
        staleTime:6000000,//100min
        cacheTime:6000000,
        refetchInterval:6000000,
        refetchOnWindowFocus: true,
        retry: 2
      })
    const dispatch = useDispatch()
    const startUserLogout = useSelector((state:RootState)=>state.UserInfo.startUserLogout)

    if(isShutDown){
        // @ts-ignore
        dispatch(Alert({"status":"error","heading":"Error","message":error.response.data[0]}))

    }


    return (
    <>
    <LoadUser/>
    {startUserLogout && <LogoutUser/>}

    </>
    )
}

export default InitialLoader