import React from 'react'


// Components
import Component_1 from './ScreenComponents/home/Component_1'
import Component_2 from './ScreenComponents/home/Component_2'
import Component_3 from './ScreenComponents/home/Component_3'
import Component_4 from './ScreenComponents/home/Component_4'
import Component_5 from './ScreenComponents/home/Component_5'
import Component_6 from './ScreenComponents/home/Component_6'
import Component_7 from './ScreenComponents/home/Component_7'
import Component_8 from './ScreenComponents/home/Component_8'



function Home() {
  return (
    <div className=''>
    <Component_1 />

    <Component_6 />

    <Component_2 />

    <Component_4 />


    <Component_3 />

    <Component_5 />
    <div>
      
    </div>

    
    {/* <Component_7 /> */}

    <Component_8 />


    </div>
    
    
  )
}

export default Home

// https://images.unsplash.com/photo-1547149617-609fafa00a6b?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774
