import React from 'react'

function RefundPolicy() {
  return (
    <div className="py-10 px-2  lg:px-10 lg:py-20">
    <ol className='flex flex-col gap-y-5'>
        <li className='flex flex-col gap-y-5'>
        <h3>Cancellation & Refund Policy</h3>
        </li>
        No cancellations & Refunds are available
    </ol>
    </div>
  )
}

export default RefundPolicy