import React, { useState } from 'react'
import { motion } from 'framer-motion';
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AvTimerRoundedIcon from '@mui/icons-material/AvTimerRounded';
import JSON_to_Foodlist from '../../../Functionalities/Data_To_TSX/JSON_to_Foodlist';
import { timeFormatter12HR, timeFormatterDate } from '../../../Functionalities/smallFns';

function HistoryList({id,processedFoodList,hotelName,orderedTime,otp, requestedPickupTime,userPickedUpTime, deliveredTime, finalTotal,actualTotal, status,image,invoice}:any) {

  let _orderedTime = timeFormatter12HR(orderedTime)
  let _deliveredTime = timeFormatter12HR(deliveredTime)
  let _requestedPickupTime = timeFormatter12HR(requestedPickupTime)
  let _userPickedUpTime = timeFormatter12HR(userPickedUpTime)

  let date = timeFormatterDate(orderedTime)
  const [isOfferApplied, setIsOfferapplied]= useState(!(finalTotal==actualTotal))// Because if offer havent applied finalTotal and actualTotal are same 
    const list = {
        initial:{
          x : -50,
          opacity:0,
      },
      animation:{
        x:0,
        opacity:1,
        transition:{
          duration : 0.7
      },
      
    }    
      }
  return (

    <motion.div variants={list}
    initial="initial"
    whileInView="animation"
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 1.02 }}
    className="max-w-sm mx-auto h-full bg-white rounded-lg shadow-lg dark:bg-gray-900 w-full relative flex flex-col justify-between">
    
    <div>
        <div className='relative'>
        <div className='right-0 absolute text-green-100 rounded-bl-md rounded-tr-md  bg-blur-dark flex justify-center align-middle py-3 px-4'>
              ID : {id}
            </div>
            <img className="object-cover object-center w-full h-56" src={`${image}`} alt={hotelName} />
        </div>
        <div className="flex items-center px-6 py-3 bg-neutral-900">
            <FastfoodRoundedIcon sx={{ color: "#fff" }}  />

            <h3 className="mx-3 text-lg font-semibold text-white">{hotelName}</h3>
        </div>
    </div>
    

    <div className="px-6 py-4 relative flex flex-col justify-between h-full ">
      <div>
          <div className='right-0 absolute text-green-700 dark:text-green-100 pr-5 '>
            {date}
          </div>
          <div className=''>
            <p className="text-xl font-semibold text-gray-800 dark:text-white">{status}</p>
        
            <p className="py-2 text-gray-700 dark:text-gray-400 whitespace-pre">{processedFoodList}</p>
          </div>

      </div>

        
      <div >
          <div className="flex items-center mt-4 text-gray-700 dark:text-gray-200">
                <CurrencyRupeeRoundedIcon />
                {
                isOfferApplied?
                (<>
                <p className="px-2 text-sm line-through">{actualTotal}</p>
                <p className="px-2 text-sm dark:offerPrice-dark offerPrice ">{finalTotal}</p>

                </>):
                (<p className="px-2 text-sm">{finalTotal}</p>)

                }
                
            </div>
        
            <div className="flex items-center mt-4 text-gray-700 dark:text-gray-200">
                
            <AccessTimeRoundedIcon />
                <p className="px-2 text-sm"><b>Ordered &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b> {_orderedTime} <br />
                <b>User picked :</b> {_userPickedUpTime?_userPickedUpTime:''}
                 {/* {_deliveredTime?_deliveredTime:'-'} */}
                 

                 </p>
            </div>
        
            <div className="flex items-center mt-4 text-gray-700 dark:text-gray-200">
                
            <AvTimerRoundedIcon />
                    <p className="px-2 text-sm"><b>Requested Time :</b> {_requestedPickupTime}</p>
            </div>
            <div className="flex justify-between items-center mt-4 text-gray-700 dark:text-gray-200 w-full">
              <div>
                <span className='font-extrabold pr-1 scale-75'>OTP</span>{otp}
              </div>
                <a  href={invoice} className='underline text-right'>Invoice</a>
            </div>
            
      </div>
  </div>
        
    
        
</motion.div>
          
  )
}

export default HistoryList