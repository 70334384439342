import React from 'react'

function ContactUs() {
    return (
            <div className=" px-2  lg:px-10 lg:py-20 text-gray-800 dark:text-white">
                <h3 className='py-4'>
                You may contact us using the information below:
                </h3>
                <ol className='flex flex-col gap-y-5'>
                    <li className=''>
                    <b>Merchant Legal entity name</b>: Kallardo  
                    </li>
                    <li>
                    <b>Registered Address:</b> 4/101 A4, Elanthaiyadi, Parvathipuram, Nagercoil, Kanyakumari TAMIL NADU 629003
               
                    </li>
                <li>
                <b>Operational Address:</b> 4/101 A4, Elanthaiyadi, Parvathipuram, Nagercoil, Kanyakumari TAMIL NADU 629003
                
                </li>
                <li>
                <b>Telephone No:</b> <a className='underline' href="tel:+9108062177665">+91 08062177665</a> 
                </li>
                <li>
                <b>E-Mail ID:</b> <a className='underline' href="mailto:support@kallardo.com">support@kallardo.com</a>
                </li>
                </ol>



                
            </div>
            )
}

            export default ContactUs