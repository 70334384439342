import React from 'react'
import { Link } from 'react-router-dom'

function Error404 ({message='Page not found'} : {message?:any;}){
  return (
    
// https://tailwindcomponents.com/component/404-page
<section>

	<div className="dark:bg-gray-800 text-white bg-gray-300">
		<div className="flex h-screen">
			<div className="m-auto text-center">
			<div className=" text-center flex flex-col justify-center">

      <img className='h-60 md:h-96' src="https://www.kallardo.com/static/404_image.jpg" alt="404_not_found" />
        </div>

				<p className="text-sm  md:text-3xl dark:base-orange-text text-orange-600 p-2 mb-4">{message}
				</p>
				<Link to="/"
					className="dark:bg-orange-100 base-orange-text  bg-white dark:hover:border-white dark:hover:text-black rounded shadow hover:shadow-lg py-2 px-4 mx-5 border dark:border-orange-600 ">
					Home</Link>

          <Link to="/Restaurants"
					className=" dark:base-orange base-orange hover:border-white text-white rounded shadow hover:shadow-lg py-2 px-4 border border-orange-600 ">
					Order Now</Link>
			</div>
		</div>
	</div>
</section>
  )
}

export default Error404