import React, { useState } from 'react'
import CartPopUp from './CartPopUp/CartPopUp'
import { useSelector } from 'react-redux';
import { RootState } from '../../App/store';
import AccountPopUp from './AccountPopUp/AccoutPopUp';
import DeliveryOTP_PopUp from './DeliveryBoyPopUp/DeliveryBoyOTPPopUp';
import UndelivReasonPopUp from './DeliveryBoyPopUp/UndelivReasonPopUp';
import InStoreOTPPopUp from './InStorePopUp/InStoreOTPPopUp';
import QR_food_picked from './InStorePopUp/QR_food_picked';
import { DisplayRazorPay } from '../../Components/RazorPay/DisplayRazorPay';
import AndroidRedirect from '../AndroidRedirect';
import { is_TWA } from '../../Constants/globalConstants';

import { Location, useLocation } from 'react-router-dom';
import { useEffect } from 'react'
import { PopConstants } from './PopUpManagerConstants';
import Choose_delivery_boy_PopUp from './HotelOwnerPopUp/Choose_delivery_boy_PopUp';
import DeliveryBoyQR_FoodDelivered from './DeliveryBoyPopUp/DeliveryBoyQR_FoodDelivered';


export default function PopUpManager() {


  
  const isCartPopUp = useSelector((state:RootState)=>state.PopUpOrder.visible)
  const isLoginPopUp = useSelector((state:RootState)=>state.PopUpAccount.visible)
  const isDeliveryBoyPopUpVisible =useSelector((state:RootState)=>state.DeliveryOTP.visible)
  const isDeliveryOTP_PopUp =useSelector((state:RootState)=>state.DeliveryOTP.mode === 'OTP') ;
  const isUnDeliveredReasonPopUp= useSelector((state:RootState)=>state.DeliveryOTP.mode === 'reason') ;
  const isInStoreOTPPopUp= useSelector((state:RootState)=>state.InStoreOTPPopUp.visible) ;
  const isDisplayRazorPayPopUp= useSelector((state:RootState)=>state.GlobalData.isDisplayRazorPayPopUp) ;
  const isAndroidRedirectPopup= useSelector((state:RootState)=>state.GlobalData.isAndroidRedirectPopup) ;

  let location = useLocation();
  let url: Location;

  
  const [is_QR_food_picked, setIs_QR_food_picked] = useState(false)
  const [is_QR_food_delivered, setIs_QR_food_delivered] = useState(false)
  const [isChooseDeliveryBoyPopUp, setIsChooseDeliveryBoyPopUp] = useState(false)
  useEffect(() => {
    //  url.search for query params like ?popup/login
    //  url.pathname for path like        popup/login 
    url = location;
    setTimeout(() => { // time for script dom to be appended incase of direct URL takes time
      setIs_QR_food_picked(url.search.includes(PopConstants.QR_FOOD_PICKED))   // Does not work in direct URL open due to library has to load
    }, 500);
    setIsChooseDeliveryBoyPopUp(url.search.includes(PopConstants.CHOOSE_DELIVERY_BOY))
    setIs_QR_food_delivered(url.search.includes(PopConstants.QR_FOOD_DELIVERED))

  }, [location]);

  return (
    <>
    { isCartPopUp? <CartPopUp/>:null }
    { isLoginPopUp && <AccountPopUp/>}
    { isDeliveryBoyPopUpVisible && isDeliveryOTP_PopUp && <DeliveryOTP_PopUp />}
    { isDeliveryBoyPopUpVisible && isUnDeliveredReasonPopUp && <UndelivReasonPopUp />}
    { isInStoreOTPPopUp &&  <InStoreOTPPopUp />}
    { isDisplayRazorPayPopUp &&  <DisplayRazorPay />}
    { isAndroidRedirectPopup && !is_TWA &&  <AndroidRedirect />}

    {/* ! In  GET URL based Popup, If you use UseQuery attach Time in UseQuery String. Else second PopUp cause Error  */}
    { is_QR_food_picked && <QR_food_picked/>}
    { isChooseDeliveryBoyPopUp && <Choose_delivery_boy_PopUp/>}
    { is_QR_food_delivered && <DeliveryBoyQR_FoodDelivered/>}
    

    </>
  )
}
