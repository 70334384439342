import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
    totalAlert: Array<object>
}

const initialState: CounterState = {
  totalAlert:[
]
}

export const AlertSlicer = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    Alert: (state, action: PayloadAction<object>) => {
        state.totalAlert.push(action.payload)
        
    },
    // pop: (state, action: PayloadAction<object>) => {
    //     // state.totalAlert.filter((value) =>{

    //     }) 

    // },
  },
})

// Action creators are generated for each case reducer function
export const { Alert } = AlertSlicer.actions

export default AlertSlicer.reducer