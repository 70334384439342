import React from 'react'

function OrderedFoodTable( { foodListCheck, FoodList }: any ) {

    return (
        <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                    <tr>
                        <th scope="col" className="py-3 px-6">
                            Food X count
                        </th>
                        <th scope="col" className="py-3 px-4 text-right ">
                            Price
                        </th>
                    </tr>
                </thead>
                <tbody>


                    {foodListCheck ? (
                        // for (const [key, value] of Object.entries(FoodList)) {
                        FoodList.map( ( value: any ) => {

                            return (
                                <tr key={value.id} className="bg-white border-b dark:bg-gray-900 dark:even:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {value.name} x {value.count}
                                    </th>
                                    <td className="py-4 text-right px-4">
                                        {value.foodPrice * value.count}
                                    </td>
                                </tr>
                            )

                        } ) ) : ( <></> )}
                </tbody>
            </table>
        </>

    )
}

export default OrderedFoodTable