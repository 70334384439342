import React from 'react'
import { motion } from 'framer-motion';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export default function DeliveryBoyHeader() {
    const { isLoading, isError,isSuccess, data, error } = useQuery(['fetchDeliveryBoyHeader'], () => {
        return (axios.get(`/api/getUserName/`))
    })
  return (
    <div className="container px-6 pt-10 md:pt-16 mx-auto ">
      <motion.div
         initial={{
            x:-50,
            opacity:0.2
        }}
        whileInView={"animation"}
        variants={{animation:{
            x:0,
            opacity:1
        }}}
        transition={{
            duration : 0.7
        }}
            className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white flex ">
            {data?.data?.username} 
             </motion.div>
              <div className="mt-2">
                  <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
                  <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
                  <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
  )
}
