import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import Error404 from '../../Components/Error404'
import Loading from '../../Animations/Loading'
import { useDeleteReq } from '../../CustomHooks/deleteReq'
import RefreshWindow from '../../Functionalities/RefreshWindow'
import { useParams } from 'react-router-dom'
import { usePostReq } from '../../CustomHooks/postReq'
import { FormData_Config } from '../../Constants/Axios/JSON_Config'
import HotelOwnerHeader from './HotelOwnerHeader'
import { combineAPI_food_categories } from '../../Functionalities/smallFns'

function FoodEditJSX( { id, name, price, isAvailable, isVeg, refetchFoodList, image, refetch, suggestion, category }: any ) {


  const [inputs, setInputs] = useState(
    {
      id: id,
      name: name,
      price: parseInt( price ),
      isAvailable: isAvailable,
      isVeg: isVeg,
      image: null,
      imagePreview: null,
      category: category,
    } );
  const [isCardVisible, setIsCardVisible] = useState( true )
  const [isNewCategory, setIsNewCategory] = useState( false )
  const { mutate: PostEditFood } = usePostReq()

  const { mutate: DeleteFood } = useDeleteReq( () => { refetch() } )


  const handleChange = ( event: any ) => {
    let name: any, value: any;



    name = event.target.name;

    if ( name == 'foodName' ) {//Change for Auto fill in input field
      name = 'name'
    }

    if ( event.target.type === 'checkbox' ) {
      // For check Box
      value = event.target.checked;
    } else if ( event.target.type === 'text' || event.target.type === 'number' ) {
      // For Input Form
      value = event.target.value;
    }

    if ( event.target.name == "image" ) {
      value = event.target.files[0];
      // @ts-ignore
      setInputs( values => ( { ...values, imagePreview: URL.createObjectURL( event.target.files[0] ) } ) )
    }
    else if ( event.target.type === 'select-one' ) {
      // For Input Form
      value = event.target.value;
      if ( value == 'Other' ) {
        value = ""
        setIsNewCategory( true )
        setTimeout( () => {
          // @ts-ignore
          document.getElementById( "category_input"+id ).focus();
        }, 100 );
      }
    }
    setInputs( values => ( { ...values, [name]: value } ) )
  }

  const handleSubmit = ( event: { preventDefault: () => void } ) => {

    event.preventDefault();
    const config = FormData_Config
    const formData = new FormData();
    formData.set( 'id', inputs.id + "" );
    formData.set( 'name', inputs.name );
    formData.set( 'price', inputs.price + "" );
    formData.set( 'isAvailable', inputs.isAvailable ? "true" : "false" );
    formData.set( 'isVeg', inputs.isVeg ? "true" : "false" );
    formData.set( 'category', inputs.category );
    if ( ( inputs.image !== null ) ) {
      formData.set(
        "image",
        // @ts-ignore
        inputs.image,
        // @ts-ignore
      );
    }


    const url = '/api/hotels/edit/food/'
    PostEditFood( { url, body: formData, config } )
  }
  function deleteFood() {
    const config = {
      headers: {
        'Content-type': 'application/json',
        // 'Authorization': `Bearer ${userInfo.token}`
      }
    }
    const body = inputs
    const url = '/api/hotels/delete/food/'
    DeleteFood( { url, config, body } )
    // const myTimeout = setTimeout(refetchFoodList, 500);
    // setIsCardVisible(false)
    // 



  }

  return (
    <>
      {isCardVisible ?
        <form onSubmit={handleSubmit} className='mild-border p-2 py-8 rounded-md relative mb-6 mx-4'  >
          {/* Food Image Field */}
          <div className="flex justify-center items-center mx-2 mb-9">
            <label
              htmlFor={`dropzone-file-new-food-${ id }`}// !inCase of change ID below with same name
              className="flex  justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col sm:flex-row justify-evenly w-full items-center mt-2 pt-5 pb-6">
                {inputs.imagePreview && <img className="w-36 h-36" src={inputs.imagePreview} alt="" />}
                {!inputs.imagePreview && <img className="w-36 h-36" src={image} alt={name} />}

                <div className=" dark:text-gray-100 flex flex-col justify-center items-center ">

                  <svg
                    aria-hidden="true"
                    className="mb-3 w-10 h-10  "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                  </svg>
                  <p className="mb-2 text-sm ">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs">
                    JPG (Resolution 1:1)
                  </p>
                </div>

              </div>
              <input id={`dropzone-file-new-food-${ id }`} //! inCase of change htmlfor above with same name
                type="file" className="hidden"
                onChange={( e ) => handleChange( e )}
                name='image'
                accept=".jpg, .jpeg, .png" />
            </label>

          </div>
          {/* Food Image Field ends */}
          <div className="grid md:grid-cols-2  ml-3 mb-6">
            <div className='w-full'>
              <div className="mb-3">
                <label htmlFor="foodName" className="relative font-medium text-gray-900 dark:text-gray-300 text-2xl">Food Name</label>
                <input name='foodName' value={inputs.name} onChange={( e ) => handleChange( e )} type="text" id="foodName" className="md:w-11/12 px-2  w-full mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div>
            </div>
            <div className='w-full'>
              <div className="mb-3">
                <label htmlFor="price" className="relative  font-medium text-gray-900 dark:text-gray-300 text-2xl ">Price</label>
                <input name='price' value={inputs.price} onChange={( e ) => handleChange( e )} type="number" id="price" min='1' max='10000' className="md:w-11/12 px-2 w-full mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div>
            </div>
          </div>


          <div className='my-6 mx-3 w-full'>
            <div className='block md:flex '>

              <ChooseCategory isNewCategory={isNewCategory} inputs={inputs} suggestion={suggestion} handleChange={handleChange} id={id} />
              <div className='inline-block md:mt-0 mt-5 w-1/2 justify-center items-center align-middle  md:pt-12'>
                <label className="inline-flex relative items-center cursor-pointer mx-2">
                  <input name='isVeg' onChange={( e ) => handleChange( e )} checked={inputs.isVeg} type="checkbox" id="veg-toggle" className="sr-only peer" />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Veg</span>
                </label>
                <label className="inline-flex relative items-center cursor-pointer mx-2">
                  <input name='isAvailable' onChange={( e ) => handleChange( e )} checked={inputs.isAvailable} type="checkbox" id="food-availability-toggle" className="sr-only peer" />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Available</span>
                </label>
              </div>

            </div>

          </div>
          <div className='flex  justify-end text-white mx-5 right-4 bottom-4'>
            <button name='delete' type="button" onClick={() => deleteFood()} className=" w-20 mx-5 items bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 text-white">Delete</button>
            <button name='save' type="submit" className=" w-20      items bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 text-white"
            >Save</button>
          </div>
        </form>
        : ( <></> )}

    </>

  )
}

export default function FoodListEdit() {
  const { hotelID } = useParams();

  const { isLoading, isError, isSuccess, data, error, refetch, isRefetching, isPreviousData } = useQuery( ['fetchHotelFoodEdit-' + hotelID], () => {
    return ( axios.get( `/api/getHotelFoodEditPreview/${ hotelID }/` ) )
  },
    {
      refetchOnMount: true,
      staleTime: 60000,//1min
      cacheTime: 60000,
      refetchInterval: 60000,
      refetchOnWindowFocus: true,

    } )
  const { isLoading: _isLoading, isError: _isError, isSuccess: _isSuccess, data: _data_suggestions, error: _error } = useQuery( ['fetch-foodCategories-' + hotelID], () => {
    return ( axios.get( `/api/get_HotelCategorySuggestion/`,
      {
        params: {
          hotelID: hotelID
        }
      } ) )
  },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: 100000,
      cacheTime: 100000,
      refetchInterval: 100000,

    } )
  const [suggestion, setSuggestion] = useState( [] )
  useEffect( () => {
    if ( _isSuccess ) {
      // @ts-ignore
      setSuggestion( combineAPI_food_categories( _data_suggestions ) )
    }

    return () => {
    }
  }, [_isSuccess] )

  return (
    <div className='container mx-auto my-10'>
      <HotelOwnerHeader dashboard manage />

      <h3 id='add-new-food' className='flex font-medium text-gray-900  dark:text-gray-300 text-4xl my-10'>Edit Foods</h3>

      {isLoading && Loading}
      {isError && Error404}
      {isSuccess && data?.data.map( ( value: any ) => {

        return (
          <FoodEditJSX key={value.id} id={value.id} name={value.name} price={value.price} isAvailable={value.isAvailable} isVeg={value.isVeg} refetchFoodList={refetch} image={value.image} refetch={refetch} suggestion={suggestion} category={value.category} />
        )

      } )}


    </div>

  )
}


function ChooseCategory( { isNewCategory, inputs, suggestion, handleChange,id }: any ) {
  return (
    <>
      {!isNewCategory ?

        ( <select name='category' className='mt-5 md:mt-auto  w-72' value={inputs.category} onChange={( e ) => handleChange( e )}>
          <option value="">{inputs.category}</option>
          {suggestion.map( ( option: any ) => (
            <option key={option} value={option}>{option}</option>
          ) )}
        </select> ) :
        (

          <div className="mb-3 w-full">
                <label htmlFor="category" className="relative font-medium text-gray-900 dark:text-gray-300 text-2xl">Enter Category</label>
                <input name='category' value={inputs.category} onChange={( e ) => handleChange( e )} type="text" id={"category_input"+id} className="md:w-1/2 px-2  w-full mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div>

        )
      }
    </>
  )
}
