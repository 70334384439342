import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import DummyScreen from '../DummyScreen';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalData } from '../../GlobalSlicer/GlobalSlicer';
import { currentURLPath } from '../../Functionalities/smallFns';
import { RootState } from '../../App/store';
import { TogglePopUpAccount } from '../../Animations/PopUp/AccountPopUp/AccountSlicer';

function FoodSearchBar() {
  const [query, setQuery] = useState("")
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state:RootState)=>state.UserInfo.isAuthenticated)
  const dispatch = useDispatch()

  
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!isAuthenticated){
      dispatch(TogglePopUpAccount())

      return
    }
    if(currentURLPath()!="/search-food")
    {
      navigate("/search-food");
    }
    dispatch(setGlobalData({"searchFoodQuery":query}))
  }
  return (
    <>
    <div className='w-full  px-10 pt-10 md:px-20 '>
    {/* To load a nice seatch page  */}
    <form onSubmit={handleSubmit}>
        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
        <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </div>
            <input value={query} onChange={(e)=>setQuery(e.target.value)} pattern='^[a-zA-Z0-9 ]*$' title="Search value must contain only A-Z a-z 0-9 and spaces" type="search" id="default-search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search your favourite food" required />
          {/* <Link to='/search-food' > */}
            <button type="submit" className="text-white absolute right-2.5 bottom-2.5 base-orange hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-4 md:px-6 py-2 ">Go</button>
          {/* </Link>   */}
        
        </div>
    </form>  
    </div>
    </>

  )
}

export default FoodSearchBar