import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { ModePhoneSignIn, SetTogglePopUpAccount } from '../AccountSlicer';
import RefreshWindow from '../../../../Functionalities/RefreshWindow';
import { usePutReq } from '../../../../CustomHooks/putReq';

function ResetPassword( { togglePopUp }: { togglePopUp: any } ) {
    const dispatch = useDispatch()
    // let setCartPopUp = 
    function switchToPhoneSignIn() {
        dispatch( ModePhoneSignIn() )
    }
    const [stepper, setStepper] = useState( 1 )
    const [inputs, setInputs] = useState(
        {
            phoneNumber: '',
            password: '',
            otp: ''
        } );

    const { mutate: PutResetPassword } = usePutReq( ()=>{setStepper(2)} )
    const { mutate: PutResetPasswordConfirmOTP } = usePutReq( successFn )

    function successFn( data: any ) {
        dispatch( SetTogglePopUpAccount( false ) )

        let accessToken = data.access
        let refreshToken = data.refresh
        localStorage.setItem( "accessToken", accessToken )
        localStorage.setItem( "refreshToken", refreshToken )
        RefreshWindow()
    }
    const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        let name: any, value: any;



        name = event.target.name;

        if ( event.target.type === 'checkbox' ) {
            // For check Box
            value = event.target.checked;
        } else if ( event.target.type === 'text' || event.target.type === 'number' || event.target.type === 'password' ) {
            // For Input Form
            value = event.target.value;
        }

        // For Buttons
        if ( event.target.name === 'save' ) {
            alert( "save" )
        } else if ( event.target.name === 'delete' ) {
            alert( "delete" )
        }


        setInputs( values => ( { ...values, [name]: value } ) )
    }

    const handleSubmit =  (event: { preventDefault: () => void }) => {
        event.preventDefault();

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        if (stepper  == 1){
            const body = JSON.stringify( inputs )
            const url = '/api/send_PasswordReset_otp/'
            PutResetPassword( { url, body, config } )
        }else  if(stepper == 2){
            const body = JSON.stringify( inputs )
            const url = '/api/verify_PasswordReset_otp/'
            PutResetPasswordConfirmOTP( { url, body, config } )
        }

    }
    return (
        <>



            {/* // <!-- Main modal --> */}
            <motion.div
                initial={{
                    y: 300,
                }}
                whileInView={"animation"}
                variants={{
                    animation: {
                        y: 0,

                    }
                }}
                transition={{
                    duration: 0.5
                }} className=" fixed overflow-auto scrollbar-thin  z-[100]  w-full h-full md:h-full justify-center items-start flex md:items-center shadow-3xl  pb-32 md:pb-0">
                <div className="relative  px-4 w-full max-w-sm  md:h-auto ">
                    {/* <!-- Modal content --> */}
                    <div className="relative dark:bg-blur-dark bg-blur-light rounded-lg   shadow ">

                        {/* To Do  Add Redux for Close button */}

                        {/* Add sometin for  onlclick */}




                        <button type="button" onClick={() => togglePopUp()} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="py-6 px-6 lg:px-8">
                            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white text-center">Reset Password</h3>
                            <form onSubmit={handleSubmit} className="space-y-6 mild-border rounded-lg p-3">
                                {/* Inner Box */}

                                <div>
                                    <label htmlFor="phoneNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Phone number</label>
                                    <input type="phoneNumber" name="phoneNumber" value={inputs.phoneNumber} onChange={( e ) => handleChange( e )} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                </div>
                                {stepper == 2 && <>
                                    <div>
                                        <label htmlFor="otp" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">OTP recieved</label>
                                        <input type="number" name="otp" value={inputs.otp} onChange={( e ) => handleChange( e )} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Enter Password</label>
                                        <input type="password" name="password" id="password" value={inputs.password} onChange={( e ) => handleChange( e )} placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                    </div>
                                </>
                                }


                                {stepper == 1 &&
                                    <button type="submit" className="w-full text-white base-orange hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Send OTP</button>
                                }
                                {stepper == 2 && <>
                                    <div className="flex justify-between">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="remember" type="checkbox" className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800" />
                                        </div>
                                        <label htmlFor="remember" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
                                    </div>
                                </div>
                                    <button type="submit"  className="w-full text-white base-orange hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Reset Password</button>
                                    
                                </>
                                    
                                }
                                <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                                    Not registered? <span onClick={() => switchToPhoneSignIn()} className="text-orange-500 cursor-pointer underline  ">Create account</span>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>

    )
}

export default ResetPassword