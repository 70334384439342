import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useSelector } from 'react-redux';

import { ErrorBoundary } from './ErrorBoundary';
import ThemeProvider from './Components/ThemeProvider';
import { AlertContainer } from './Animations/Alerts/AlertContainer';
import Header from './Components/Header/Header'
import Footer from './Components/Footer'
import Error404 from './Components/Error404';
import Home from './Screen/Home';
import Hotels from './Screen/Users/Hotels/Hotels';
import About from './Screen/About';
import Foods from './Screen/Users/Foods/Foods'
import History from './Screen/Users/History/History';
import MaintainScroll from './Components/MaintainScroll';
import ChooseHotel from './Screen/HotelOwners/ChooseHotel';
import HotelDashboard from './Screen/HotelOwners/DashBoard/HotelOrdersDashBoard/HotelOrdersDashBoard.js';
import HotelDetailsEdit from './Screen/HotelOwners/HotelDetailsEdit/HotelDetailsEdit'
import HotelOwnersHistory from './Screen/HotelOwners/History/HotelOwnersHistory'
import FoodListEdit from './Screen/HotelOwners/FoodListEdit';
import Profile from './Screen/Profile';
import PopUpManager from './Animations/PopUp/PopUpManager';
import DelveryBoyChooseDelivery from './Screen/DeliveryBoy/ChooseDelivery/DeliveryBoyChooseDelivery'
import DelveryBoyDashBoard from './Screen/DeliveryBoy/DashBoard/DelveryBoyDashBoard';
import InitialLoader from './Components/InitialLoader/InitialLoader'
import DeliveryBoyHistory from './Screen/DeliveryBoy/History/DeliveryBoyHistory'
import PickupHotelDashboard from './Screen/HotelOwners/DashBoard/PickupOrdersDashboard/PickupOrdersDashboard.tsx';

import DeliveryHotelDashboard from './Screen/HotelOwners/DashBoard/DeliveryOrdersDashboard/DeliveryOrdersDashboard.tsx';
import DeliveryPolicy from './Screen/Others/DeliveryPolicy';
import FinalLoader from './Components/FinalLoader/FinalLoader'
import FoodSearchScreen from './Screen/Users/Foods/FoodSearch/FoodSearchScreen'
import {CheckoutPage} from './Screen/CheckoutPage/CheckoutPage'
import PrivacyPolicyAndroid from './Screen/Others/PrivacyPolicyAndroid';
import Testing from './Components/Testing.tsx'
import DeleteAccount from './Screen/Users/DeleteAccount'
import  AdminDashboard  from './Screen/Admin/AdminDashboard';
import  RefundPolicy from './Screen/Others/RefundPolicy';
import ContactUs from './Screen/Others/ContactUs';
import HotelHomeDashBoard from './Screen/HotelOwners/DashBoard/HomeDashBoard/HotelHomeDashBoard';
function App() {
// Create a client
const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries:  
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
      <InitialLoader />
              <ThemeProvider> 
              <AlertContainer />

              <MaintainScroll>


                <Header />
                <ErrorBoundary>
                  
                  <PopUpManager />
                    <Routes>
                      {/* Consumers */}
                          <Route path="*" element={<Error404 />} />
                          <Route path="/" exact element={<Home />} />

                          <Route path="/Restaurants" exact element={<Hotels />} />
                          <Route path="/foods/:hotelName/:hotelID" exact element={<Foods />} />
                          <Route path="/foods/:hotelName/:hotelID/QR" exact element={<Foods />} />

                          <Route path="/orders" exact element={<History />} />
                          <Route path="/about" exact element={<About />} />
                          <Route path="/search-food" exact element={<FoodSearchScreen />} />


                          <Route path="/Profile" exact element={<Profile />} />
                          <Route path="/delete/my_account" exact element={<DeleteAccount />} />

                          {/* Hotel Ownwers */}

                          {/* Choose Hotel */}
                          <Route path="/hotel/manage/choose_hotel" exact element={<ChooseHotel />} />{ /* Same component but handled in JSX*/ }
                          <Route path="/hotel/history/choose_hotel" exact element={<ChooseHotel />} /> { /* Same component but handled in JSX*/ }
                          <Route path="/hotel/home/dashBoard/choose_hotel" exact element={<ChooseHotel />} />

                          
                          <Route path="/hotel/home/dashBoard/:hotelID" exact element={<HotelHomeDashBoard />} />

                          <Route path="/hotel/dashboard/:hotelID" exact element={<HotelDashboard />} />
                          <Route path="/hotel/dashboard/edit/:hotelID" exact element={<HotelDetailsEdit />} />
                          <Route path="/hotel/dashboard/foodListEdit/:hotelID" exact element={<FoodListEdit />} />
                          <Route path="/hotel/dashboard/pickupHotel/:hotelID" exact element={<PickupHotelDashboard />} />
                          <Route path="/hotel/dashboard/deliveryHotel/:hotelID" exact element={<DeliveryHotelDashboard />} />
                          <Route path="/hotel/dashboard/history/:hotelID" exact element={<HotelOwnersHistory />} />


                          
                          {/* Delivery Boy */}
                          <Route path="/deliveryboy/dashboard" exact element={<DelveryBoyDashBoard />} />
                          <Route path="/deliveryboy/history" exact element={<DeliveryBoyHistory />} />
                          <Route path="/deliveryboy/choose/delivery" exact element={<DelveryBoyChooseDelivery />} />

                          


                          {/* Others */}
                          <Route path="/delivery_policy" exact element={<DeliveryPolicy />} />
                          <Route path="/privacy_policy/android" exact element={<PrivacyPolicyAndroid />} />
                          <Route path="/refund_policy" exact element={<RefundPolicy />} />
                          <Route path="/contact_us" exact element={<ContactUs />} />



                          <Route path="/checkout-page" exact element={<CheckoutPage />} />

                          

                          <Route path="/admin_dashboard" exact element={<AdminDashboard />} />

                          {/* Development Debugging*/}
                          <Route path="/testing" exact element={<Testing />} />


                    </Routes>
                  <Footer />
                  <FinalLoader />  
                </ErrorBoundary>

          </MaintainScroll>
              </ThemeProvider>
    </Router>
    <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>

  )
}

export default App;
