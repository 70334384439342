import React from 'react'
import { motion } from 'framer-motion';
import Error404 from '../../../Components/Error404';
import Loading from '../../../Animations/Loading';
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import {useDispatch} from 'react-redux'
import DashBoardChooseDeliveryCardList from './DashBoardChooseDeliveryCardList';
import DeliveryBoyHeader from '../DeliveryBoyHeader';
import { RQ_Constants } from '../../../Constants/Axios/ReactQueryRefetch';
function DelveryBoyChooseDelivery() {
  const dispatch = useDispatch()

    const { isLoading, isError,isSuccess, data, error } = useQuery(['fetchChooseDelivery'], () => {
    return (axios.get(`/api/getDeliveryBoyChooseOrders/`))
    // To do URL
  },RQ_Constants.one_minute)
  return (
    <>
  <DeliveryBoyHeader/>
    {isError && <Error404 message={error} /> }
    {isLoading && <Loading />  }

      
    
.
    <div className='dark:bg-gray-800 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-y-10 py-10 px-5 '>
    {
      isSuccess && data?.data.map((value: any) =>{
        let id =value.id
        let processedFoodList =value.processedFoodList //To avoid name collision
        let userName =value.customerDetails.username
        let phoneNumber=value.customerDetails.phoneNumber
        let altPhoneNumber =value.customerDetails.altPhoneNumber
        let userAddress =value.address
        let hotelName = value.hotelDetails.name
        let hotelAddress = value.hotelDetails.address
        let orderedTime = value.orderedTime
        let total = value.total
        let otp = value.otp
        let hotelImg =value.hotelDetails.image

        return (<DashBoardChooseDeliveryCardList id={id} userName={userName} processedFoodList={processedFoodList} phoneNumber={phoneNumber}
          altPhoneNumber={altPhoneNumber} userAddress={userAddress} hotelName={hotelName} hotelImg={hotelImg}
          hotelAddress={hotelAddress} orderedTime={orderedTime} total={total} otp={otp} dispatch={dispatch} 
          address_Line_1 ={value.address_Line_1} address_Line_2={value.address_Line_2}
          pincode={value.pincode} landmark={value.landmark} latitude={value.latitude} longitude={value.longitude}/>)
      }
       ) 
    }
    </div>
    </>
)
}
export default DelveryBoyChooseDelivery