import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react'
import { useDispatch } from 'react-redux';
import RefreshWindow from '../../Functionalities/RefreshWindow';
import {setUserInfo } from '../../Data/UserInfoSlicer';
import JSON_Config from '../../Constants/Axios/JSON_Config';
import { usePostReq } from '../../CustomHooks/postReq';


// This Loading User works after Header is been rendered


export default function LoadUser() {
    const dispatch = useDispatch()
    const { isLoading, isError,isSuccess, data, error } = useQuery(['fetchLoadUserDetails'], () => {
        return (axios.get('/api/auth/getUserDetails/',JSON_Config))
    },{
    refetchOnMount:true,
    refetchOnWindowFocus: true,
    retry: 2,

  }
  )
    if(isSuccess)
    {
        dispatch(setUserInfo(data.data))
        dispatch(setUserInfo({isAuthenticated:true}))
    }
    if (isError){
        

    }
    return (<></>)

}

export function LogoutUser(){
          // localStorage.removeItem('accessToken');
        // localStorage.removeItem('refreshToken');
  // const config = JSON_Config
  // const body = JSON.stringify({"refresh":localStorage.getItem('refreshToken')})
  const url = '/api/auth/user/logout/'
//   PostLogout({url,body,config})
    axios.get(url).then((response) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        RefreshWindow()

    }).catch(function (error) {
        console.log(error);
      });;
    
    return <></>
}
