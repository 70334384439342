import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
function Component_3() {
  return (
    <div>
            <div className="bg-white dark:bg-gray-800 overflow-hidden relative lg:flex lg:items-center">
    <motion.div
            initial={{
                x : -200,
                opacity:0.2
            }}
            whileInView={"animation"}
            variants={{animation:{
                x:0,
                opacity:1
            }}}
            transition={{
                duration : 0.7
            }} className="w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span className="block">Give yourself a sweet treat after a heavy meal</span>
        </h2>
        <p className="text-md mt-8 dark:text-gray-400 text-gray-700">
            Already had food? but how does the meal ends without good dessert. Order now your favourite dessert on <h2 className='text-xl inline dark:text-gray-400 text-gray-700'>Kallardo</h2>.
        </p>
        <div className="lg:mt-0 lg:flex-shrink-0">
            <div className="mt-10 inline-flex rounded-md shadow">
                <Link to='Restaurants' type="button" className="py-2 px-4 base-orange hover:bg-pink-500 focus:ring-pink-400 focus:ring-pink-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-sm ">
                    Order Now
                </Link>
            </div>
        </div>
    </motion.div>
    <div className="flex items-center gap-8 p-8 lg:p-24">
    <motion.img
            initial={{
                x : -50,
                opacity:0.2
            }}
            whileInView={"animation"}
            variants={{animation:{
                x:0,
                opacity:1
            }}}
            transition={{
                duration : 0.7
            }} src="static/home/iceCream_1.jpg" className="rounded-lg w-1/2" alt="Tree"/>
        <div>

        <motion.img
            initial={{
                y : -50,
                opacity:0.2
            }}
            whileInView={"animation"}
            variants={{animation:{
                y:0,
                opacity:1
            }}}
            transition={{
                duration : 0.7
            }} src="static/home/iceCream_2.jpg" className="rounded-lg mb-8" alt="Tree"/>
            <motion.img
            initial={{
                y : 50,
                opacity:0.2
            }}
            whileInView={"animation"}
            variants={{animation:{
                y:0,
                opacity:1
            }}}
            transition={{
                duration : 0.7
            }} src="static/home/iceCream_3.jpg" className="rounded-lg" alt="Tree"/>
        </div>
    </div>
</div>
    </div>
  )
}

export default Component_3