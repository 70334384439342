import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useParams,Link } from "react-router-dom";
import Loading from "../../../Animations/Loading";
import Error404 from "../../../Components/Error404";
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { usePostReq } from "../../../CustomHooks/postReq";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../App/store";
import AddFood from "./AddFood";
import { FormData_Config } from "../../../Constants/Axios/JSON_Config";
import HotelTimePicker from "./HotelTimePicker";
import { bool2str, timeFormatter12HR_TimeOnly, timeFormatter24HR_TimeOnly, timeFormatter24HR_for_api } from "../../../Functionalities/smallFns";
import { Alert } from "../../../Animations/Alerts/AlertSlicer";
import HotelOwnerHeader from "../HotelOwnerHeader";

export default function HotelDetailsEdit() {
  // Function Based Handling is Good Because isSuccess & useState multiple loops.
  // So go with Function based

  

  const { hotelID } = useParams();
  const [editMode, setEditMode] = useState(false)
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["fetchHotelDashboardValueForEdit-"+hotelID],
    () => {
      return axios.get(`/api/getHotelDashBoardForEdit/${hotelID}/ `);
    },
    {
      refetchOnMount:true,
      staleTime:600000,//10min
      cacheTime:600000,
      refetchInterval:600000,
      refetchOnWindowFocus: false,
      
    }
  );
  
  return (
    <>
      {isError && <Error404 />}
      {isLoading && <Loading />}
      {isSuccess && (
        // <HotelDetails />
        <div className="container px-6 pt-10 md:pt-16 mx-auto">
          <div className="">
            <div className="container mb-16">
              <HotelDetails />
              <h3
                id="add-new-food"
                className="flex  font-medium text-gray-900  dark:text-gray-300 text-3xl my-10"
              >
                Add New Food
              </h3>
              <AddFood />


             
            </div>
          </div>
        </div>
      )}
    </>
  );
  function HotelDetails(){
    const userPhoneNumber = useSelector((state:RootState)=>state.UserInfo.phoneNumber)
    const dispatch = useDispatch()

    const [inputs, setInputs] = useState({ 
      id: '',
      name: '',
      description: '',
      isAvailable: false,
      image: null,
      imagePreview: null,
      canAutoOpen:false,
      autoOpenTime:'',
      autoCloseTime:'',
      // canAutoClose:false

    })
    console.log(inputs);
    
    // const formData = new FormData();
    
      // Update the formData object
      
    const [justInitiated, setJustInitiated] = useState(true)
    if(isSuccess && justInitiated)
    {
      
      setJustInitiated(false)
      setInputs({ 
        id: data.data.id,
        name: data.data.name,
        description: data.data.description,
        isAvailable: data.data.isAvailable,
        image: null,//This Makes form data to avoid unecessary append
        imagePreview: data.data.image,
        canAutoOpen:data.data.canAutoOpen,
        autoOpenTime:`2020-05-20T${timeFormatter24HR_TimeOnly(data.data.autoOpenTime)}`,//Random date doesnt matter we extraxt only time.  Formattting like this important for time  picker
        autoCloseTime: `2020-05-20T${timeFormatter24HR_TimeOnly(data.data.autoCloseTime)}`,
        // canAutoClose:data.data.canAutoClose,


      })
    }
    
    const handleChange = (event: any) => {
      let name: any,value: any;
  
      name = event.target.name;
  
      
      if(event.target.name=="image")
      {
        value = event.target.files[0];
        
        // @ts-ignore
        setInputs(values => ({...values, imagePreview:URL.createObjectURL(event.target.files[0]) }))

      }else if(event.target.type==='text' || event.target.type==='number'){
        // For Input Form
        value = event.target.value;
      }
      if(event.target.type==='checkbox')
      {
        // For check Box
        value = event.target.checked;
      }
      
      setInputs(values => ({...values, [name]: value}))
    }

    const { mutate:UpdateUser } = usePostReq()
  
    const handleSubmit = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      if(new Date(inputs.autoOpenTime)>new Date(inputs.autoCloseTime)){
        dispatch(Alert({"status":"error","heading":"Error","message":"Open time  cannot be greater than closing time"}))
        return
      }
      const config = FormData_Config
      const formData = new FormData();
      if ((inputs.image!==null)){
        formData.set(
          "image",
          // @ts-ignore
          inputs.image,
          // @ts-ignore
        );
      }
      console.log(inputs);
      
      formData.set('id', inputs.id);
        
      formData.set('description',  inputs.description);
      formData.set('isAvailable',  bool2str(inputs.isAvailable));
      formData.set('canAutoOpen',  bool2str(inputs.canAutoOpen));
      formData.set('autoOpenTime', new Date( timeFormatter24HR_for_api(inputs.autoOpenTime) ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })) ;
      formData.set('autoCloseTime', new Date( timeFormatter24HR_for_api(inputs.autoCloseTime)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })) ;

      // formData.set('canAutoClose', bool2str(inputs.canAutoClose));

      
      const url = '/api/hotels/update/details/'
      // axios.post(url, formData, {
      //   headers: {
      //     'content-type': 'multipart/form-data'
      //   }
      // })
      //     .then(res => {
      //       console.log(res.data);
      //     })
      //     .catch(err => console.log(err))
      // @ts-ignore
      
      UpdateUser({body:formData,config,url})
    }
    return(
      
      <form onSubmit={handleSubmit} className="flex flex-col items-stretch align-middle container   ">
                {/* Hotel Description */}
                <HotelOwnerHeader  manage/>
                <div className="my-7">
                  <label
                    htmlFor="message"
                    className="block mb-5 pl-3 font-medium text-gray-900  dark:text-gray-300 text-3xl my-5"
                  >
                    Description
                  </label>
                  <input
                    id="message"
                    name="description"
                    className=" p-2.5  w-full h-24  text-sm md:text-lg text-gray-900 bg-gray-50 rounded-t-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Minimum 10-20 catchy words"
                    onChange={(e) =>handleChange(e)}
                    value={inputs.description}
                  />
                </div>

                {/* Hotel Image Field */}
                <div className="flex justify-center items-center  mb-9">
                  <label
                    htmlFor={`dropzone-file-hotel-edit-image-${data?.data.id}`}// !inCase of change ID below with same name
                    className="flex  justify-center items-center w-full h-64 bg-gray-50 rounded-b-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col sm:flex-row justify-evenly w-full items-center pt-5 pb-6">
                      {inputs.imagePreview && <img className="w-36 h-36" src={inputs.imagePreview} alt="" /> }
                      
                      <div className=" dark:text-gray-100 flex flex-col justify-center items-center ">

                      <svg
                            aria-hidden="true"
                            className="mb-3 w-10 h-10  "
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm ">
                            <span className="font-semibold">Click to upload</span> or
                            drag and drop
                          </p>
                          <p className="text-xs">
                            JPG  (Resolution 1:1)
                          </p>
                  </div>
                      
                    </div>
                    <input  id={`dropzone-file-hotel-edit-image-${data?.data.id}`} //! inCase of change htmlfor above with same name
                    type="file" className="hidden"
                    onChange={(e)=>handleChange(e)} 
                    name='image'
                    accept=".jpg, .jpeg, .png"/>
                  </label>
                  
                </div>

                <div className="align-middle place-items-center mx-3 grid grid-cols-2 md:grid-cols-5 items-center   gap-4">
                {/* <label
                    className="inline-flex relative items-center cursor-pointer mx-2"
                  >
                    <input
                      type="checkbox"
                      value=""
                      id="isAvailable-toggle"
                      className="sr-only peer"
                      name="isAvailable"
                      onChange={(e)=>handleChange(e)} checked={inputs.isAvailable}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                    </div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Open
                    </span>
                </label> */}
                {inputs.isAvailable?
                (
                  <button type="button" onClick={()=>setInputs(values => ({...values, isAvailable: !inputs.isAvailable}))} className="text-white h-full bg-green-700 hover:bg-green-800 mt-3 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-8 py-1 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800 w-fit">Opened Now</button>
                ):
                (
                <button type="button"  onClick={()=>setInputs(values => ({...values, isAvailable: !inputs.isAvailable}))} className="text-white h-full bg-red-700 hover:bg-red-800 mt-3 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-8 py-1 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800 w-fit">Closed Now</button>
                )

                }
                {inputs.canAutoOpen?
                (
                  <button type="button" onClick={()=>setInputs(values => ({...values, canAutoOpen: !inputs.canAutoOpen}))} className="text-white h-full bg-green-700 hover:bg-green-800 mt-3 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-8 py-1 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800 w-fit text-center">Auto Open On</button>
                ):
                (
                <button type="button"  onClick={()=>setInputs(values => ({...values, canAutoOpen: !inputs.canAutoOpen}))} className="text-white h-full bg-red-700 hover:bg-red-800 mt-3 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-8 py-1 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800 w-fit">Auto Open Off</button>
                )

                }
                {/* {inputs.canAutoClose?
                (
                  <button type="button" onClick={()=>setInputs(values => ({...values, canAutoClose: !inputs.canAutoClose}))} className="text-white h-full bg-green-700 hover:bg-green-800 mt-3 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-8 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Auto Close - On</button>
                ):
                (
                <button type="button"  onClick={()=>setInputs(values => ({...values, canAutoClose: !inputs.canAutoClose}))} className="text-white h-full bg-red-700 hover:bg-red-800 mt-3 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-8 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800">Auto Close - Off</button>
                )

                } */}
                  <HotelTimePicker setInputs={setInputs} timeType="Open Time" initialTime={inputs.autoOpenTime}/>
                  <HotelTimePicker setInputs={setInputs} timeType="Close Time" initialTime={inputs.autoCloseTime}/>
                
                
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 mr-2 mb-2 dark:border-white border-gray-600 border-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full col-span-2  md:col-span-1">Save</button>
                </div>
                


              </form>

    )
  }
}

