export const RQ_Constants = {
    one_minute : {
        refetchOnMount:true,
        staleTime:60000,//1min
        cacheTime:60000,
        refetchInterval:60000,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        retry:5
      }
}


