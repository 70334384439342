import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../App/store";

 

export function DisplayRazorPay() {
    const placeOrderResponse= useSelector((state:RootState)=>state.GlobalData.placeOrderResponse) ;
    const userInfo= useSelector((state:RootState)=>state.UserInfo) ;
    

async function displayRazorpayPaymentSdk() {
    
  const options = {
      key: placeOrderResponse.merchantId,
      amount: placeOrderResponse.amount.toString(),
      currency: "INR",
      name: userInfo.username,
      description: "Test Transaction",
      image: "/static/logo192.png",
      order_id: placeOrderResponse.orderId,
      callback_url: window.location.origin +"/api/orders/razorpay_callback/",
      redirect: true,
      prefill: {
        name: userInfo.username,
        email: userInfo.email,
        contact: userInfo.phoneNumber,
    },
    notify: {
        "email": true,
        "sms": false
      },
      notes: {
          address: "None",
      },
      theme: {
          color: "#374151",
      },
  };
    // @ts-ignore
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}

displayRazorpayPaymentSdk()
return <></>

}

