import React from 'react'
import HotelProfitTable from './HotelProfitTable'
import AboutHotel from './HotelDashBoard/AboutHotel'

function HotelHomeDashBoard() {
  return (
    <div className='w-full h-full flex justify-center py-16'>

    <div className='container'>
    <AboutHotel />
      <HotelProfitTable/>
      <div className=' px-4 lg:text-right mt-8 dark:text-gray-300'>
          All dashboard data are based on UTC time zone (-5.30 Indian Standard Time)
      </div>
    </div>
    </div>
  )
}

export default HotelHomeDashBoard