

export default function ValidateOrder(value:any) {

  let processedFoodList = {}
  for (let [id, count] of Object.entries(value))
  {
    // @ts-ignore
    if(count>0)
    {
      processedFoodList = {...processedFoodList,[id]:count}
    }
  }
return processedFoodList
}
