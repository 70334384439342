import React, { useEffect, useState } from 'react'
import FoodListComponent from './FoodListComponent'
import { motion } from 'framer-motion'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import Loading from '../../../Animations/Loading'
import Error404 from '../../../Components/Error404'
import CheckOrderButton from '../../../Components/Buttons/CheckOrderButton'
import { useDispatch } from 'react-redux';
import { FoodListInitialize } from './FoodSlicer'
import { setGlobalData } from '../../../GlobalSlicer/GlobalSlicer'
import { checkFirstTimeVisit, redirect_to_Android, timeFormatter12HR, timeFormatter12HR_TimeOnly } from '../../../Functionalities/smallFns'
import RoomIcon from '@mui/icons-material/Room';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Alert } from '../../../Animations/Alerts/AlertSlicer'


function Foods() {


  const dispatch = useDispatch()

  const { hotelID } = useParams();

  dispatch( setGlobalData( { orderHotelID: hotelID } ) )
  const axiosParam = "/api/getFoodList/" + hotelID + "/"
  let queryID = 'fetchFoods-' + hotelID

  const { isLoading, isError, isSuccess, data, error } = useQuery( [queryID], () => {

    return ( axios.get( axiosParam ) )
  },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 30000000,//500min
      cacheTime: 30000000,
      refetchInterval: 30000000,

    } )

  var errorMessage;
  if ( error instanceof Error ) {
    errorMessage = error.message;
  }

  const [availableFoods, setAvailableFoods] = useState([]);
  const [unavailableFoods, setUnavailableFoods] = useState([]);
  
  useEffect(() => {
    if (isSuccess) {
      let available: any = [];
      let unavailable: any = [];
  
      data.data.forEach((item:any) => {
        item.foods.forEach((food: any) => {
          if (food.isAvailable) {
            available.push(food);
            dispatch(FoodListInitialize({ id: food.id, count: 0 }));

          } else {
            unavailable.push(food);
          }
        });
      });
  
      setAvailableFoods(available);
      setUnavailableFoods(unavailable);
    }
  }, [isSuccess, data]);
  
  // Hotels Details Query
  const { isLoading: _isLoading, isError: _isError, isSuccess: _isSuccess, data: _data, error: _error } = useQuery( [`fetchHotelDetalsInOrders-${ hotelID }`], () => {
    const axiosParam_2 = "/api/getHotelsFullDetails/" + hotelID + "/"

    return ( axios.get( axiosParam_2 ) )
  },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: 300000,//5min
      cacheTime: 300000,
      refetchInterval: 300000,

    } )

    // SEO
    useEffect(() => {
      if(_isSuccess){
        let hotelName = _data.data.name;
        document.title = "Kallardo - " + hotelName;
        // @ts-ignore
        document.querySelector('meta[name="description"]').setAttribute("content", `Pre Order food from ${hotelName} through Kallardo` );

        if(!_data.data.isAvailable){
        dispatch(Alert({"status":"info","heading":"Closed","message":"Oops! Restaurant is closed now"}))
        }

      }
    }, [_isSuccess])
    // Handling Title

      useEffect(() => {
        const original_description =  document.querySelector('meta[name="description"]')?.getAttribute("content");
        return () => {
        document.title = 'Kallardo';
        // @ts-ignore
        document.querySelector('meta[name="description"]').setAttribute("content", original_description  );
        }
      }, [ ])

// Now you can use availableFoods and unavailableFoods in your code


  useEffect( () => {
    // Android Redirect Pop up
    if ( checkFirstTimeVisit() ) {
      dispatch( setGlobalData( { isAndroidRedirectPopup: true } ) )
    }
    // To handle if scanned from restaurants QR code
    if (window.location.href.includes("?utm_source=restaurant_QR")){
      redirect_to_Android( `kallardo://Restaurants/QR_to_website/${ hotelID }` )
    }
    return () => {
    }
  }, [] )

  return (
    <div className="divide-y-2 dark:divide-gray-700">

      <section className="text-gray-700 dark:text-gray-300 dark:bg-gray-800 body-font">

        <div className="container px-5 py-24 mx-auto">
          {_isError && <Error404 />}
          {_isLoading && <Loading />}
          {_isSuccess && (
            // <HotelDetails />
            <div className='flex justify-center align-middle text-center items-center'>
              <div className="container px-6 py-5 md:pb-16 mx-auto">
                <motion.div
                  initial={{
                    x: -50,
                    opacity: 0.2
                  }}
                  whileInView={"animation"}
                  variants={{
                    animation: {
                      x: 0,
                      opacity: 1
                    }
                  }}
                  transition={{
                    duration: 0.7
                  }}
                  className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white  ">
                  <h1 className='text-4xl'> {_data.data.name} </h1>
                </motion.div>
                <div className="mt-2">
                  <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
                  <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
                  <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
                </div>
                {_isSuccess && 
              // Address
              (<>
              <address className='mt-4'><RoomIcon/> {_data.data.address_Line_1} {_data.data.address_Line_2} {_data.data.pincode}</address>
              <div className='mt-4'><AccessTimeIcon/> {timeFormatter12HR_TimeOnly(_data.data.autoOpenTime)} - {timeFormatter12HR_TimeOnly(_data.data.autoCloseTime)}</div>
              </>)
              
            }
              </div>

            </div>
          )}


          <div >

            {isLoading && <Loading />}
            {isError && <Error404 message={errorMessage} />}

            {
  isSuccess && data.data.map((item:any) => {
    // Filter available foods for this category
    const categoryAvailableFoods = availableFoods.filter((food:any) => food.category === item.category);

    // Only render the category if it has available foods
    return categoryAvailableFoods.length > 0 ? (
      <div key={item.category}>
        <div className='flex flex-col justify-center align-middle items-center'>
          <h2 className='py-2 text-4xl text-center'>{item.category}</h2>
          <div className="">
            <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
            <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
            <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 sm:mx-auto sm:mb-2 -mx-2 ">
          {categoryAvailableFoods.map((food:any) => {
            return (
              <FoodListComponent key={food.id} isVeg={food.isVeg} foodName={food.name} price={food.price}
              counterVisible={true} id={food.id} image={food.image} />
            );
          })}
        </div>
      </div>
    ) : null;
  })
}


          </div>
        </div>
      </section>


      <section className="text-gray-700 dark:text-gray-300 dark:bg-gray-800 body-font bg-neutral-100">
        <div className="container px-5 py-24 mx-auto">
          <motion.div
            initial={{
              y: 50,
              opacity: 0
            }}
            whileInView={"animation"}
            variants={{
              animation: {
                y: 0,
                opacity: 1
              }
            }}
            transition={{
              duration: 0.7
            }} className="text-center mb-20">
            <p className="sm:text-3xl text-2xl font-medium text-center title-font dark:text-white mb-4">Out of Stock</p>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto"> The food will be available soon !</p>
          </motion.div>

          {/* Lists */}

          <div>

          {
  isSuccess && data.data.map((item:any) => {
    // Filter unavailable foods for this category
    const categoryUnavailableFoods = unavailableFoods.filter((food:any) => food.category === item.category);

    // Only render the category if it has unavailable foods
    return categoryUnavailableFoods.length > 0 ? (
      <div key={item.category}>
        <div className='flex flex-col justify-center align-middle items-center'>
          <h3 className='py-2 text-center'>{item.category}</h3>
          <div className="">
            <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
            <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
            <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 sm:mx-auto sm:mb-2 -mx-2 ">
          {categoryUnavailableFoods.map((food:any) => {
            return (
              <FoodListComponent key={food.id} isVeg={food.isVeg} foodName={food.name} price={food.price}
              counterVisible={false} id={food.id} image={food.image} />
            );
          })}
        </div>
      </div>
    ) : null;
  })
}


          </div>
        </div>
      </section>
      <CheckOrderButton hotelID={hotelID} />
    </div>
  )
}

export default Foods