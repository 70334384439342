import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
    visible: boolean,
    mode:string,
    orderID:string
}

const initialState: CounterState = {
    visible:false,
    mode:'OTP',
    orderID:""
}

export const DeliveryBoyPopUpSlicer = createSlice({
  name: 'DeliveryBoyPopUp',
  initialState,
  reducers: {
    ToggleDeliveryPop: (state) => {
        state.visible = !state.visible
    },
    ModePopUpDeliveredOTP: (state) => {
      state.mode = 'OTP'
    },
    ModePopUpUnDeliverableReason: (state) => {
      state.mode = 'reason'
   },
   setDeliveryPopUpOrderID:(state, action: PayloadAction<string>) => {
      state.orderID = action.payload
   }
  },
})

// Action creators are generated for each case reducer function
export const { ToggleDeliveryPop,ModePopUpDeliveredOTP, ModePopUpUnDeliverableReason } = DeliveryBoyPopUpSlicer.actions

export default DeliveryBoyPopUpSlicer.reducer