import React from 'react'
import Counter from '../../../Components/Buttons/Counter'
import { motion } from 'framer-motion'
import { FoodListUpdate } from './FoodSlicer'
import { useDispatch } from 'react-redux'

function FoodListComponent({isVeg,foodName,counterVisible,price,id,image}:
  {isVeg:boolean, foodName:string,counterVisible?:boolean,price:number, id:number,image:any}) {

    let textSize;
    if (foodName.length > 30) {
        textSize = 'text-base leading-5 ';
    } else if (foodName.length > 25) {
        textSize = 'text-lg';
    } else {
        textSize = 'text-xl';
    }
  const listBar = {
    initial:{
      x : -50,
      opacity:0,
  },
  animation:{
    x:0,
    opacity:1,
    transition:{
      duration : 0.7
  },
}    
  }
  return (
    <motion.div variants={listBar}
    initial="initial"
    whileInView="animation"
    
    className="p-2 py-3 w-full static ">




      
    <div className=''>
      <div className=" relative h-32 md:h-48 max-h-full  md:m-5 flex  items-center bg-white rounded-lg border shadow-md flex-row max-w-xl hover:bg-neutral-100 dark:border-gray-700 dark:bg-gray-700 dark:hover:bg-zinc-800 ">
        <img className="aspect-square object-cover h-auto rounded-t-lg w-32 md:w-48 rounded-none rounded-l-lg" 
        src={image} alt={foodName} />
        
        <div className="block relative  h-full justify-between p-4 leading-normal w-80 sm:w-96 overflow-hidden">
          
          <h3 className={"mb-2 font-medium tracking-tight text-gray-900  dark:text-white w-10/12 "+ textSize}>{foodName} </h3>
          
          <div className='absolute w-6 h-6 scale-90 md:scale-100 right-0 top-0 m-3'>
                  {isVeg &&  <img src="/static/logos/veg.svg" alt="Veg"/>}
                  {!isVeg && <img src="/static/logos/nonVeg.svg" alt="Non-Veg"/>}
          </div>
          <div className='absolute px-3 md:pl-0 pr-3 right-0 bottom-2 flex items-center justify-between  w-full'>
              <span className='px-4 text-2xl dark:text-gray-200 inline-block whitespace-nowrap'><b>₹</b> {Math.trunc(price)}</span>
              <div className='scale-90 md:scale-100'>
                {counterVisible? <Counter id={id} foodName={foodName} price={price} /> : null}
              </div>
              
          </div>
          {/* <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 overflow-hidden">{value.description}</p> */}
        </div>

      </div>  
      
    </div>
      </motion.div>
  )
}

export default FoodListComponent