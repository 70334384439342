import React, { useState } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import DeliveryBoyHistoryCardList from './DeliveryBoyHistoryCardList'
import Error404 from '../../../Components/Error404'
import Loading from '../../../Animations/Loading'
import { RootState } from '../../../App/store'
import { useSelector } from 'react-redux'
import DummyScreen from '../../../Components/DummyScreen'
import JSON_to_Foodlist from '../../../Functionalities/Data_To_TSX/JSON_to_Foodlist'
import DeliveryBoyHeader from '../DeliveryBoyHeader'
import JSON_Config from '../../../Constants/Axios/JSON_Config'
import { RQ_Constants } from '../../../Constants/Axios/ReactQueryRefetch'

function DeliveryBoyHistory() {

  const { isLoading, isError,isSuccess, data, error } = useQuery(['fetchDeliveryOrderHistory'], () => {
    return (axios.get(`/api/getDeliveryBoyOrdersHistory/`,JSON_Config))
  },RQ_Constants.one_minute)
  var errorMessage;
    if (error instanceof Error) {
      errorMessage = error.message;
    }
  
  
  const isAuthenticated = useSelector((state:RootState)=>state.UserInfo.isAuthenticated)


  return (
    <>
    <DeliveryBoyHeader />

    {isError && isAuthenticated&& <Error404 message={errorMessage} /> }
    {isLoading && isAuthenticated && <Loading />  }
    {!isAuthenticated && <DummyScreen />}
      
    

    <div className='dark:bg-gray-800 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-y-10 py-10 px-5 '>
    {
      isSuccess && data?.data?.map((value: {id:any|null;address:any|null; userName:any|null; cookedTime:any|null;pickedUpTime:any|null;
        deliveryBoyName:any|null;hotelName: any|null; orderedTime: any|null; requestedPickupTime: any|null; deliveredTime: any|null; 
        total: any|null; status: any|null;deliveryBoypickedUpTime:any|null;
        hotelDetails :any|null;processedFoodList:any;})  =>(HistoryListJSX(value)
        
      ) ) 
    }

    </div>
    </>
    
  )
}
function HistoryListJSX(value: any)
{
  return (
          <DeliveryBoyHistoryCardList key={value.id} id={value.id} userName={value.userName} 
    orderedTime={value.orderedTime} cookedTime={value.cookedTime} pickedUpTime={value.pickedUpTime}
    requestedPickupTime={value.requestedPickupTime} deliveredTime={value.deliveredTime}
    total={value.total} status={value.status} deliveryBoyName={value.deliveryBoyName}
    address={value.address} deliveryBoypickedUpTime={value.deliveryBoypickedUpTime}
    hotelName={value.hotelDetails.name}
     hotelAddress={value.hotelDetails.address}  hotelImg={value.hotelDetails.image} processedFoodList={value.processedFoodList} />
          )

}
export default DeliveryBoyHistory


