import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div>


            <footer className="dark:bg-gray-900 bg-neutral-300 py-7">
                <div className="container  px-6 py-4 mx-auto">
                    <div className="lg:flex">
                        <div className="w-full -mx-6 lg:w-2/5">
                            <div className="px-6">
                                <div>
                                    <a href="#" className="text-xl font-bold text-gray-800 dark:text-white hover:text-gray-700 dark:hover:text-gray-300">Kallardo</a>
                                </div>

                                <p className="max-w-md mt-2 text-gray-500 dark:text-gray-400 pr-3">Enjoy the food pre-ordering experince through Kallardo</p>

                                <div className="flex mt-4 -mx-2">
                                    <a href="https://www.linkedin.com/company/kallardo" target="_blank" className="mx-2 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400" aria-label="Linkden">
                                        <svg className="w-4 h-4 fill-current" viewBox="0 0 512 512">
                                            <path d="M444.17,32H70.28C49.85,32,32,46.7,32,66.89V441.61C32,461.91,49.85,480,70.28,480H444.06C464.6,480,480,461.79,480,441.61V66.89C480.12,46.7,464.6,32,444.17,32ZM170.87,405.43H106.69V205.88h64.18ZM141,175.54h-.46c-20.54,0-33.84-15.29-33.84-34.43,0-19.49,13.65-34.42,34.65-34.42s33.85,14.82,34.31,34.42C175.65,160.25,162.35,175.54,141,175.54ZM405.43,405.43H341.25V296.32c0-26.14-9.34-44-32.56-44-17.74,0-28.24,12-32.91,23.69-1.75,4.2-2.22,9.92-2.22,15.76V405.43H209.38V205.88h64.18v27.77c9.34-13.3,23.93-32.44,57.88-32.44,42.13,0,74,27.77,74,87.64Z" />
                                        </svg>
                                    </a>

                                    <a href="https://www.facebook.com/Kallardo/" target="_blank" className="mx-2 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400" aria-label="Facebook">
                                        <svg className="w-4 h-4 fill-current" viewBox="0 0 512 512">
                                            <path d="M455.27,32H56.73A24.74,24.74,0,0,0,32,56.73V455.27A24.74,24.74,0,0,0,56.73,480H256V304H202.45V240H256V189c0-57.86,40.13-89.36,91.82-89.36,24.73,0,51.33,1.86,57.51,2.68v60.43H364.15c-28.12,0-33.48,13.3-33.48,32.9V240h67l-8.75,64H330.67V480h124.6A24.74,24.74,0,0,0,480,455.27V56.73A24.74,24.74,0,0,0,455.27,32Z" />
                                        </svg>
                                    </a>

                                    <a href="https://twitter.com/Kallardo_offici" target="_blank" className="mx-2 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400" aria-label="Twitter">
                                        <svg className="w-4 h-4 fill-current" viewBox="0 0 512 512">
                                            <path d="M496,109.5a201.8,201.8,0,0,1-56.55,15.3,97.51,97.51,0,0,0,43.33-53.6,197.74,197.74,0,0,1-62.56,23.5A99.14,99.14,0,0,0,348.31,64c-54.42,0-98.46,43.4-98.46,96.9a93.21,93.21,0,0,0,2.54,22.1,280.7,280.7,0,0,1-203-101.3A95.69,95.69,0,0,0,36,130.4C36,164,53.53,193.7,80,211.1A97.5,97.5,0,0,1,35.22,199v1.2c0,47,34,86.1,79,95a100.76,100.76,0,0,1-25.94,3.4,94.38,94.38,0,0,1-18.51-1.8c12.51,38.5,48.92,66.5,92.05,67.3A199.59,199.59,0,0,1,39.5,405.6,203,203,0,0,1,16,404.2,278.68,278.68,0,0,0,166.74,448c181.36,0,280.44-147.7,280.44-275.8,0-4.2-.11-8.4-.31-12.5A198.48,198.48,0,0,0,496,109.5Z" />
                                        </svg>
                                    </a>
                                    <a href="https://www.instagram.com/kallardo_official/" target="_blank" className="mx-2 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400" aria-label="Twitter">
                                        <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 lg:mt-0 lg:flex-1">
                            <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4">
                                <div className='col-span-2'>
                                    <h3 className="text-xl text-gray-700 uppercase dark:text-white ">Legal</h3>
                                    <a href="https://www.termsandconditionsgenerator.com/live.php?token=QLLn9GinJuTwRRnVI6EtYpqcNn06MRoC" target="_blank" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Terms and Conditions</a>
                                    <Link to="/refund_policy" target="_blank" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Cancellation and Refund Policy</Link>
                                    <Link to="/delivery_policy" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Shipping and Delivery Policy</Link>
                                    <a href="https://www.privacypolicygenerator.info/live.php?token=FCVKt1eCsbjOxdVUvQYy8zz9fk8HJ98P" target="_blank" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Privacy Policy</a>

                                    {/* <a href="#" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Offer Terms</a> */}


                                    {/* <a href="#" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">community</a> */}
                                    {/* <a href="#" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Careers</a> */}
                                </div>

                                <div>
                                    <h3 className="text-xl text-gray-700 uppercase dark:text-white">Social Media</h3>
                                    <a href="https://www.linkedin.com/company/kallardo" target="_blank" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Linkedin</a>
                                    <a href="https://www.instagram.com/kallardo_official/" target="_blank" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Instagram</a>
                                    <a href="https://www.facebook.com/profile.php?id=61554871436808" target="_blank" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Facebook</a>
                                    <a href="https://twitter.com/Kallardo_offici" target="_blank" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Twitter</a>
                                </div>

                                {/* <div>
                            <h3 className="text-xl text-gray-700 uppercase dark:text-white">Products</h3>
                            <a href="#" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Mega cloud</a>
                            <a href="#" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Aperion UI</a>
                            <a href="#" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Meraki UI</a>
                        </div> */}

                                <div id='contact'>
                                    <h3 className="text-xl text-gray-700 uppercase dark:text-white">Contact</h3>
                                    <Link to="/contact_us" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Contact Us</Link>
                                    <a href='tel:+9108062177665' className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">+91 08062177665</a>
                                    <a href="mailto:support@kallardo.com" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">support@kallardo.com</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="h-px my-6 bg-gray-300 border-none dark:bg-gray-700" />

                    <div>
                        <p className="text-center text-gray-800 dark:text-white">© Kallardo 2022 - {new Date().getFullYear()} <br /> All rights reserved</p>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Footer