import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
    FoodList: { [id: number]: number}
    FoodListItemsFinal:{},
    address:string,
      
}

const initialState: CounterState = {
  FoodList: {},
  FoodListItemsFinal: {},
  address:"Address",

}

export const FoodSlicer = createSlice({
  name: 'FoodListCounter',
  initialState,
  reducers: {
    FoodListUpdate: (state, action: PayloadAction<{ id: number, count:number}>) => {
      const id    = action.payload.id;
      const count = action.payload.count
      Object.assign(state.FoodList,{[id]: count}) 
    },
    // TO avoid uneccessary assigning after data reloads
    FoodListInitialize: (state, action: PayloadAction<{ id: number, count:number}>) => {
      const id    = action.payload.id;
      const count = action.payload.count
      if(state.FoodList[id]>0){
        return
      }
      Object.assign(state.FoodList,{[id]: count}) 

      

    },
    FoodListItemsFinalUpdate: (state, action: PayloadAction<object>) => {
      
      Object.assign(state.FoodListItemsFinal,action.payload)
    },
    setOrderAddress(state, action: PayloadAction<string>) {
      state.address=action.payload
    },
    clearAllFoodListRedux: (state) => {
      state.FoodListItemsFinal={}
      state.FoodList={}
    },
  },
})

// Action creators are generated for each case reducer function
export const { FoodListUpdate,FoodListInitialize,FoodListItemsFinalUpdate,setOrderAddress,clearAllFoodListRedux } = FoodSlicer.actions

export default FoodSlicer.reducer
