
import { Alert } from "../Animations/Alerts/AlertSlicer"
import {isObject} from "../Functionalities/smallFns"



export function axiosErrorHandler({dispatch,data,error,errorCallBackFn}:any){

if(error.response.status===500){
        dispatch(Alert({"status":"error","heading":"Oops!","message":"Internal Server Error"}))
        errorCallBackFn(data)
        return
    }
    //@ts-ignore
    let object = error.response.data
    let lastKey = "";
    let lastMessage = "";
    loopObject(object)
    function loopObject(object:object) {

        for (const [key, value] of Object.entries(object)) {
            
            lastKey = typeof(key)==="string"?key: lastKey
            lastMessage = typeof(value)==="string"?value: lastMessage

            // Check if nested Array
            if (Array.isArray(value)){
                lastMessage=value[0]
                return
            }
            // Check if nested Object
            if (isObject(value)){
                loopObject(value)
                return
            }
            if((value+"").length>50)
            {
                return
            }
            lastMessage=value+""
            
        }
    }

    let lastKeyMessage = ""
    if(! (lastKey =="0")){
        lastKeyMessage = lastKey + " - "
    }
    dispatch(Alert({"status":"error","heading":"Error","message":lastKeyMessage + lastMessage}))
    errorCallBackFn(data)
}  


