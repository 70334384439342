export const ANDROID_URL = "kallardo://"
export const PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=com.kallardo.mobile"

export const is_TWA = document.referrer.includes( 'android-app://com.kallardo.twa' )



export const foodCategoriesSuggestion = [
    'Briyani',
    'Juices',
    'Starter',
    'Ice Cream',
]

export const OrderStatus = {
    ORDER_INITIATED: "Order Initiated",//1
    ORDER_PLACED: "Order placed",//2


    READY_TO_PICKUP: "Ready to pickup",//3
    IN_STORE_PICKED_UP: "In store picked up",//4


    READY_FOR_DELIVERY_BOY_PICK: "Ready for Delivery",//3
    OUT_FOR_DELIVERY: "Out for Delivery",//5
    DELIVERED: "Delivered",//6
    UNDELIVERED: "Undelivered",//6
}
