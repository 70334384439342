import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from '../../Animations/Alerts/AlertSlicer'
import { RootState } from '../../App/store'
import { browserNotify, requestBrowserNotificationPermission } from '../../Functionalities/browserNotification'

function Display_DB_Notifications() {
  // useEffect(() => {
  //   setTimeout(function () { // No need because FCM DOes  it automatically
  //     requestBrowserNotificationPermission()
  //   }, 20000);//20 sec
  //   return () => {}
  // }, [])
  


    const dispatch = useDispatch()
    const isHotelOwner = useSelector((state:RootState)=>state.UserInfo.isHotelOwner)

    const { isLoading, isError,isSuccess , data, error,isPreviousData } = useQuery(['fetch_DB_notifcations'], () => {
        return (axios.get('/api/getDB_notifications/general/'))
      },
      {
        refetchOnMount:true,
        staleTime:10000,//10sec
        cacheTime:10000,
        refetchInterval:10000,
        refetchOnWindowFocus: true,
        keepPreviousData:false,
    
      })

      
      if (isSuccess){
        setTimeout(function () {
        let i=0;
        data.data.forEach((value: any) =>{
          i++
          setTimeout(() => {
            dispatch(Alert({"status":value.status,"heading":value.heading,"message":value.description}))
          }, i*400);
        })

      }, 1100);

      }
      
  return (
    <>
    </>
  )
}

export default Display_DB_Notifications

// Not used May be in future if more dynamic
// function Hotel_Order_Notifications() {
//   const dispatch = useDispatch()
//   let isUserOnScreen = false
//   const { isLoading, isError,isSuccess , data, error,isPreviousData,refetch } = useQuery(['fetch_Hotel_Order_notifcations'], () => {
//     return (axios.get('/api/getDB_notifcations/NewOrder/'))
//   },
//   {
//     refetchOnMount:true,
//     staleTime:15000,//15sec
//     cacheTime:15000,
//     refetchInterval:15000,
//     refetchOnWindowFocus: true,
//     keepPreviousData:false,
//     refetchIntervalInBackground:true,

//   })
//   if (isSuccess){
//     if (isUserOnScreen) {
//       setTimeout(function () {
//         let i=0;
//         data.data.forEach((value: any) =>{
//           i++
//           setTimeout(() => {
//             dispatch(Alert({"status":value.status,"heading":value.heading,"message":value.description}))
//           }, i*400);
//         })
    
//       }, 1100);
//     }
//     else{
//       data.data.forEach((value: any) =>{
//         browserNotify("New order!", "New order has been placed through Kallardo")
//       })
//     }
    

//   }
//   // document.addEventListener("visibilitychange", (event) => {
//   //   if (document.visibilityState == "visible") {
//   //     isUserOnScreen=true
//   //   } else {
//   //     isUserOnScreen=false
//   //   }
//   // });
//   return (
//     <></>
//   )
// }
