import React from 'react'
import FoodSearchBar from '../../../../Components/SearchBar/FoodSearchBar'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../App/store'
import { snakeCase } from '../../../../Functionalities/Formatter/SnakeCase'
import { motion } from 'framer-motion'
import { Link} from "react-router-dom";

import Loading from '../../../../Animations/Loading'
import Error404 from '../../../../Components/Error404'
function FoodSearchScreen() {
  const query = useSelector((state:RootState)=>state.GlobalData.searchFoodQuery)

  return (
  <>
  <div>
    <FoodSearchBar/>
    <motion.div 
     className='text-gray-700 dark:text-gray-300 body-font'>
        <motion.div
              initial={{
                  y : -50,
                  opacity:0
              }}
              whileInView={"animation"}
              variants={{animation:{
                  y:0,
                  opacity:1
                }}}
                transition={{
                    duration : 0.7
                }} className="text-center mb-6  py-10">
          <p className="sm:text-3xl text-2xl font-medium text-center title-font dark:base-orange-text base-orange-text mb-4 ">Search - {query}</p>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto"> Here are your results </p>
        </motion.div>


        { query!=''  &&  <FoodSearchData />}

    </motion.div>

  </div>
  </>
  )
}

function FoodSearchData() {
  const query = useSelector((state:RootState)=>state.GlobalData.searchFoodQuery)

  // searchFoodQuery
  const params = {"query":query }
  
  const { isLoading, isError,isSuccess, data, error,refetch } = useQuery(['fetch-SearchedFood',query], () => {
    return (axios.get('/api/get_searched_food/',{params}))
  })
  const child = {
    initial:{
        x : -30,
        opacity:0,     
    },
    animation:{
        x:0,
        opacity:1,
        transition:{
          duration : 1,
      }
    },    
  }

  return (
    <>
    {/*@ts-ignore */}
      {isError && <Error404 message={error?.message} />}
      {isLoading && <Loading />  }
      <div className='grid lg:grid-cols-2 grid-cols-1 justify-items-center dark:bg-gray-800 mb-10'>

    {
          isSuccess &&  data?.data.food.map((value:any) =>(
            <motion.div 
            initial="initial"
            whileInView="animation" 
            variants={child} 
            key={value.id}
            >
            <Link to={`/Foods/${snakeCase(value.hotelDetails.name)}/${value.hotelDetails.id}`}  className=" relative md:h-48 max-h-full m-5 flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-neutral-100 dark:border-gray-700 dark:bg-gray-700 dark:hover:bg-zinc-800 ">
              <img className="aspect-square object-cover w-full h-60 md:h-auto rounded-t-lg md:w-48 md:rounded-none md:rounded-l-lg" 
              src={value.hotelDetails.image} alt={value.hotelDetails.name} />
              
              <div className="block h-40 md:h-full justify-between p-4 leading-normal w-80 sm:w-96 overflow-hidden">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{value.hotelDetails.name}</h5>
                <div className="mb-3 font-normal text-gray-700 dark:text-gray-400 overflow-hidden flex mt-12">
                  <div className=' flex items-center'>
                    {value.isVeg &&  <img src="/static/logos/veg.svg" className='h-5' alt="Veg"/>}
                    {!value.isVeg && <img src="/static/logos/nonVeg.svg" className='h-5' alt="Non-Veg"/>}
                    <b className='pl-3 font-bold dark:text-gray-200'>{value.name}</b> - ₹ {value.price}
                  </div>
                  
                </div>
              </div>

            </Link>  
            </motion.div>
          // )
          ) ) 
          }

        

          </div>
      <div className='grid lg:grid-cols-2 grid-cols-1 justify-items-center dark:bg-gray-800 mb-10'>

          {
          isSuccess &&  data?.data.hotel.map((value:any) =>(
            <motion.div 
            initial="initial"
            whileInView="animation" 
            variants={child} 
            key={value.id}
            >
            <Link to={`/Foods/${snakeCase(value.name)}/${value.id}`}  className=" relative md:h-48 max-h-full m-5 flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-neutral-100 dark:border-gray-700 dark:bg-gray-700 dark:hover:bg-zinc-800 ">
              <img className="aspect-square object-cover w-full h-60 md:h-auto rounded-t-lg md:w-48 md:rounded-none md:rounded-l-lg" 
              src={value.image} alt={value.name} />
              
              <div className="block h-40 md:h-full justify-between p-4 leading-normal w-80 sm:w-96 overflow-hidden">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{value.name}</h5>
                <div className="mb-3 font-normal text-gray-700 dark:text-gray-400 overflow-hidden flex mt-12">
                  <div className=' flex items-center'>
                    {value.description}
                  </div>
                  
                </div>
              </div>

            </Link>  
            </motion.div>
          // )
          ) ) 
          }
          </div>
    </>
  )
}

export default FoodSearchScreen