import { configureStore } from '@reduxjs/toolkit'
import { AlertSlicer } from '../Animations/Alerts/AlertSlicer'
import { FoodSlicer } from '../Screen/Users/Foods/FoodSlicer'
import { PopUpOrderSlicer } from '../Animations/PopUp/PopUpSlicer'
import { PopUpAccountSlicer } from '../Animations/PopUp/AccountPopUp/AccountSlicer'
import { UserInfoSlicer } from '../Data/UserInfoSlicer'
import { DeliveryBoyPopUpSlicer } from '../Animations/PopUp/DeliveryBoyPopUp/DeliveryBoyPopUpSlicer'
import { GlobalDataSlicer } from '../GlobalSlicer/GlobalSlicer'
import { InStoreOTPPopUpSlicer } from '../Animations/PopUp/InStorePopUp/InStoreOTPPopUpSlicer'

export const store = configureStore({
  reducer: {
    GlobalData :GlobalDataSlicer.reducer,
    Alert:    AlertSlicer.reducer,
    FoodList: FoodSlicer.reducer,
    PopUpOrder: PopUpOrderSlicer.reducer,
    PopUpAccount: PopUpAccountSlicer.reducer,
    UserInfo: UserInfoSlicer.reducer,
    DeliveryOTP : DeliveryBoyPopUpSlicer.reducer,
    InStoreOTPPopUp : InStoreOTPPopUpSlicer.reducer,

  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch