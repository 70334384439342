import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { useNavigate,useParams,useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export function GlassPopUpLarge({title,pop_url,body,close}:any) {

    const navigate = useNavigate();



    if (close){
        closePopUp()
    }
    function closePopUp( ) {
        navigate( window.location.pathname.replace( pop_url, '' ) )
    }
    return (
        <>



            {/* // <!-- Main modal --> */}
            <motion.div
                initial={{
                    y: 300,
                }}
                whileInView={"animation"}
                variants={{
                    animation: {
                        y: 0,

                    }
                }}
                transition={{
                    duration: 0.5
                }} className=" fixed py-3 md:py-28 z-40  sm:z-50 w-full md:inset-0 h-modal md:h-full justify-center items-start flex shadow-3xl" role="dialog">
                <form className="relative px-4 pt-4 w-full max-w-sm h-full md:h-auto ">
                    {/* <!-- Modal content --> */}
                    <div className="relative dark:bg-blur-dark bg-blur-light rounded-lg   shadow ">



                        {/*   Close Button */}
                        <button type="button" onClick={closePopUp} className="absolute top-3 right-2.5 text-gray-700 dark:text-gray-200 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="py-6 px-6 lg:px-8">
                            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white text-center"> {title} </h3>
                            <div className="space-y-6 mild-border rounded-lg p-3">
                                {/* Inner Box */}

                            {
                                body
                            }
                            </div>
                        </div>
                    </div>
                </form>
            </motion.div>
        </>

    )
}

export default GlassPopUpLarge





