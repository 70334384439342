
// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
// Firebase
    
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import JSON_Config from "../../Constants/Axios/JSON_Config";
import { usePutReq } from "../../CustomHooks/putReq";
import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { Alert } from "../../Animations/Alerts/AlertSlicer";

function FireBase_Notification() {

  const dispatch = useDispatch()

  const { mutate:PutUpdateFB_token } = usePutReq()//Success Fn
  useEffect(() => {





  let VAPID_KEY = "BBkQxn3VJLoq6GJcX7r5PYllVdfp4mY3B2fawH3OxzYhaRJLJT6-k6c8J9UTkclXEnfxNptnMOD4pPWJ1xFrrvc"
    // https://firebase.google.com/docs/web/setup#available-libraries
    
    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyB6e7fXDHifOGZ-jrt4XxUQPnLosBLGbEc",
      authDomain: "joy-wayne.firebaseapp.com",
      projectId: "joy-wayne",
      storageBucket: "joy-wayne.appspot.com",
      messagingSenderId: "40154737127",
      appId: "1:40154737127:web:c37c635bdc67322c86f542"
    };
    
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    
    
    const messaging = getMessaging(app);


    onMessageListener().then((payload: any) => {
      console.log(payload);
    })



    
    setTimeout(function () {
        //! Issue in Local IP address I guess only works on DNS levels
        // Check in production
        getToken(messaging, { vapidKey: VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
                // Send the token to your server and update the UI if necessary
                // ...
                const config = JSON_Config
                const body = JSON.stringify
                ({
                  "token":currentToken,
                  "isWeb":true
                })
            
                const url = '/api/firebase/update/token/'
                  PutUpdateFB_token({url,body,config})
              } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
        
        
    }, 20000);//20 sec
    


  async function onMessageListener() {
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      if (payload.notification != undefined){
        dispatch(Alert({"status":"info","heading":payload?.notification?.title,"message":payload?.notification?.body}))
      }
      resolve(payload);
    });
  });}
    return () => {
    }
  }, [ ])
  


  return (
    <></>
  )
}

export default FireBase_Notification
