import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export interface CounterState {
    orderHotelID:string,
    deliveryBoyOrderIDforOTP:number// !! Same for undelivered reason Order ID
    currentOrderID:number// !! This can be global 
    isDisplayRazorPayPopUp:boolean
    isAndroidRedirectPopup:boolean

    placeOrderResponse:any
    requestedPickupTime:string
    searchFoodQuery:string


}

const initialState: CounterState = {
    orderHotelID: '',
    deliveryBoyOrderIDforOTP: 0,
    currentOrderID:0,
    isDisplayRazorPayPopUp:false,
    isAndroidRedirectPopup:false,
    placeOrderResponse:{},
    requestedPickupTime:"",
    searchFoodQuery:""

}

export const GlobalDataSlicer = createSlice({
  name: 'Global Data',
  initialState,
  reducers: {
    setGlobalData(state, action: PayloadAction<{}>) {
        Object.assign(state,action.payload)
    },
}})

// Action creators are generated for each case reducer function
export const { setGlobalData } = GlobalDataSlicer.actions

export default GlobalDataSlicer.reducer