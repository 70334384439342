import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { Alert } from '../Animations/Alerts/AlertSlicer' 
import { isObject } from '../Functionalities/smallFns'
import { axiosErrorHandler } from './axiosErrorHandler'


    const AxiosFn = ({body,config,url}:{body:any,config:any,url:any}) => {
        return axios.delete(url,
            {
                headers: config,
                data: body
            }
                )
    }

  export const useDeleteReq = (successCallBackFn:any=()=>{} ,errorCallBackFn:any=()=>{}  ,isSuccessAlert:boolean=true) => {
    const dispatch = useDispatch()

    return useMutation(AxiosFn, {
        onError: (error, data ) => {
            axiosErrorHandler({dispatch,data,error,errorCallBackFn})
            return
        }, 
        onSuccess: (data) => {
            if(isSuccessAlert &&  typeof data.data.message === 'string'){
                dispatch(Alert({"status":"success","heading":data.data.heading?data.data.message:"Success","message":data.data.message}))
            }
            successCallBackFn(data.data)

        },
      })
  }

