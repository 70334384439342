import React, { useState } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import HotelOwnersHistoryCardList from './HotelOwnersHistoryCardList'
import Error404 from '../../../Components/Error404'
import Loading from '../../../Animations/Loading'
import { RootState } from '../../../App/store'
import { useSelector } from 'react-redux'
import DummyScreen from '../../../Components/DummyScreen'
import JSON_to_Foodlist from '../../../Functionalities/Data_To_TSX/JSON_to_Foodlist'
import { useParams } from 'react-router-dom'
import HotelOwnerHeader from '../HotelOwnerHeader'
import JSON_Config from '../../../Constants/Axios/JSON_Config'
import { RQ_Constants } from '../../../Constants/Axios/ReactQueryRefetch'


function HotelOwnersHistory() {
  const { hotelID } = useParams();

  const { isLoading, isError,isSuccess, data, error } = useQuery(['fetchHotelOrderHistory-'+hotelID], () => {
    return (axios.get(`/api/getHotelOrdersHistory/${hotelID}/`,JSON_Config))
  },RQ_Constants.one_minute)
  var errorMessage;
    if (error instanceof Error) {
      errorMessage = error.message;
    }
  // let processedFoodList: string ;
  
  
  // if (isSuccess) {
  //   processedFoodList = JSON_to_Foodlist(data.data)
  // }
  const isAuthenticated = useSelector((state:RootState)=>state.UserInfo.isAuthenticated)


  return (
    <>
                        <HotelOwnerHeader  dashboard />

    {isError && isAuthenticated&& <Error404 message={errorMessage} /> }
    {isLoading && isAuthenticated && <Loading />  }
    {!isAuthenticated && <DummyScreen />}
      
    

    <div className='dark:bg-gray-800 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-y-10 py-10 px-5 '>
    {
      isSuccess && data?.data?.map((value: any) =>(HistoryListJSX(value)
        
      ) ) 
    }

    </div>
    </>
    
  )
}
function HistoryListJSX(value:any)
{
  return (
          <HotelOwnersHistoryCardList key={value.id} id={value.id} userName={value.userName} processedFoodList={value.processedFoodList}
    orderedTime={value.orderedTime} cookedTime={value.cookedTime} userPickedUpTime={value.userPickedUpTime}
    requestedPickupTime={value.requestedPickupTime} deliveredTime={value.deliveredTime}
    hotelOwnersProfit={value.hotelOwnersProfit} status={value.status} deliveryBoyName={value.deliveryBoyName}
    address={value.address} deliveryBoypickedUpTime={value.deliveryBoypickedUpTime} 
    unDeliveredReason={value.unDeliveredReason} isDelivery={value.isDelivery} isPickUp={value.isPickUp}
    address_Line_1 ={value.address_Line_1} address_Line_2={value.address_Line_2}
          pincode={value.pincode} landmark={value.landmark} latitude={value.latitude} longitude={value.longitude}
      />
          )

}
export default HotelOwnersHistory


