import axios from "axios";

export default function plainAxios(url: string) {
  return axios.get(url, {transformRequest: (data, headers) => {
    // @ts-ignore
    delete headers.common['Authorization'];
    // @ts-ignore
    delete headers.common['X-CSRFToken'];

    return data;
  }
});

}
