import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
    id:number,
    username:string,
    address:string,
    phoneNumber:number,
    altPhoneNumber:number,
    userImg:string,
    firstName:string,
    lastName:string,
    email:string,
    accessToken:string,
    refreshToken:string,
    isAuthenticated:boolean
    date_joined:string,
    isNormalUser: boolean,
    isHotelOwner:boolean,
    isDeliveryBoy: boolean,
    is_superuser:boolean,
    is_staff:boolean,
    startUserLogout:boolean,
    
}

const initialState: CounterState = {
    username: '',
    address: '',
    phoneNumber: 0,
    altPhoneNumber: 0,
    userImg: 'https://images.unsplash.com/photo-1523779917675-b6ed3a42a561?ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8d29tYW4lMjBibHVlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=face&w=500&q=200',
    firstName: '',
    lastName: '',
    email: '',
    id: 0,
    date_joined: '',
    isNormalUser: true,
    isHotelOwner: false,
    isDeliveryBoy: false,

    // +"" to typecase to string to avoid TypeScript error
    accessToken: localStorage.getItem( 'accessToken' ) ? localStorage.getItem( 'accessToken' ) + "" : "",
    refreshToken: localStorage.getItem( 'refreshToken' ) ? localStorage.getItem( 'refreshToken' ) + "" : "",
    isAuthenticated: false,
    startUserLogout: false,
    is_superuser: false,
    is_staff: false
}

export const UserInfoSlicer = createSlice({
  name: 'UserInfo',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<{}>) {
        Object.assign(state,action.payload)
    },
}})

// Action creators are generated for each case reducer function
export const { setUserInfo } = UserInfoSlicer.actions

export default UserInfoSlicer.reducer