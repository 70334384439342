import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import HistoryList from './HistoryCardList'
import Error404 from '../../../Components/Error404'
import Loading from '../../../Animations/Loading'
import { RootState } from '../../../App/store'
import { useSelector } from 'react-redux'
import DummyScreen from '../../../Components/DummyScreen'
import JSON_Config from '../../../Constants/Axios/JSON_Config'
import NewOrderAlert from './NewOrderAlert'



function History() {
  const { isLoading, isError,isSuccess, data, error } = useQuery(['fetchHistory'], () => {
    return (axios.get('/api/getOrderHistory/',JSON_Config))
  })
  var errorMessage;
    if (error instanceof Error) {
      errorMessage = error.message;
    }
  const isAuthenticated = useSelector((state:RootState)=>state.UserInfo.isAuthenticated)


  return (
    <>
    {isError && isAuthenticated&& <Error404 message={errorMessage} /> }
    {isLoading && isAuthenticated && <Loading />  }
    {!isAuthenticated && <DummyScreen />}
      
    

    <div className='dark:bg-gray-800 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-y-10 py-10 px-5 '>
    {
      isSuccess && data?.data?.map((value: any) =>(HistoryListJSX(value)
      ) ) 
    }
    {
      isSuccess && <NewOrderAlert />//To avoid multiple Alert
    }
    </div>
    </>
    
  )
}


function HistoryListJSX(value: any)
{
  return (
          <HistoryList hotelName ={value.hotelName[0].name}  orderedTime ={value.orderedTime} id ={value.id}
          requestedPickupTime ={value.requestedPickupTime} deliveredTime={value.deliveredTime} otp={value.otp}
          status={value.status} image={value.hotelImage[0].image} processedFoodList={value.processedFoodList}
          finalTotal={value.finalTotal } actualTotal={value.actualTotal} userPickedUpTime={value.userPickedUpTime} invoice={value.invoice}  />
          
          )

}
export default History


