import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../App/store';
import { timeFormatterDate } from '../Functionalities/smallFns';

function Profile() {
    // Make it Dynamic Later
  const userInfo = useSelector((state:RootState)=>state.UserInfo)

  return (
    <section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto flex flex-col">
    <div className="lg:w-4/6 mx-auto">
      <div className="rounded-lg h-64 overflow-hidden">
        <img alt="content" className="object-cover object-center h-full w-full" src="static/ProfleBG.jpg" />
      </div>
      <div className="flex flex-col sm:flex-row mt-10">
        <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
          <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
          <img className ="w-10 h-10" src={userInfo.userImg} alt={userInfo.username} />

          </div>
          <div className="flex flex-col items-center text-center justify-center">
            <h2 className="font-medium title-font mt-4 text-lg text-gray-500 dark:text-gray-300">{userInfo.username}</h2>
            <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
            <p className="text-base text-gray-500 dark:text-gray-200;">Our favourite customer <br />Since... {timeFormatterDate(userInfo.date_joined) }</p>
          </div>
        </div>
        <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
          
    <section className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
        <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Account settings</h2>
        
        <form>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                <div>
                    <label className="text-gray-700 dark:text-gray-200" htmlFor="username">Username</label>
                    <input value={userInfo.username} id="username" type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" disabled/>
                </div>

                <div>
                    <label className="text-gray-700 dark:text-gray-200" htmlFor="emailAddress">Phone Number</label>
                    <input value={userInfo.phoneNumber?userInfo.phoneNumber :""}  id="phoneNumber" type="tel" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" disabled/>
                </div>
                
                <div>
                    <label className="text-gray-700 dark:text-gray-200" htmlFor="phoneNo">Alternate Phone Number</label>
                    <input  id="altPhoneNumber" type="tel" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"  
                      value={userInfo.altPhoneNumber?userInfo.altPhoneNumber :""}
                    //   onChange={(e) => userInfo((prevState: any) => ({
                    //     ...prevState,
                    //     phoneNo: e.target.value
                    //  }))}
                    />
                </div>
                
            </div>

            <div className="flex justify-end mt-6">
            <button name='save'   type="submit" className=" w-20      items bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 text-white"
            >Save</button>
            </div>
        </form>
    </section>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}

export default Profile