// import process from "process";

import { log } from "console";
import Cookies from "js-cookie";
import { foodCategoriesSuggestion } from "../Constants/globalConstants";

export function currentURLPath() {
    return window.location.pathname
}
export const  isFirefox = navigator.userAgent.includes("Firefox");

export function timeFormatter12HR( value: any ) {
    if ( value == undefined ) {
        return "-"
    }
    let formattedDate = new Date( value ).toLocaleString( 'en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    } );
    return formattedDate
}

export function timeFormatterDate( value: any ) {
    let formattedDate = new Date( value ).toLocaleDateString( 'en-GB' );
    return formattedDate
}



export default function isDevMode(): boolean {
    // const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    // return development;
    if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ) {
        return true
    } else {
        return false
    }
}

export function isObject( val: any ) {
    if ( typeof val === 'object' &&
        !Array.isArray( val ) &&
        val !== null ) {

        return ( true )

    } else {
        return false
    }

}

export function round_by_5( x: any ) {
    return Math.ceil( x / 5 ) * 5;
}

export function bool2str( val: boolean ) {
    return val ? "true" : "false"
}


export function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test( navigator.userAgent );
}

export function isAndroid() {
    // return ( navigator.userAgent.includes( "Android" ) )
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes("android");

}

export function checkFirstTimeVisit() {
    var visitedBefore = Cookies.get( "visitedBefore" );
    if ( visitedBefore === null || visitedBefore === "" || visitedBefore == undefined ) {
        Cookies.set( "visitedBefore", "true", { expires: 3 } ); // Set the cookie to expire in 3 day
        // User is visiting for the first time
        return true
    } else {
        Cookies.set( "visitedBefore", "true", { expires: 3 } ); // Set the cookie to expire in 3 day
        // User has visited before
        return false

    }
}



export function redirect_to_Android(url:string) {
    // Attempt to open the Android app
    if(!isFirefox){//Firefox do  NS binding aborted error
        window.location.href = url;
    }
}


export function timeFormatter12HR_TimeOnly(timeString: string) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const utcTime = new Date(0); // Initialize with the Unix epoch
    utcTime.setUTCHours(hours, minutes, seconds);
  
    const browserTimeZoneTime = new Date(utcTime);
    const formattedTime = browserTimeZoneTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return formattedTime;
  }

  export function timeFormatter24HR_TimeOnly(timeString: string) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const utcTime = new Date(0); // Initialize with the Unix epoch
    utcTime.setUTCHours(hours, minutes, seconds);
  
    const browserTimeZoneTime = new Date(utcTime);
    const formattedTime = browserTimeZoneTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
    return formattedTime;
  }
  
  export function timeFormatter24HR_for_api(timeString: string) {
    const browserTimeZoneTime = new Date(timeString); // Parse the input time string
  
    // Get the UTC time by subtracting the browser's timezone offset
    const utcTime = new Date(browserTimeZoneTime.getTime() + browserTimeZoneTime.getTimezoneOffset() * 60000);
  
    // Format the UTC time as a string
    const formattedTime = utcTime.toUTCString();
    return formattedTime;
  }
  

  export function cleanPhoneNumber(input: string) {
    if (input.length < 10) {
        return input;
    }

    // Use a regular expression to match and clean the phone number
    const cleanedNumber = input.replace(/[^0-9]/g, '');
  
    // Check if the number starts with "91" and remove it
    if (cleanedNumber.startsWith('91')) {
      return cleanedNumber.substring(2);
    }
  
    return cleanedNumber;
  }
  
// export function openAppOrRedirectPlayStore() {
//     var customURLScheme = 'kallardo://restaurants/anna/1';

//     function redirectToPlayStore() {
//       var playStoreURL = 'https://play.google.com/store/apps/details?id=com.kallardo.mobile';
//       window.location.href = playStoreURL;
//     }

//     window.location.href = customURLScheme;

//     // Check if the app was opened successfully
//     setTimeout(function () {
//       // If the user is still on the same page, the app wasn't opened.
//       if (document.hasFocus()) {
//         redirectToPlayStore();
//       }
//     }, 1000); // Adjust the timeout (in milliseconds) as needed
//   }


export function combineAPI_food_categories(data: any) 
{
    const apiCategories = data.data.map( ( item: any ) => item.category );
    const mergedCategories = [...foodCategoriesSuggestion, ...apiCategories];
    const uniqueCategories = Array.from( new Set( mergedCategories ) );
    uniqueCategories.push( 'Other' );//Optimizing here causes unknown error, so leave it
    return uniqueCategories;
}
