import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion'
import Error404 from '../../../../../Components/Error404';
import Loading from '../../../../../Animations/Loading';
import RoomIcon from '@mui/icons-material/Room';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { timeFormatter12HR_TimeOnly } from '../../../../../Functionalities/smallFns';
import HotelOwnerHeader from '../../../HotelOwnerHeader';
import Breaker from '../../../../../Components/SmallUI/Breaker';

function AboutHotel() {
    const { hotelID } = useParams();

    const today = new Date();
    // Hotels Details Query
    const { isLoading, isError, isSuccess, data, error } = useQuery( [`fetchHotelDetailsAboutHotel-${ hotelID }`], () => {
        return ( axios.get( "/api/getHotelsFullDetails/" + hotelID + "/" ) )
    },
        {
            refetchOnMount: true,
            refetchOnWindowFocus: true,
            staleTime: 300000,//5min
            cacheTime: 300000,
            refetchInterval: 300000,

        } )

        const { isLoading:_isLoading, isError:_isError,isSuccess:_isSuccess, data:dataPofitPerMonth, error:_error }= useQuery(["fetchHotelMonthProfit-"+hotelID], () => {
            const axiosParam = "/api/get_Hotel_Dashboard_per_month_profit/"
            
              return (axios.get(axiosParam,{params:{    hotelID:hotelID , "month":today.getMonth()+1// bec index starts from 0
              , "year":today.getFullYear()}   }))
              },
              {
                refetchOnMount:true,
                refetchOnWindowFocus: true,
                staleTime:300000,//5min
                cacheTime:300000,
                refetchInterval:300000,
                
              })
    return (
        <div>
            <div className="flex flex-col-reverse lg:flex-row justify-center align-middle   items-center ">
                <motion.div
                    initial={{
                        x: 200,
                        opacity: 0.2,

                    }}
                    whileInView={"animation"}
                    variants={{
                        animation: {
                            x: 0,
                            opacity: 1,

                        }
                    }}
                    transition={{
                        duration: 0.7
                    }}
                    className="h-5/6 flex justify-center lg:block  lg:w-1/2 object-contain w-full">
                    <img className=" rounded-lg pb-20 lg:pb-0" src={data?.data.image} />
                </motion.div>
                <section className="text-gray-700 dark:text-gray-300 dark:bg-gray-800 body-font">

<div className="container px-5 lg:py-24 mx-auto">
    {isError && <Error404 />}
    {isLoading && <Loading />}
    {isSuccess && (
        // <HotelDetails />
        <div className='flex justify-center align-middle text-center items-center'>
            <div className="container px-6 py-5 md:pb-16 mx-auto">
                <motion.div
                    initial={{
                        x: -50,
                        opacity: 0.2
                    }}
                    whileInView={"animation"}
                    variants={{
                        animation: {
                            x: 0,
                            opacity: 1
                        }
                    }}
                    transition={{
                        duration: 0.7
                    }}
                    className="text-4xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white  ">
                    {data.data.name}
                </motion.div>
                <div className="mt-2">
                    <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
                    <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
                    <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
                </div>
                {isSuccess &&
                    // Address
                    ( <>
                        <div className='mt-4'><RoomIcon /> {data.data.address_Line_1}, {data.data.address_Line_2}, {data.data.pincode}</div>
                        <div className='mt-4'><AccessTimeIcon /> {timeFormatter12HR_TimeOnly( data.data.autoOpenTime )} - {timeFormatter12HR_TimeOnly( data.data.autoCloseTime )}</div>
                        <HotelOwnerHeader editOnly dashboard manage/>

                    </> )

                }
            </div>

        </div>
    )}


</div>
</section>



</div>
<div className="text-white grid lg:grid-cols-4 grid-cols-1 pb-20 ">
          <p className="mb-4 lg:col-start-3 lg:col-span-2 text-center  lg:text-3xl font-semibold  text-gray-800 capitalize  dark:text-white  ">{today.toLocaleString('default', { month: 'long' })} month's Sales : <span className='text-orange-500 lg:text-4xl'>&nbsp; ₹ {Math.trunc(dataPofitPerMonth?.data.monthly_profit) }</span> </p>
          <p className="mb-4 lg:col-start-3 lg:col-span-2 text-center  lg:text-3xl font-semibold  text-gray-800 capitalize  dark:text-white  ">{today.toLocaleString('default', { month: 'long' })} Total Orders : <span className='text-orange-500 lg:text-4xl'>&nbsp; {Math.trunc(dataPofitPerMonth?.data.monthly_order) }</span> </p>
          
</div>
<Breaker className = "h-24"/>
        </div>
    )
}

export default AboutHotel