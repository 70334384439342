import React, { useState }  from 'react'
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../App/store'; 



import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';


import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { setOrderAddress } from '../../Screen/Users/Foods/FoodSlicer';
import { usePostReq } from '../../CustomHooks/postReq';
import JSON_Config from '../../Constants/Axios/JSON_Config';
import ValidateOrder from '../../Functionalities/Validate/ValidateOrder';
import { setGlobalData } from '../../GlobalSlicer/GlobalSlicer';
import { Alert } from '../../Animations/Alerts/AlertSlicer';
import { add,set,isBefore } from 'date-fns'





export  function CheckoutPage() {
  return (
   <>
   <div className="text-gray-600 body-font">
    <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-start ">
        <div className='w-full'>
        <form>
            
            <div className="mb-6">
                <label htmlFor="address_Line_1" className="block mb-2 font-medium text-gray-900 dark:text-white">Address line 1</label>
                <input type="text" name='address_Line_1' id="address_Line_1" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="House No, Building Name" required/>
            </div> 
            <div className="mb-6">
                <label htmlFor="address_Line_2" className="block mb-2 font-medium text-gray-900 dark:text-white">Address line 2</label>
                <input type="text" name='address_Line_2' id="address_Line_2" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Road Name, Area, Colony, Street" required/>
            </div>
            <div className="grid gap-6 mb-6 md:grid-cols-2 w-full ">
                <div>
                    <label htmlFor="pincode" className="block mb-2 font-medium text-gray-900 dark:text-white sm:mb-8">Pincode</label>
                    <input type="number" name='pincode' id="pincode" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="641114" required/>
                </div>
                <div>
                    <label htmlFor="altPhoneNumber" className="block mb-2 font-medium text-gray-900 dark:text-white">Alternate Phone Number <br /> (If user phone number is unreachable)</label>
                    <input type="number" name='altPhoneNumber' id="altPhoneNumber" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required/>
                </div>
            </div>
            <div className="mb-6">
                <label htmlFor="landmark" className="block mb-2 font-medium text-gray-900 dark:text-white">Landmark</label>
                <textarea id="landmark" name='landmark' rows={4} className="block p-2.5 mb-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Opposite to college" />
            </div>
            <div className="grid gap-6 mb-6 md:grid-cols-2 w-full">
                <div>
                    <label htmlFor="OfferCode" className="block mb-2 font-medium text-gray-900 dark:text-white">Offer Code <br />	&nbsp;</label>
                    <input type="text" name='OfferCode' id="OfferCode" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="MY FIRST ORDER" required/>
                </div>
            </div>
        </form>


        </div>
        <div className=" w-full md:w-1/2 flex  justify-center items-center align-middle ">
            <Cart/>
        </div>
    </div>
</div>
   </>
  )
}

export  function Cart() {
    const dispatch = useDispatch()

    const todayDate = new Date();

    const _35_Min_later = add(todayDate, {
        minutes: 35,
    })
    dispatch(setGlobalData({"reqTime":_35_Min_later}))
    dispatch(setGlobalData({isDisplayRazorPayPopUp:false}))
    dispatch(setGlobalData({placeOrderResponse:{}}))
    function togglePopUp(){
        // dispatch(TogglePopUpOrder())//TODO
    }
    const { mutate:PostPlaceOrder } = usePostReq(successPostPlaceOrder)//Success Fn

    function successPostPlaceOrder(data: any){
        if (isAddressChanged){
            dispatch(setGlobalData({isDisplayRazorPayPopUp:true}))
            dispatch(setGlobalData({placeOrderResponse:data}))
        }
        dispatch(setOrderAddress('Address')) // ! Important to make sure the Razory to call again 

        // navigate("/")
                        
    }

    const HotelID= useSelector((state:RootState)=>state.GlobalData.orderHotelID) ;

    // Hotels Details Query
    const { isLoading:_isLoading, isError:_isError,isSuccess:_isSuccess, data:_data, error:_error }= useQuery(["fetchHotelDetalsInCheckout"], () => {
        const axiosParam = "/api/getHotelsFullDetails/"+HotelID+"/"
        
          return (axios.get(axiosParam))
          },
          {
            refetchOnMount:true,
            refetchOnWindowFocus: true,
            staleTime:300000,//5min
            cacheTime:300000,
            refetchInterval:300000,
            
          })
        let isDineInHotel = false // Should be initially false
        if(_isSuccess){
            isDineInHotel =_data.data.isDineInHotel
        }
        const isAddressChanged= useSelector((state:RootState)=>state.FoodList.address!='Address')  ;
        const address= useSelector((state:RootState)=>state.FoodList.address) ;
        const forProcessedFoodList = useSelector((state:RootState)=>state.FoodList.FoodList)
        
          const handleSubmit = (event: { preventDefault: () => void }) => {
            event.preventDefault();
            if (!isAddressChanged && !isDineInHotel ){
                dispatch(Alert({"status":"info","heading":"Error","message":"Select delivery address"}))
                return
            }
            togglePopUp()
            const processedFoodList = ValidateOrder(forProcessedFoodList)
            const config = JSON_Config

            const body = JSON.stringify(
            {"address":address,
            "hotelID":parseInt(HotelID),
            "foodList":processedFoodList,
            "isDineInOrder":true,
        })

            const url = '/api/orders/place_order/'
            if (isAddressChanged || isDineInHotel){
                PostPlaceOrder({url,body,config})
            }
      
          }

    // @ts-ignore
    const FoodList = useSelector((state:RootState)=>state.FoodList.FoodListItemsFinal["FoodList"])
    // @ts-ignore
    const TotalPrice = useSelector((state:RootState)=>state.FoodList.FoodListItemsFinal["actualTotal"])

    
    const foodListCheck = !((FoodList == null) || (typeof(FoodList)==undefined))
    
      
  return (
    <>
    


{/* // <!-- Main modal --> */}
<motion.div
            initial={{
                y : 300,
            }}
            whileInView={"animation"}
            variants={{animation:{
                y:0,

            }}}
            transition={{
                duration : 0.5
            }} className="w-full md:inset-0 h-modal md:h-full justify-center items-center flex shadow-3xl"role="dialog">
    <div className="relative px-4 pt-4 w-full max-w-md h-full md:h-auto ">
        {/* <!-- Modal content --> */}
        <div className="relative bg-zinc-100 rounded-lg shadow dark:bg-gray-700">





            
            <div className="py-6 px-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white text-center">Your cart</h3>
                <form onSubmit={handleSubmit}className="space-y-6 mild-border rounded-lg p-3"> 
                {/* Inner Box */}

<div className="overflow-y-auto relative shadow-md sm:rounded-lg h-80 scrollbar-thin scrollbar-thumb-orange-500 scrollbar-track-gray-300 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
            <tr>
                <th scope="col" className="py-3 px-6">
                    Food X count
                </th>
                <th scope="col" className="py-3 px-4 text-right ">
                    Price
                </th>
            </tr>
        </thead>
        <tbody>
        

            {foodListCheck ?(
            // for (const [key, value] of Object.entries(FoodList)) {
            FoodList.map((value: { name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; count:  number ; foodPrice: number; })=>{
                return(
                    <tr className="bg-white border-b dark:bg-gray-900 dark:even:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {value.name} x {value.count}
                    </th>
                    <td className="py-4 text-right px-4">
                        {value.foodPrice * value.count}
                    </td>
                    </tr>
                )
                

            })):(<></>)}
            



        </tbody>
    </table>
</div>

                    <div className="flex justify-between items-start">
                        {
                        _isSuccess && isDineInHotel ?
                        <PickUpTimePicker />:<AddressPicker />
                            
                            
                        }

                        <div className="text-gray-700 dark:text-gray-50 flex w-32 justify-between items-baseline ">
                            <div className='text-xl text-center tracking-widest font-medium dark:text-blue-200 font-serif '> 
                            Total : 
                            </div>
                            <div className='text-xl text-center '>
                                {foodListCheck &&  TotalPrice}
                            </div>
                        </div>

                    </div>
                    <button type="submit" className="w-full text-white base-orange hover:bg-orange-600 focus:ring-4 focus:outline-none
                     focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Proceed & Pay</button>
                    {/* <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                        Order more? <a href="#" className="text-blue-700 hover:underline dark:text-blue-500">Go back</a>
                    </div> */}
                </form>
            </div>
        </div>
    </div>
</motion.div> 
</>

  )
}




function AddressPicker(){
    const [addressPop,setAddressPop] = useState(false)
    const address = useSelector((state:RootState)=>state.FoodList.address)
    const dispatch = useDispatch()
    
    let availableAddresses = [
        "JMR",
        "JVR",
        "BR",
        "HR",
        "AR",
    ]
    function toggleAddressPopUp(){
        setAddressPop(!addressPop)
    }
    function addressClicked(value:string){
        dispatch(setOrderAddress(value))
        toggleAddressPopUp()
    }
    return(
        <>
            <div className='relative'>
                <button onClick={()=>toggleAddressPopUp()} className="mr-3 mb-3 md:mb-0 w-32 flex justify-between text-white base-orange hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center items-center " type="button">{address} <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg></button>

                {/* <!-- Dropdown menu --> */}
                {
                    addressPop &&
                    <div id="dropdownTop" className=" absolute bottom-14 z-10 w-44 bg-gray-200 rounded divide-y divide-gray-100 shadow dark:bg-gray-600">
                    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownTopButton">
                    

                    {availableAddresses.map((value)=>{
                        return(
                            <li onClick={()=>addressClicked(value)}>
                                <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-white">{value}</span>
                            </li>
                        )
                                
                        })}
                    </ul>
                </div>
                    
                }
                
            </div>

        </>

    )
}



function PickUpTimePicker() {
    let _date = new Date()
    const dispatch =useDispatch()
    const currentPickupValue= useSelector((state:RootState)=>state.GlobalData.requestedPickupTime) ;
    
    const todayDate = new Date();
    

    const _30_Min_later = add(todayDate, {
        minutes: 30,
    })
    const _4_30_PM = set(todayDate, { hours: 16,minutes:30 })
    const _5_01_PM = set(todayDate, { hours: 17,minutes:1 })

    
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className='scale-75 -ml-5 w-7/12'>
        <MobileTimePicker
          label="Pickup Time"
          value={currentPickupValue}
          onChange={(newValue) => {
                dispatch(setGlobalData({"reqTime":newValue}))
            }
          }
          disabled={isBefore(_4_30_PM, todayDate)}//Before 30min
          minutesStep={5}
          minTime={dayjs(_30_Min_later)}
          maxTime={dayjs(_5_01_PM)}// Let always be +1 min .. So the minutes Step dont get affected
          renderInput={(params) => <TextField {...params} />}
        />
        </div>
      
    </LocalizationProvider>
  );
}

