import React from 'react'
import Error404 from '../../../../Components/Error404';
import Loading from '../../../../Animations/Loading';
import DeliveryHotelDashboardCard from './DeliveryOrdersDashboardCard';
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import HotelOwnerHeader from '../../HotelOwnerHeader'
import { RQ_Constants } from '../../../../Constants/Axios/ReactQueryRefetch';


export default function DeliveryHotelDashboard() {
  const { hotelID } = useParams();
  const { isLoading, isError,isSuccess, data, error } = useQuery([`fetchDeliveryHotelDashboardValue-${hotelID}`], () => {
    return (axios.get(`/api/getDeliveryHotelDashBoard/${hotelID}/`))
  },RQ_Constants.one_minute)
  // getHotelsFullDetails
  return (
    <>
          <HotelOwnerHeader  dashboard={true} />
{/* @ts-ignore */}
    {isError && <Error404 message={error.message} /> }
    {isLoading && <Loading />  }

      
    

    <div className='dark:bg-gray-800 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-y-10 py-10 px-5 '>
    {
      isSuccess && data?.data?.orders?.map((value: any) =>(DashBoardCardLists(value,hotelID)
      ) ) 
    }
    </div>
    </>
    
  )
}

function DashBoardCardLists(value:any,urlhotelID: any)
{
  return (
          <DeliveryHotelDashboardCard key={value.id} id={value.id} foodList ={value.processedFoodList} orderedTime ={value.orderedTime}
          requestedPickupTime ={value.requestedPickupTime} deliveredTime={value.deliveredTime} userName={value.userName}
          status={value.status} isAvailable={value.isAvailable} profit={value.hotelOwnersProfit} 
          address_Line_1={value.address_Line_1} address_Line_2={value.address_Line_2} pincode={value.pincode}
           latitude={value.latitude} longitude={value.longitude} deliveryBoy={value.deliveryBoy} deliveryBoyNumbe={value.deliveryBoyNumber} 
           isDelivery={value.isDelivery}  />
          )
}