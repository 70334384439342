import React from 'react'
import Error404 from '../../../../Components/Error404';
import Loading from '../../../../Animations/Loading';
import HotelDashBoardCardList from './HotelDashBoardCardList';
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import HotelOwnerHeader from '../../HotelOwnerHeader'
import { RQ_Constants } from '../../../../Constants/Axios/ReactQueryRefetch';

export default function HotelDashboard() {
  const { hotelID } = useParams();
  const { isLoading, isError,isSuccess, data, error } = useQuery(['fetchHotelDashboardValue-'+hotelID], () => {
    return (axios.get(`/api/getHotelDashBoard/${hotelID}/`))
  },RQ_Constants.one_minute)
  
  return (
    <>
          <HotelOwnerHeader  dashboard={true} />

    {isError && <Error404 message={error.message} /> }
    {isLoading && <Loading />  }

      
    

    <div className='dark:bg-gray-800 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-y-10 py-10 px-5 '>
    {
      isSuccess && data?.data?.orders.map((value) =>(DashBoardCardLists(value,hotelID)
      ) ) 
    }
    </div>
    </>
    
  )
}

function DashBoardCardLists(value,urlhotelID)
{
  return (
          <HotelDashBoardCardList key={value.id} id={value.id} hotel_QR_OTP={value.hotel_QR_OTP} processedFoodList ={value.processedFoodList} orderedTime ={value.orderedTime} img={value.oneFoodImg}
          requestedPickupTime ={value.requestedPickupTime} deliveredTime={value.deliveredTime} userName={value.userName}
          totalCost = {value.total} status={value.status}   isAvailable={value.isAvailable} urlhotelID ={urlhotelID}  profit={value.hotelOwnersProfit}
          address_Line_1 ={value.address_Line_1} address_Line_2={value.address_Line_2} pincode={value.pincode} isPickUp={value.isPickUp} isDelivery={value.isDelivery}
          latitude={value.latitude} longitude={value.longitude} isDineIn={value.isDineIn}/>
          )
}
