import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

function Component_1({props}:any) {
  return (
    <div {...props} className=" py-10 md:px-2 flex justify-center align-middle items-center">
        

        <div className="lg:flex">
        <motion.div
            initial={{
                x : -200,
                opacity:0.2,
            }}
            whileInView={"animation"}
            variants={{animation:{
                x:0,
                opacity:1,

            }}}
            transition={{
                duration : 0.7
            }}
            
            className="flex items-center justify-center  w-full px-6 py-8  lg:w-1/2" >
                <div className="max-w-xl">
                    <h1 className="text-4xl font-semibold text-gray-800 dark:text-white lg:text-5xl">Kallardo <span className="text-orange-500 inline-block ">Dine-In</span></h1>
                        
                    <p className="mt-2 text-md text-gray-600 dark:text-gray-200 lg:text-xl">
                    Pre-order new, dine in, and enjoy the simplicity! With Kallardo, plan your meals in advance for a stress-free dine-in experience. Unlock discounts up to <b>₹300</b> by applying our exclusive promo codes.
                        </p>

                    <div className="flex flex-col mt-6 space-y-3 lg:space-y-0 lg:flex-row ">
                        <Link to='Restaurants' className="inline-flex justify-center text-white  base-orange hover:bg-orange-600 border-0 py-2 px-6 focus:outline-none rounded-sm text-lg">Order Now</Link>

                        <Link to='About' className="inline-flex justify-center lg:mx-4 mx-0 text-gray-700 bg-gray-200 hover:bg-gray-300 border-0 py-2 px-6 focus:outline-none rounded-sm text-lg">About</Link>

                    </div>
                </div>
            </motion.div>
            
            <motion.div
             initial={{
                x:200,
                opacity:0.2,

            }}
            whileInView={"animation"}
            variants={{animation:{
                x:0,
                opacity:1,

            }}}
            transition={{
                duration : 0.7
            }}
             className="h-5/6 lg:w-1/2 object-contain w-full">
                <img className="px-4 " src="static/home/first_img.jpg" />
                {/* drop-shadow-2xl */}
            </motion.div>
        </div>
    </div>
  )
}

export default Component_1