import React from 'react'

import DisplayCard from '../../../Components/Card/DisplayCard';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import PersonIcon from '@mui/icons-material/Person';
import { timeFormatter12HR, timeFormatterDate } from '../../../Functionalities/smallFns';
import DeliveryDiningRoundedIcon from '@mui/icons-material/DeliveryDiningRounded';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { OrderStatus } from '../../../Constants/globalConstants';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

function HotelOwnersHistoryCardList( { id, userName, cookedTime, userPickedUpTime, processedFoodList,
  deliveryBoyName, orderedTime, requestedPickupTime, deliveredTime,
  hotelOwnersProfit, status, deliveryBoypickedUpTime, unDeliveredReason, isDelivery, isPickUp,
  latitude, longitude, address_Line_1, address_Line_2,
  pincode, landmark }: any ) {

  let _orderedTime = timeFormatter12HR( orderedTime )
  let _cookedTime = timeFormatter12HR( cookedTime )
  let _deliveryBoypickedUpTime = timeFormatter12HR( deliveryBoypickedUpTime )
  let _requestedPickupTime = timeFormatter12HR( requestedPickupTime )
  let _userPickedUpTime = userPickedUpTime ? timeFormatter12HR( userPickedUpTime ) : "-"
  let _deliveredTime = deliveredTime ? timeFormatter12HR( deliveredTime ) : "-"
  let date = timeFormatterDate( ( orderedTime ) )

  const list = {
    initial: {
      x: -50,
      opacity: 0,
    },
    animation: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.7
      },

    }
  }
  return (
    <DisplayCard
      Head={
        <>
          <div className="object-cover bg-blue-800 object-center w-full h-56 text-8xl tracking-widest text-center text-white dark:text-gray-100 flex justify-center items-center" >
            {( '' + id ).padStart( 2, '0' )}
          </div>
          <div className="flex items-center px-6 py-3 bg-neutral-900">
            <PersonIcon sx={{ color: "#fff" }} />

            <h3 className="mx-3 text-lg font-semibold text-white">{userName}</h3>
          </div>
        </>

      }
      BodyTop={
        <>
          <div className='right-0 absolute text-green-700 dark:text-green-100 pr-5 '>
            {date}
          </div>
          <div className=''>
            {
              status == OrderStatus.UNDELIVERED ? (
                <p className={"text-xl font-bold text-red-500  "}>{status}</p>

              ) : status == OrderStatus.DELIVERED || status == OrderStatus.IN_STORE_PICKED_UP ? (
                <p className={"text-xl font-bold text-green-500"}>{status}</p>
              ) : (
                <p className={"text-xl font-bold text-gray-800 dark:text-white"}>{status}</p>
              )
            }
            <p className="py-2 text-gray-700 dark:text-gray-400 whitespace-pre">{processedFoodList}</p>
          </div>
        </>
      }
      BodyLow={
        <div className='text-gray-700 dark:text-gray-200'>


        {
          isDelivery &&
          <div className="flex items-center mt-4 ">
            <DeliveryDiningRoundedIcon />
            <div>
              <p className="px-2 text-sm">
                <b>Delivery Boy :</b> {deliveryBoyName} <br />
              </p>
            </div>

          </div>
        }


          <div className="flex items-start mt-4 ">

            <AccessTimeRoundedIcon />
            <div className="px-2 text-sm flex flex-col w-5/6">
            <div className='flex justify-between'>
                    <b>Ordered &nbsp;&nbsp;:</b> {_orderedTime} <br />
                  </div>
                  <div className='flex justify-between'>
                    <b>Cooked &nbsp;&nbsp;:</b> {_cookedTime} <br />
                  </div>
              {isPickUp &&
                <>

                  <div className='flex justify-between'>
                    <b>User Picked :</b> {_userPickedUpTime} <br />
                  </div>
                  <div className='flex justify-between'>
                      <b>Requested Time :</b>  {_requestedPickupTime}
                    </div></>
              }
              {
                isDelivery &&
                <>

                  <div>
                    {/* Hard coded sorry */}
                    <div className='dark:text-white flex my-2 -ml-8 '> 

                      <HomeRoundedIcon />
                      <div className='mx-2 text-sm'>
                        {address_Line_1} <br />
                        {address_Line_2} <br />
                        {pincode} <br />
                        Landmark - {landmark}
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-between'>
                    <b>Delivery Boy Picked :</b> {_deliveryBoypickedUpTime} <br />
                  </div>

                  {unDeliveredReason ? (
                    <div className='flex justify-between'>
                      <b>Undelivered Reason :</b> {unDeliveredReason}
                    </div>
                  ) : (
                    <div className='flex justify-between'>
                      <b>Delivered :</b> {_deliveredTime}
                    </div>
                  )}

                </>
              }



            </div>
          </div>

          <div className="flex items-center mt-4 ">

            <PaidOutlinedIcon />
            <div className='flex justify-between text-xl px-2'>
              <b>Total : &nbsp; </b>  ₹ {hotelOwnersProfit}
            </div>
          </div>

        </div>

      }

    />


  )
}

export default HotelOwnersHistoryCardList