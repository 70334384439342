import React from 'react'
import JSON_Config from '../../Constants/Axios/JSON_Config'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import Error404 from '../Error404'
import Loading from '../../Animations/Loading'

export default function OfferPopUptables({setOfferCode,setShowOfferCode,applyOffer}:any ) {
  const { isLoading, isError,isSuccess, data, error } = useQuery(['fetchOffersAvailable'], () => {
    return (axios.get('/api/getOffers/',JSON_Config))
  },{
  refetchOnMount:true,
  refetchOnWindowFocus: true,
  retry: 2,

  })
  if(isError){
    console.log(error);
  }
  return (
            <div className="w-full  text-sm text-left text-gray-700 dark:text-gray-200">
                   
                <div  className='grid grid-flow-row gap-y-2 rounded-lg dark:border-white border'>

                {isError && <Error404   message={error} /> }
               {isLoading && <Loading />  }
                    {isSuccess &&
                        data.data.map( ( value: any ) => {

                            return (
                                <div key={value.id} className="grid border-b border-black  dark:bg-gray-600 dark:border-gray-500 rounded-lg">
                                  <div className=''>
                                    <div className="py-4 px-6 bg-gray-500 text-gray-100 dark:bg-gray-800 rounded-t-lg dark:bg-blur-dark bg-blur-light font-medium  whitespace-nowrap dark:text-white text-center">
                                          {value.code} 
                                      </div>
                                      
                                  </div>
                                    
                                    <div className="py-4 text-right px-4 flex  justify-between">
                                    <div className='text-base text-left pr-2'>
                                        {value.userDescription}
                                    </div>
                                            <button onClick={()=>{
                                                setOfferCode(value.code+"#")
                                                // applyOffer()//It  does  -1  index of offercode//So avoid
                                                setShowOfferCode(false)
                                              }} type="button" className="text-orange-500 text-xs hover:text-white border border-orange-500 hover:bg-orange-600 font-medium rounded-lg  px-5 py-2 text-center   dark:hover:text-white w-fit">Apply</button>
                                    </div>
                                </div>
                            )

                        } )}

                </div>
            </div>
  )
}
