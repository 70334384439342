// ThemeProvider.js

import React, { useState, useEffect } from 'react';
import { ThemeProvider as ThemeProviderMUI, createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';


export default function ThemeProvider({ children }) {
// To make  avoid Cache less error
  const [theme, setTheme] = useState((localStorage.getItem('theme')!=null)?localStorage.getItem('theme'):"light");

  const darkTheme = createTheme({
    palette: {
      mode: (theme)?(theme):"light",
      text:{
        
      },
      background: {
        paper:  (theme==='dark')?"#111827":"#d4d4d4",
      },
      
      secondary: {
        main: colors.blueGrey[200],
      },
    },
  });
  useEffect(() => {
    const localStorageTheme = localStorage.getItem('theme')
    if (localStorageTheme != null) {
      setTheme(localStorageTheme);
    } else {
      localStorage.setItem('theme', `light`);
      setTheme(`light`);
    }
  }, []);

  useEffect(() => {

    localStorage.setItem('theme', theme);
  }, [theme]);


  return (
    <ThemeProviderMUI theme={darkTheme}>
      <div id='theme-wrapper' className={`${theme} theme-changer `}>
        <div className="dark:bg-gray-800 ">
          {children}

        <button
        
      className='fixed p-2 m-2 bottom-2 right-2 bg-blur-dark dark:bg-blur-light rounded-full z-50'      
          onClick={() => {

            theme === `light` ? setTheme(`dark`) : setTheme(`light`)
          }}>
          <img className='h-8 ' src={` ${ theme==='dark' ? '/static/header/lightMode.png' : '/static/header/darkMode.png' }`} alt="DarkMode" />
        </button>
        </div>
        
      </div>
    </ThemeProviderMUI>

  )
}