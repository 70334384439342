import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
    visible: boolean,
    orderID:string
}

const initialState: CounterState = {
    visible:false,
    orderID:""
}

export const InStoreOTPPopUpSlicer = createSlice({
  name: 'InStoreOTPPopUp',
  initialState,
  reducers: {
    ToggleInStoreOTPPopUp: (state) => {
        state.visible = !state.visible
    },
   setInStoreOTPPopUpOrderID:(state, action: PayloadAction<string>) => {
      state.orderID = action.payload
   }
  },
})

// Action creators are generated for each case reducer function
export const { ToggleInStoreOTPPopUp,setInStoreOTPPopUpOrderID } = InStoreOTPPopUpSlicer.actions

export default InStoreOTPPopUpSlicer.reducer