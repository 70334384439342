import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Alert } from '../../../Animations/Alerts/AlertSlicer'

function NewOrderAlert() {
  const dispatch = useDispatch()

  const { isLoading, isError,isSuccess , data, error } = useQuery(['fetch_NewOrderAlert-User'], () => {
      return (axios.get('/api/getDB_notifications/OrderPlaced/'))
      })
    
      if (isSuccess){
        setTimeout(function () {
        let i=0;
        data.data.forEach((value: any) =>{
          i++
          setTimeout(() => {
            dispatch(Alert({"status":value.status,"heading":value.heading,"message":value.description}))
          }, i*400);
        })

      }, 1100);

      }
      
  return (
    <></>
  )
}

export default NewOrderAlert