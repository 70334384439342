import React from 'react'
import { motion } from 'framer-motion'
import { HomeOrderButton } from './Component_4'
function Component_5() {
  const parent = {
    initial: {
    },
    animation: {
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.4,
      },
    },
    variants:{},
    transition: {}, 
  }
  
  const procedure = {
    initial:{
        y : -10,
        opacity:0,     
    },
    animation:{
        y:0,
        opacity:1,
        transition:{
          duration : 0.3,
      }
    },    
  }
  const progressBar = {
    initial:{
        height : 0    
    },
    animation:{
      height : "100%" ,   

        transition:{
          duration : 0.4,
      }
    },    
  }
  return (
<motion.section 
variants={parent}
initial="initial"
whileInView="animation"
// This makes child also uses the same parmeters but can be edited by adding custom variants
 
  className="bg-neutral-200 text-gray-400 dark:bg-gray-900 body-font">
  <div className="container px-5 py-24 mx-auto flex flex-wrap">
    <div className="flex flex-wrap w-full flex-row justify-evenly ">
      <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
        <div className="flex relative pb-12">
          
          <motion.div 
            variants={procedure}
            

              className="flex-shrink-0 w-10 h-10 rounded-full base-orange inline-flex items-center justify-center text-white relative z-10">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            
          </motion.div>
          <div className="h-full w-10 absolute inset-0 flex items-start justify-center">
          <motion.div 
            variants={progressBar}
             className="w-1 bg-gray-900 pointer-events-none">
             </motion.div>
          </div>
          <div className="flex-grow pl-4">
            <h2 className="text-gray-800 dark:text-white font-medium title-font text-sm  mb-1 tracking-wider">STEP 1</h2>
            <p className="leading-relaxed dark:text-gray-300">Sign Up using required details</p>
          </div>
        </div>
        <div className="flex relative pb-12">
          
          <motion.div  
            variants={procedure}

             className="flex-shrink-0 w-10 h-10 rounded-full base-orange inline-flex items-center justify-center text-white relative z-10">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
          </motion.div>
          <div className="h-full w-10 absolute inset-0 flex items-start justify-center">
          <motion.div 
            variants={progressBar}
             className="w-1 bg-gray-900 pointer-events-none">
             </motion.div>
          </div>
          <div className="flex-grow pl-4">
            <h2 className="text-gray-800 dark:text-white font-medium title-font text-sm mb-1 tracking-wider">STEP 2</h2>
            <p className="leading-relaxed dark:text-gray-300">Chooose your favourite hotel from Kallardo</p>
          </div>
        </div>
        <div className="flex relative pb-12">
          
          <motion.div  
            variants={procedure}
            className="flex-shrink-0 w-10 h-10 rounded-full base-orange inline-flex items-center justify-center text-white relative z-10">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
              <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
            </svg>
          </motion.div>
          <div className="h-full w-10 absolute inset-0 flex items-start justify-center">
          <motion.div 
            variants={progressBar}
             className="w-1 bg-gray-900 pointer-events-none">
             </motion.div>
          </div>
          <div className="flex-grow pl-4">
            <h2 className=" text-gray-800 dark:text-white font-medium title-font text-sm mb-1 tracking-wider">STEP 3</h2>
            <p className="leading-relaxed dark:text-gray-300">Order your desired food from the given list</p>
          </div>
        </div>
        <div className="flex relative pb-12">
          
          <motion.div  
            variants={procedure}
            className="flex-shrink-0 w-10 h-10 rounded-full base-orange inline-flex items-center justify-center text-white relative z-10">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
            </svg>
          </motion.div>
          <div className="h-full w-10 absolute inset-0 flex items-start justify-center">
          <motion.div 
            variants={progressBar}
             className="w-1 bg-gray-900 pointer-events-none">
             </motion.div>
          </div>
          <div className="flex-grow pl-4">
            <h2 className="text-gray-800 dark:text-white font-medium title-font text-sm mb-1 tracking-wider">STEP 4</h2>
            <p className="leading-relaxed dark:text-gray-300">Pay for the order through secured gateway</p>
          </div>
        </div>
        <div className="flex relative">
        <motion.div  
            variants={procedure}
             className="flex-shrink-0 w-10 h-10 rounded-full base-orange inline-flex items-center justify-center text-white relative z-10">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
              <path d="M22 4L12 14.01l-3-3"></path>
            </svg>
          </motion.div >
          <div className="flex-grow pl-4">
            <h2 className="text-gray-800 dark:text-white font-medium title-font text-sm mb-1 tracking-wider">ENJOY</h2>
            <p className="leading-relaxed dark:text-gray-300">Enjoy your favourite food by collecting it up in restaurants</p>
          </div>
        </div>
        <div className='mt-12 w-full flex  justify-center'>
            <HomeOrderButton />
        </div>
      </div>
      <img className="lg:w-1/2 aspect-square md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12 drop-shadow-xl" 
      src="static/home/user_registration.jpg" alt="user_registration" />

        {/* <img className=" rounded-lg w-96" src="static/home/user_registration.jpg" alt="user_registration" /> */}
    </div>
  </div>
</motion.section>
    )
}

export default Component_5