// JWT and CSRF will be added in Initial Loader Axios
const JSON_Config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const FormData_Config={
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
export default JSON_Config
