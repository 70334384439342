import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { RootState } from '../../App/store'
import { TogglePopUpOrder } from '../../Animations/PopUp/PopUpSlicer'
import { usePostReq } from '../../CustomHooks/postReq'
import { FoodListItemsFinalUpdate } from '../../Screen/Users/Foods/FoodSlicer'
import JSON_Config from '../../Constants/Axios/JSON_Config'
import ValidateOrder from '../../Functionalities/Validate/ValidateOrder'
import { TogglePopUpAccount } from '../../Animations/PopUp/AccountPopUp/AccountSlicer'



export default function CheckOrderButton(hotelID:any) {
  const isAuthenticated = useSelector((state:RootState)=>state.UserInfo.isAuthenticated)
  
  
  const dispatch = useDispatch()
  function togglePopUp(){
    dispatch(TogglePopUpOrder())
}
  // dispatch()

  const FoodList = useSelector((state:RootState)=>state.FoodList.FoodList)

  let processedFoodList = ValidateOrder(FoodList)

  const { mutate:PostCheckOrder } = usePostReq(ifSuccessCheckOrder,()=>{})
  function ifSuccessCheckOrder(data: any){
    dispatch(FoodListItemsFinalUpdate(data))
  }


  const handleSubmit = (event: { preventDefault: () => void }) => {
    
    event.preventDefault();
    const body = JSON.stringify({"foodList":processedFoodList,
    "hotelID":parseInt(hotelID["hotelID"]),
    "isDineInOrder":true,
  })
    let url = '/api/orders/check_order/'
    const config = JSON_Config;
    PostCheckOrder({body,config,url})
  }
  function signInPopUp(event: { preventDefault: () => void }){
    event.preventDefault();
    dispatch(TogglePopUpAccount())
  }
  return (
    <form onSubmit={isAuthenticated?handleSubmit:signInPopUp} className='fixed h-20 w-screen bg-zinc-100 dark:bg-gray-800 z-20 bottom-0 flex justify-center items-center'>
      <button type="submit" onClick={()=>isAuthenticated?togglePopUp():null}  className="text-white text-lg bg-blue-600 hover:bg-blue-700 focus:ring-4
       focus:ring-blue-300 font-medium rounded-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-700
       dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-900">Place Order</button>
       {/* <Link to='#'>
      </Link>   */}
    </form>
  )
}

