import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
    visible: boolean
    // FoodList: { [id: number]: number}
}

const initialState: CounterState = {
    visible:false,
    // FoodList: { }
}

export const PopUpOrderSlicer = createSlice({
  name: 'PopUpOrder',
  initialState,
  reducers: {
    TogglePopUpOrder: (state) => {
        state.visible = !state.visible
    },
    // pop: (state, action: PayloadAction<object>) => {
    //     // state.totalAlert.filter((value) =>{

    //     }) 

    // },
  },
})

// Action creators are generated for each case reducer function
export const { TogglePopUpOrder } = PopUpOrderSlicer.actions

export default PopUpOrderSlicer.reducer