import * as React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';


import { useSelector } from 'react-redux';
import { RootState } from '../../App/store';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function CartCountIcon({n}:{n:number}) {
  const FoodList = useSelector((state:RootState)=>state.FoodList.FoodList)
  let processedFoodList = {}
  for (let [id, counts] of Object.entries(FoodList))
  {
    if(counts>0)
    {
      processedFoodList = {...processedFoodList,[id]:counts}
    }
  }
  let count=Object.keys(processedFoodList).length
  
  return (
    <IconButton aria-label="cart">
      <StyledBadge badgeContent={count} color="secondary">
        <ShoppingCartIcon />
      </StyledBadge>
    </IconButton>
  );
}
