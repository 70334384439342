import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';



import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';


import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { add } from 'date-fns'
import { setGlobalData } from '../../../GlobalSlicer/GlobalSlicer';
import { usePostReq } from '../../../CustomHooks/postReq';
import { Alert } from '../../Alerts/AlertSlicer';
import ValidateOrder from '../../../Functionalities/Validate/ValidateOrder';
import JSON_Config from '../../../Constants/Axios/JSON_Config';
import OrderedFoodTable from '../../../Components/Tables/CartPopUpTables';
import OfferPopUptables from '../../../Components/Tables/OfferPopUptables';
import { RootState } from '../../../App/store';
import { TogglePopUpOrder } from '../PopUpSlicer';
import { round_by_5 } from '../../../Functionalities/smallFns';

export default function CartPopUp() {
    const dispatch = useDispatch()
    const requestedPickupTimeString = useSelector( ( state: RootState  ) => state.GlobalData.requestedPickupTime )
    const [showOfferCode, setShowOfferCode] = useState( false )
    const todayDate = new Date();
    const _35_Min_later = add( todayDate, {
        minutes: 35,
    } )
    const [JSX_Initiated, setJSX_Initiated] = useState( true );

    const [offerPrice, setOfferPrice] = useState( 0 );
    const [isOfferPriceChanged, setIsOfferPriceChanged] = useState( false );

    // dispatch(setGlobalData({"reqTime":_35_Min_later.toUTCString()}))
    dispatch( setGlobalData( { isDisplayRazorPayPopUp: false } ) )
    dispatch( setGlobalData( { placeOrderResponse: {} } ) )

    function togglePopUp() {
        dispatch( TogglePopUpOrder() )
    }
    const { mutate: PostPlaceOrder } = usePostReq( successPostPlaceOrder )//Success Fn

    function successPostPlaceOrder(data: any){
        if (JSX_Initiated){
            dispatch(setGlobalData({isDisplayRazorPayPopUp:true}))
            dispatch(setGlobalData({placeOrderResponse:data}))
        }
        // dispatch(setOrderAddress('Address')) // ! Important to make sure the Razory to call again // Updated based on pick up time
        setJSX_Initiated(false)
    }
    const { mutate: PostCheckOffer } = usePostReq( ( data: any ) => {
        setOfferPrice( data.offerPrice )
        let alert_value =  Math.floor((TotalPrice - data.offerPrice)  * 100) / 100  
        dispatch(Alert({"status":"success","heading":"Congrats","message":`Hurray! offer appllied  worth ₹${alert_value}`}))
        setIsOfferPriceChanged( true )
    
      } )
    const HotelID = useSelector( ( state: RootState ) => state.GlobalData.orderHotelID );

    const FoodListforprocessedFoodList = useSelector( ( state: RootState ) => state.FoodList.FoodList )

    let processedFoodList = ValidateOrder( FoodListforprocessedFoodList )


    // Hotels Details Query
    const { isLoading: _isLoading, isError: _isError, isSuccess: _isSuccess, data: _data, error: _error } = useQuery( ["fetchHotelDetalsInCart"], () => {
        const axiosParam = "/api/getHotelsFullDetails/" + HotelID + "/"

        return ( axios.get( axiosParam ) )
    },
        {
            refetchOnMount: true,
            refetchOnWindowFocus: true,
            staleTime: 300000,//5min
            cacheTime: 300000,
            refetchInterval: 300000,

        } )
    let isDineInHotel = false // Should be initially false
    if ( _isSuccess ) {
        isDineInHotel = _data.data.isDineInHotel
    }
    // const isAddressChanged= useSelector((state:RootState)=>state.FoodList.address!='Address')  ;
    const address = useSelector( ( state: RootState ) => state.FoodList.address );
    const forProcessedFoodList = useSelector( ( state: RootState ) => state.FoodList.FoodList )
    const [offerCode, setOfferCode] = useState( "" );
    // const [pickUpTime, setPickUpTime] = useState(_35_Min_later.toISOString());
    console.log( requestedPickupTimeString );
    console.log( dayjs( new Date( requestedPickupTimeString ) ) );


    const body = JSON.stringify(
        {
            "hotelID": parseInt( HotelID ),
            "foodList": processedFoodList,
            "offerCode": offerCode.replace( "#", "" ),//NEEEDED in 2places
            "requestedPickupTime": dayjs( new Date( requestedPickupTimeString ) ),//.format("YYYY-MM-DDThh:mm:ss") // '25/01/2019'
            "address": address == "Address" ? null : address,
            "isDineInOrder": true,
            "isAndroid": false
        } )

    const handleSubmit = ( event: { preventDefault: () => void } ) => {
        event.preventDefault();
        if ( !JSX_Initiated && !isDineInHotel ) {
            dispatch( Alert( { "status": "info", "heading": "Error", "message": "Select delivery address" } ) )
            return
        }
        togglePopUp()
        const processedFoodList = ValidateOrder( forProcessedFoodList )
        const config = JSON_Config



        const url = '/api/orders/place_order/'
        if ( JSX_Initiated || isDineInHotel ) {

            PostPlaceOrder( { url, body, config } )
        }

    }

    // @ts-ignore
    const FoodList = useSelector( ( state: RootState ) => state.FoodList.FoodListItemsFinal["FoodList"] )
    console.log( FoodList );
    // @ts-ignore
    const TotalPrice = useSelector( ( state: RootState ) => state.FoodList.FoodListItemsFinal["actualTotal"] )


    const foodListCheck = !( ( FoodList == null ) || ( typeof ( FoodList ) == undefined ) ) && ( typeof ( FoodList ) == "object" )
    function applyOffer() {
        let url = '/api/orders/check_offer/'
        const config = JSON_Config;
        PostCheckOffer( { body, config, url } )
    }
    if (offerCode.includes("#")) {
        setOfferCode( offerCode.replace( "#", "" ) )//NEEEDED in 2places
        applyOffer()
    }
    return (
        <>



            {/* // <!-- Main modal --> */}
            <motion.div
                initial={{
                    y: 300,
                }}
                whileInView={"animation"}
                variants={{
                    animation: {
                        y: 0,

                    }
                }}
                transition={{
                    duration: 0.5
                }} className=" fixed overflow-auto scrollbar-thin  z-[100]  w-full h-full md:h-full justify-center items-start flex md:items-center shadow-3xl  pb-32 md:pb-0">
                {/* ! This is little differ from rest pop watchout */}
                <div className="relative  px-4 w-full max-w-md  md:h-auto sm:scale-100 scale-90 ">
                    {/* <!-- Modal content --> */}
                    <div className="relative bg-zinc-100 rounded-lg shadow dark:bg-gray-700">


                        <button type="button" onClick={() => togglePopUp()} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="py-6 px-6 lg:px-8">
                            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white text-center">{showOfferCode ? "Offers Available":"Your cart"} </h3>
                            <div className="space-y-6 mild-border rounded-lg p-3">
                                {/* Inner Box */}

                                <div className="overflow-y-auto relative shadow-md sm:rounded-lg h-80  scrollbar-thumb-orange-500 scrollbar-track-gray-300 ">

                                    {showOfferCode ?
                                        <OfferPopUptables setOfferCode={setOfferCode} setShowOfferCode={setShowOfferCode} applyOffer={applyOffer} /> :
                                        <OrderedFoodTable foodListCheck={foodListCheck} FoodList={FoodList} />
                                    }


                                </div>

                                <div className="relative flex justify-between items-start">
                                    <div>
                                        {
                                            _isSuccess && isDineInHotel && !showOfferCode ?
                                                <PickUpTimePicker closingTime={_data.data.autoCloseTime} /> : <></>


                                        }
                                        <div className='mt-3 flex items-center '>
                                            <input type="text" name="offerCode" id="offerCode" value={offerCode}
                                                onClick={() => { setShowOfferCode( true ) }}
                                                onChange={( e ) => { setOfferCode( e.target.value ) }}
                                                className="w-3/4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="OFFER CODE" />


                                            <div onClick={() => { applyOffer() }} className=' pl-3 base-orange-text '>Apply</div>
                                        </div>
                                    </div>

                                    {!showOfferCode && 
                                    <div className="text-gray-700 dark:text-gray-50 flex flex-col w-1/2 justify-between  ">
                                        <div className='flex justify-between w-full items-baseline'>
                                            <div className='text-xl text-center tracking-widest font-medium dark:text-oranger-200 font-serif '>
                                                Total :
                                            </div>
                                            {
                                                isOfferPriceChanged ? (
                                                    <div className='text-2xl line-through  '>
                                                        {foodListCheck && TotalPrice}
                                                    </div>
                                                ) : (
                                                    <div className='text-2xl'>
                                                        {foodListCheck && TotalPrice}
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <br />
                                        {
                                            isOfferPriceChanged ?
                                                (
                                                    <div className='absolute bottom-3 right-0 text-4xl text-center offerPrice dark:offerPrice-dark'>
                                                        {offerPrice}
                                                    </div>
                                                ) : null
                                        }

                                    </div>
}

                                </div>

                                <button type="button" onClick={handleSubmit} className="w-full text-white base-orange hover:bg-orange-600 focus:ring-4 focus:outline-none
                     focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Proceed & Pay</button>
                                {/* <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                        Order more? <a href="#" className="text-blue-700 hover:underline dark:text-blue-500">Go back</a>
                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>

    )
}





function PickUpTimePicker( { closingTime }: any ) {
    const dispatch = useDispatch()

    const todayDate = new Date();
    let _35_Min_later = add( todayDate, {
        minutes: 35,
    } )

    let _closingTime = add( new Date( closingTime ), {
        minutes: 31,//+1 to avoid error
    } )


    _35_Min_later.setMinutes( round_by_5( _35_Min_later.getMinutes() ) )

    const [pickUpTime, setPickUpTime] = useState<dayjs.Dayjs | null | Date>( _35_Min_later );
    dispatch( setGlobalData( { requestedPickupTime: pickUpTime?.toString() } ) )//For Inititializing for API !mandatory


    const _30_Min_later = add( todayDate, {
        minutes: 30,
    } )
    // const _5_01_PM = set(todayDate, { hours: 21,minutes:1 })


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='scale-75 -ml-5 '>
                <MobileTimePicker
                    label="Pickup Time"
                    value={pickUpTime}
                    onChange={( newValue ) => {
                        setPickUpTime( newValue )//For this JSX component
                        dispatch( setGlobalData( { requestedPickupTime: newValue?.toString() } ) )//For calling API
                        console.log( newValue?.toString() );

                    }
                    }
                    //   disabled={isBefore(_4_30_PM, todayDate)}//Before 30min
                    minutesStep={5}
                      minTime={dayjs(_30_Min_later)}
                    maxTime={dayjs( _closingTime )}// Let always be +1 min .. So the minutes Step dont get affected
                    renderInput={( params ) => <TextField {...params} />}
                />
            </div>

        </LocalizationProvider>
    );
}
