import React from 'react'
import { TogglePopUpAccount } from './AccountSlicer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../App/store';
import Login from './Login/Login';
import Error404 from '../../../Components/Error404';
import PhoneSignIn from './SignIn/PhoneSignIn';
import ResetPassword from './Login/ResetPassword';






export default function AccountPopUp() {

    const AccountPopUpMode = useSelector( ( state: RootState ) => state.PopUpAccount.mode )

    const dispatch = useDispatch()
    // let setCartPopUp = 
    function togglePopUp() {
        dispatch( TogglePopUpAccount() )
    }
    if ( AccountPopUpMode === 'login' ) {
        return ( <Login togglePopUp={togglePopUp} /> )
    }
    else if ( AccountPopUpMode === 'phoneSignIn' ) {
        return ( <PhoneSignIn togglePopUp={togglePopUp} /> )
    } else if ( AccountPopUpMode === "resetPassword" ) {
        return <ResetPassword togglePopUp={togglePopUp} />
    }

    return ( <Error404 /> )
}


