import React, { useState } from 'react'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import DailyPaymentTable from './DailyPaymentTable';



function AdminDashboard() {
    const [date, setDate] = useState<any>()
    // alert( date)
    const { isLoading, isError, isSuccess, data, error } = useQuery( [`fetchSalesValue-${ date }`], () => {
        return ( axios.get( `/api/get_hotel_per_day_profit/?date=${ date.format( 'YYYY-MM-DD' ) }` ) )
    } )
    // alert(data)
    return (
        <div className='w-full h-full flex justify-center py-8'>

            <div className='container'>

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <div className="flex items-center justify-between py-4 bg-white dark:bg-gray-800">
                        <div className='flex justify-between w-full'>


{/* Dont wrap in new JSX cause error dont know why */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Pick Date"
                                    value={date}
                                    onChange={( newValue ) => {
                                        setDate( newValue );
                                    }}
                                    renderInput={( params ) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>


                    {isSuccess && <DailyPaymentTable data={data.data} />}
                                   
                    {/* <!-- Edit user modal --> */}
                </div>


            </div>


        </div>
    )
}

export default AdminDashboard