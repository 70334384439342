import React from 'react'
import { Link } from "react-router-dom";

function NavLinks({name, url, MobNavhandleClick}:{name:string, url:string, MobNavhandleClick:any}) {
  return (
        <div >
          <Link to={url} onClick={()=>MobNavhandleClick}
          className="py-4 mx-2 md:mx-0 text-sm text-center md:px-5 md:w-20m min-w-full w-auto h-max flex justify-center flex-nowrap 
          font-medium text-gray-700 transition-colors duration-20020
           transform  md:mt-0 dark:text-gray-200 md:hover:bg-gray-300
          md:dark:hover:bg-gray-700 border-b-4 border-transparent 
          md:hover:text-orange-400 md:hover:border-orange-500">
            {name}</Link>
         
        </div>
  )
}

export default NavLinks

{/* <li>
    <a href="#page1" className="flex justify-center border-b-4 border-transparent hover:text-indigo-600 hover:border-indigo-600 py-4">Pilot Training</a>
  </li>
  <li>
    <a href="#page2" className="flex justify-center border-b-4 border-transparent hover:text-indigo-600 hover:border-indigo-600 py-4">Titan maintenance</a>
  </li> */}

  // <div>
  //         <Link to={props.url} className="px-2 py-1 mx-2 mt-2 text-sm 
  //         font-medium text-gray-700 transition-colors duration-200
  //          transform rounded-md md:mt-0 dark:text-gray-200 hover:bg-gray-300
  //         dark:hover:bg-gray-700 border-b-4 border-transparent hover:text-indigo-600 hover:border-indigo-600 py-4">{props.name}</Link>

  //       </div>