import React from 'react'
import { motion } from 'framer-motion';

function DisplayCard({Head,BodyTop,BodyLow}:{Head:any,BodyTop:any,BodyLow:any}) {

    const list = {
        initial:{
          x : -50,
          opacity:0,
      },
      animation:{
        x:0,
        opacity:1,
        transition:{
          duration : 0.7
      },
      
    }    
      }
  return (

    <motion.div variants={list}
    initial="initial"
    whileInView="animation"
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 1.02 }}
    className="max-w-sm mx-auto h-full bg-white rounded-lg shadow-lg dark:bg-gray-900 w-full relative flex flex-col justify-between">
    
    <div>
        {Head}
    </div>
    

    <div className="px-6 py-4 relative flex flex-col justify-between h-full ">
      <div>
        {BodyTop}
      </div>

        
      <div >
        {BodyLow}
      </div>
  </div>
        
    
        
</motion.div>
          
  )
}

export default DisplayCard