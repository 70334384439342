import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
    visible: boolean,
    mode:string
}

const initialState: CounterState = {
    visible:false,
    mode:'phoneSignIn'
}

export const PopUpAccountSlicer = createSlice({
  name: 'PopUpAccount',
  initialState,
  reducers: {
    SetTogglePopUpAccount: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload
    },
    TogglePopUpAccount: (state) => {
      state.visible = !state.visible
  },
    ModeSignIn: (state) => {
      state.mode = 'signIn'
    },
    ModeLogIn: (state) => {
      state.mode = 'login'
    },
    ModePhoneSignIn: (state) => {
      state.mode = 'phoneSignIn'
    },
    ModeResetPassword: (state) => {
      state.mode = 'resetPassword'
    }
    // pop: (state, action: PayloadAction<object>) => {
    //     // state.totalAlert.filter((value) =>{

    //     }) 

    // },
  },
})

// Action creators are generated for each case reducer function
export const { TogglePopUpAccount,ModeSignIn,ModeLogIn,ModePhoneSignIn,SetTogglePopUpAccount,ModeResetPassword } = PopUpAccountSlicer.actions

export default PopUpAccountSlicer.reducer