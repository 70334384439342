import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';import { ModeLogIn, ModePhoneSignIn, ModeSignIn, SetTogglePopUpAccount, TogglePopUpAccount } from '../AccountSlicer';
import { usePostReq } from '../../../../CustomHooks/postReq';
import JSON_Config from '../../../../Constants/Axios/JSON_Config';
import { usePutReq } from '../../../../CustomHooks/putReq';
import { Alert } from '../../../Alerts/AlertSlicer';
import RefreshWindow from '../../../../Functionalities/RefreshWindow';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { cleanPhoneNumber } from '../../../../Functionalities/smallFns';
function PhoneSignIn({togglePopUp}:{togglePopUp:any}) {
    const dispatch = useDispatch()
    const [is_OTP_sent, setIs_OTP_sent] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState("")
    function recaptchaValueonChange(value:any) {
        // console.log("Captcha value:", value);
        setRecaptchaValue(value)
      }
    const [stepper, setStepper] = useState(1)
    const [inputs, setInputs] = useState(
        { 
            // first_name: '',
            username: '',
            otp: '',
            phoneNumber:'',
            password:'',
            email:'',
            isAcceptedToTermsAndCondition:false
        });

    let theme = (localStorage.getItem('theme')!=null)?localStorage.getItem('theme'):"light"
        function ifErrorSendOTP(data: any) {
            dispatch(SetTogglePopUpAccount(false))
            dispatch(ModePhoneSignIn())
        }
        function ifSuccessVerifyOTP(data: any) {
            // dispatch(Alert({"status":"success","heading":"Success","message":"Account creation successful"})) // Migrated to  Notification DB!
            dispatch(SetTogglePopUpAccount(false))

            let accessToken = data.access
            let refreshToken = data.refresh
            localStorage.setItem("accessToken",accessToken)
            localStorage.setItem("refreshToken",refreshToken)
            RefreshWindow()

            // setTimeout(() => {
            //     RefreshWindow()
            // }, 3000);

            
        }
        // ! We left this normal instead of plainAxios() because we need to create unique post,put req
        //  In this following manner they will renewed in refresh
        delete axios.defaults.headers.common["Authorization"];

        const { mutate:PostSendOTP } = usePostReq(()=>{},ifErrorSendOTP)
        const { mutate:PutResendOTP } = usePutReq(()=>{
            dispatch(Alert({"status":"success","heading":"Success","message":"OTP has sent successfully"}))
        })

        const { mutate:PutVerifyOTP } = usePutReq(ifSuccessVerifyOTP,()=>{})

        
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let name: any,value: any;
        
        name = event.target.name;
        value = event.target.value;
        if ( name == 'phoneNumber' ) {
            value = cleanPhoneNumber(value)
        }
        if (event.target.type==="checkbox"){
            value = event.target.checked;
        }
        // No extra config needed bec all are kind of string only
        setInputs(values => ({...values, [name]: value}))
      }
    
    const handleSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        sendOTP()
    }
    function resendOTP(){
        let { username, phoneNumber,password,otp,email} = inputs
        const body = JSON.stringify({phoneNumber,password,email,recaptcha:recaptchaValue})
        let url = '/api/resend_SignIn_otp/'
        const config = JSON_Config;
        PutResendOTP({body,config,url})
    }
    // let setCartPopUp = 
    // function switchToSignIn(){
    //     dispatch(ModeSignIn())
    // }
    function sendOTP(){
        let { username, phoneNumber,password,otp,email} = inputs
            if (!is_OTP_sent)
            {
                if(recaptchaValue===""){
                    dispatch(Alert({"status":"info","heading":"Info","message":"Kindly verify Recaptcha"}))
                    return
                }
                const body = JSON.stringify({ username, phoneNumber,email,recaptcha:recaptchaValue})
                let url = '/api/send_SignIn_otp/'
                const config = JSON_Config;
                PostSendOTP({body,config,url})
                setIs_OTP_sent(true)
                setStepper(2)
                
            }else{
            // const body = JSON.stringify({ username, phoneNumber,password,OTP})
            // let url = '/api/send_otp/'

            //     mutateCreateUser({body,JSON_Config,url})
            //     dispatch(TogglePopUpAccount())
            // }

            
        }
    }
    function verifyOTP()
    {
        let { username, phoneNumber,password,otp,isAcceptedToTermsAndCondition} = inputs
        if(!isAcceptedToTermsAndCondition){
            dispatch(Alert({"status":"info","heading":"Info","message":"Please accept to our Terms and Conditions"}))
            return
        }

        const body = JSON.stringify({ username, phoneNumber,password,otp})
        let url = '/api/verify_SignIn_otp/'
        const config = JSON_Config;
        PutVerifyOTP({body,config,url})
        setIs_OTP_sent(true)

    }
    return (
        <>
        
    
    
    {/* // <!-- Main modal --> */}
    <motion.div
                initial={{
                    y : 300,
                }}
                whileInView={"animation"}
                variants={{animation:{
                    y:0,
    
                }}}
                transition={{
                    duration : 0.5
                }} className=" fixed overflow-auto scrollbar-thin  z-[100]  w-full h-full md:h-full justify-center items-start flex md:items-center shadow-3xl  pb-32 md:pb-0">
                <div className="relative  px-4 w-full max-w-sm  md:h-auto ">
            {/* <!-- Modal content --> */}
            <div className="relative  dark:bg-blur-dark bg-blur-light rounded-lg shadow ">
                {/* To Do  Add Redux for Close button */}
    
                {/* Add sometin for  onlclick */}
    
    
    
                
                <button type="button" onClick={()=>togglePopUp()} className="absolute top-3 right-2.5 text-gray-600 dark:text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
                <div className="py-6 px-6 lg:px-8">
                    <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white text-center">Create an account</h3>
                    <form onSubmit={handleSubmit} className="space-y-6 mild-border rounded-lg p-3"> 
                    {/* Inner Box */}



                    {
                        stepper==1 && 
                        <>
                            <div>
                                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
                                <input type="text" name="username" value={inputs.username} onChange={(e)=>handleChange(e)} id="usernameInput" pattern='^[a-zA-Z0-9 ]*$' title="User name must contain only A-Z a-z 0-9 and spaces" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"  required />
                            </div>
                            <div>
                                <label htmlFor="PhoneNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your Phone Number</label>
                                <input type="number" name="phoneNumber" value={inputs.phoneNumber} onChange={(e)=>handleChange(e)} id="phoneNumberInput" pattern='^[0-9]{9,10}*$' title="Please enter only numbers (0-9)" maxLength={10} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"   required />
                            </div>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Enter your email</label>
                                <input type="email" name="email" value={inputs.email} onChange={(e)=>handleChange(e)}  autoComplete="on"  id="user_email" title="Enter valid email id"   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                            </div>
                            <div className='flex justify-center'>
                                <ReCAPTCHA className='scale-[0.85]' theme={(theme=='light')?'light':'dark'} sitekey="6LcuXyskAAAAAMNFLsjcNUN5lvH-Y7JzmELCppGw" onChange={recaptchaValueonChange}/>
                            </div>
                        </>
                    }
                    {/* { !is_OTP_sent &&
                    <>

                        
                    </>

                    } */}

                        {
                                stepper==2 && 
                                <>
                                    <div>
                                        <label htmlFor="PhoneNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your Phone Number</label>
                                        <input type="tel" name="phoneNumber" disabled value={inputs.phoneNumber} onChange={(e)=>handleChange(e)} id="phoneNumberInput" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"  required />
                                    </div>
                                    <div>
                                        <label htmlFor="OTP" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Enter the OTP from SMS</label>
                                        <input type="number" name="otp" value={inputs.otp} onChange={(e)=>handleChange(e)} id="otp"   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                    {/* Name should be lowercase otp beacuse of models.py */}
                                    </div>
                                    <div>
                                        <label htmlFor="createpassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Enter new password</label>
                                        <input type="password" name="password" value={inputs.password} onChange={(e)=>handleChange(e)} pattern=".{8,}" autoComplete="on"  id="createPassword" title="Length of password must be minimum 8 characters"  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                    </div>
                                </>
                        }
                        {/* { is_OTP_sent &&
                        
                         } */}
                        


                        {
                            stepper==1 &&
                            <>
                            
                        <button type="submit" className="w-full text-white base-orange hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        > Send OTP </button>

                        <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                            Already have an account <span onClick={()=>{dispatch(ModeLogIn())}} className="base-orange-text cursor-pointer underline">Login</span>
                        </div>
                        </>
                        }
                        {
                                stepper==2 && 
                                <>
                        <div className="flex items-start mb-6">

                            <div className="flex items-center h-5">
                            <input id="remember" type="checkbox" name='isAcceptedToTermsAndCondition' checked={inputs.isAcceptedToTermsAndCondition}  onChange={(e)=>handleChange(e)}  className="w-4 h-4  rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required/>
                            </div>
                            <label htmlFor="remember" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the 
                            {/* 
                            !!!!!!! In change of terms and condition link change. Change in Footer also
                            */}
                            <a href="https://merchant.razorpay.com/policy/KbrkdSMjB4p08m/terms" className="text-blue-600 hover:underline dark:text-blue-500"> terms and conditions</a>.</label>
                        </div>
                            <button type="button" onClick={()=>{verifyOTP()}} className="w-full text-white base-orange hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                                Verify </button>
                            <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                                Not recieved OTP yet <span onClick={()=>{resendOTP()}} className="base-orange-text"> Resend OTP</span>
                            </div>
                            </>
                        }
                        
                        {/* { is_OTP_sent ?(
                            
                        ):
                        (
                        
                        )
                        } */}
                        
                        
    
                    </form>
                </div>
            </div>
        </div>
    </motion.div> 
    </>
    
      )
}

export default PhoneSignIn