import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import Loading from '../../Animations/Loading'
import Error404 from '../../Components/Error404'
import { currentURLPath } from '../../Functionalities/smallFns'

// ! Choose Hotel is Common for Dashboard and History


export default function ChooseHotel() {
  let semiURL="";

    if (currentURLPath() == "/hotel/manage/choose_hotel"){
      semiURL = "/hotel/home/dashboard"
    }
    if (currentURLPath() == "/hotel/history/choose_hotel"){
      semiURL = "/hotel/dashboard/history"
    }
    if (currentURLPath() == "/hotel/home/dashBoard/choose_hotel"){
      semiURL = "/hotel/dashBoard"
    }
    
    const { isLoading, isError,isSuccess , data, error } = useQuery(['fetchChooseHotels'], () => {
        return (axios.get('/api/getHotelChooseDashBoard/'))
        })
      const child = {
        initial:{
            x : -30,
            opacity:0,     
        },
        animation:{
            x:0,
            opacity:1,
            transition:{
              duration : 1,
          }
        },    
      }
    
    
    
      
    
      return (
        <motion.div 
         className='text-gray-700 dark:text-gray-300 body-font'>
          <motion.div
                initial={{
                    y : -50,
                    opacity:0
                }}
                whileInView={"animation"}
                variants={{animation:{
                    y:0,
                    opacity:1
                }}}
                transition={{
                    duration : 0.7
                }} className="text-center mb-6  py-10">
          <h1 className="sm:text-3xl text-2xl font-medium text-center title-font base-orange-text dark:base-orange-text mb-4 ">Hotels</h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto"> Choose your Favourite Hotel </p>
        </motion.div>
          {isError && <Error404 message={error.message} />}
          {isLoading && <Loading />  }
          {isSuccess &&
          <div className='grid lg:grid-cols-2 grid-cols-1 justify-items-center dark:bg-gray-800 mb-10'>
    
          
            {
              isSuccess &&  data?.data.map((value) =>(
                <motion.div 
                initial="initial"
                whileInView="animation" 
                variants={child} 
                key={value.id}
                >
                <Link to={`${semiURL}/${value.id}`} className=" relative md:h-48 max-h-full m-5 flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-neutral-100 dark:border-gray-700 dark:bg-gray-700 dark:hover:bg-zinc-800 pr-2">
                  <img className="aspect-square object-cover w-full h-60 md:h-auto rounded-t-lg md:w-48 md:rounded-none md:rounded-l-lg" 
                  src={`${value.image}`} alt={value.name} />
                  
                  <div className="block h-40 md:h-full justify-between p-4 leading-normal w-80 sm:w-96 overflow-hidden">
                    <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{value.name}</h2>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 overflow-hidden">{value.description}</p>
                  </div>
    
                </Link>  
                </motion.div>
              // )
              ) ) 
              }
            </div>}
            <hr className='border-gray-400' /> 
            
        </motion.div>
        
        
        
      )
    }
    
