import React from 'react'
import { motion } from 'framer-motion';

function DeliveryPolicy() {
  return (
    <>
      <div className='flex justify-center text-center items-center'>
        <div className='container px-4'>
        <motion.div
                initial={{
                    x:-50,
                    opacity:0.2
                }}
                whileInView={"animation"}
                variants={{animation:{
                    x:0,
                    opacity:1
                }}}
                transition={{
                    duration : 0.7
                }}
                    className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white flex py-8 ">
                      <div>
                          <h3> Delivery Policy</h3>
                          <div className="">
                            <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
                            <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
                            <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
                          </div>
                      </div>
            
                    </motion.div>
                    
        <div className='pb-8 dark:text-gray-200 text-gray-800 text-left md:text-xl'>
        We would like to inform you that Kallardo now offers the option for manual pick-up from our partner restaurants at the requested time. This means that you can personally collect your order at the designated restaurant at a time convenient for you.
        Please note that when placing an order for manual pick-up, the requested pick-up time should be scheduled at least 30 minutes after the time of order placement. Additionally, we kindly request that you collect your food within one hour of the requested pick-up time. This ensures that your order remains fresh and ready for your enjoyment.
        We would like to emphasize that Kallardo holds no liability for any unclaimed orders. It is the responsibility of the user to pick up their order within the specified time frame. Therefore, we strongly urge you to arrive promptly at the restaurant to ensure the best dining experience.
        Thank you for choosing Kallardo as your preferred food delivery service. We appreciate your continued support and hope you enjoy your meals.


  <br /><br />To ensure a seamless in-store pick-up experience, please follow these steps:
    <ol className="list-decimal pl-8">
      <li>Place your order on our website or mobile app.</li>
      <li>Once your order is confirmed, you will have an  <br />OTP in website or app in Orders section .</li>
      <li>Visit the restaurant at the scheduled pick-up time.</li>
      <li>Provide the OTP to the restaurant owner or staff.</li>
      <li>They will verify the OTP and hand over your order.</li>
    </ol>
    <br />




We strive to provide excellent customer service, but if you encounter any issues or require assistance with our services,
 please don't hesitate to contact our helpdesk. You can reach us via email at <a href="mailto:support@kallardo.com" className='dark:text-blue-300 text-blue-600'> support@kallardo.com</a> . Our team will be happy to assist you and address any concerns you may have.

           
        </div>   
        
      </div>
    </div>
    
    
    </>
  )
}

export default DeliveryPolicy