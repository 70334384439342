import React from 'react'
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import { motion } from 'framer-motion';
import Component_7 from './ScreenComponents/home/Component_7'

function About() {
  return (
    <div>
      
<div className="p-8 bg-white dark:bg-gray-800  shadow">
    <h1 className="text-center text-3xl font-bold base-orange-text dark:base-orange-text ">
        Kallardo Team
    </h1>
    <p className="text-center mb-12 text-xl font-normal text-gray-500 dark:text-gray-200">
        Company team will expand shortly
    </p>
    <div className="flex justify-center items-center col-start-2">
    {/* <div className="flex items-center flex-col md:flex-row justify evenly">  If User Increases*/}

    <motion.div
             initial={{
                y:50,
                opacity:0.2,

            }}
            whileInView={"animation"}
            variants={{animation:{
                y:0,
                opacity:1,

            }}}
            transition={{
                duration : 0.4
            }} className="p-4 hidden md:flex justify-center items-center text-2xl font-thin  text-white bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg h-96 w-72 animate-pulse scale-95">
        </motion.div>



        <motion.div
             initial={{
                y:50,
                opacity:0.2,

            }}
            whileInView={"animation"}
            variants={{animation:{
                y:0,
                opacity:1,

            }}}
            transition={{

            }} className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg">
            <div className="text-center mb-4 opacity-90 ">
                <a href="#" className="block relative">
                    <img alt="Joy Sam Raj" src="static/home/joy_sam_raj.jpg" className="bg-gray-300 mx-auto object-cover rounded-full h-40 w-40 "/>
                </a>
            </div>
            <div className="text-center">
                <p className="text-2xl text-gray-800 dark:text-white">
                    Joy Sam Raj
                </p>
                <p className="text-xl text-orange-400 font-light">
                    Founder
                </p>
                <p className="text-md text-gray-500 dark:text-gray-300 max-w-xs py-4 font-light">
                    Full Stack Developer, Cyber Security Analyst, Desktop Application Developer, <br /> SEO Analyst, Trainer
                </p>
            </div>
            <div className="pt-8 flex border-t border-gray-200 w-44 mx-auto text-gray-300 items-center justify-between">
                
                
                <a href="https://www.linkedin.com/in/joy-sam-raj/"><LinkedInIcon fontSize="large"/></a>
                <a href="tel:+91 08062177665"><CallIcon fontSize="large"/></a>
                <a href="https://github.com/JOY-SAM"><GitHubIcon fontSize="large"/></a>
                <a href="https://www.joysam.me/"><LanguageIcon fontSize="large"/></a>

                
            </div>
        </motion.div>


        <motion.div
             initial={{
                y:50,
                opacity:0.2,

            }}
            whileInView={"animation"}
            variants={{animation:{
                y:0,
                opacity:1,

            }}}
            transition={{
                duration : 0.4
              }} className="p-4 hidden md:flex justify-center items-center text-2xl font-thin  text-white bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg h-96 w-72 animate-pulse scale-95">
        </motion.div>

       

    
    </div>
    
</div>
{/* Section 2 */}
        {/* <section className="text-gray-400 dark:bg-gray-800 body-font">
            <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                <img className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" src="static/home/JOY_SAM_RAJ.jpg" alt="JOY SAM RAJ"  />
                <div className="text-center lg:w-2/3 w-full">
                <p className="title-font sm:text-4xl text-3xl mb-4 font-medium dark:text-white text-center">Hi I'm Joy Sam ,</p>
                <p className="leading-relaxed mb-8">Meggings kinfolk echo park stumptown DIY, kale chips beard jianbing tousled. Chambray dreamcatcher trust fund, kitsch vice godard disrupt ramps hexagon mustache umami snackwave tilde chillwave ugh. Pour-over meditation PBR&amp;B pickled ennui celiac mlkshk freegan photo booth af fingerstache pitchfork.</p>
                <div className="flex justify-center">
                    <a href='https://www.joysam.me' className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">My Portfolio</a>
                    <button className="ml-4 inline-flex text-white bg-gray-900 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">Contact</button>
                </div>
                </div>
            </div>
        </section> */}

<section className="dark:text-gray-400 dark:bg-gray[] body-font overflow-hidden">
  <div className="container px-5 py-24 mx-auto">

    <div className="-my-8 divide-y-2 divide-gray-800 dark:divide-gray-500">
     {/* <motion.div
             initial={{
                x:-100,
                opacity:0.2,

            }}
            whileInView={"animation"}
            variants={{animation:{
                x:0,
                opacity:1,

            }}}
            transition={{
                duration : 0.7
            }} className="py-8 flex flex-wrap md:flex-nowrap">
        <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span className="font-semibold title-font dark:text-white">CATEGORY</span>
          <span className="mt-1 dark:text-gray-500 text-sm">12 Jun 2019</span>
        </div>
        <div className="md:flex-grow">
          <h2 className="text-2xl font-medium dark:text-white title-font mb-2">Bitters hashtag waistcoat fashion axe chia unicorn</h2>
          <p className="leading-relaxed">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
          <a className="base-orange-text inline-flex items-center mt-4">Know More
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </motion.div>

      <motion.div
             initial={{
                x:100,
                opacity:0.2,

            }}
            whileInView={"animation"}
            variants={{animation:{
                x:0,
                opacity:1,

            }}}
            transition={{
                duration : 0.7
            }} className="py-8 flex flex-wrap md:flex-nowrap">
        <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span className="font-semibold title-font dark:text-white">CATEGORY</span>
          <span className="mt-1 dark:text-gray-500 text-sm">12 Jun 2019</span>
        </div>
        <div className="md:flex-grow">
          <h2 className="text-2xl font-medium dark:text-white title-font mb-2">Bitters hashtag waistcoat fashion axe chia unicorn</h2>
          <p className="leading-relaxed">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
          <a className="base-orange-text inline-flex items-center mt-4">Know More
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </motion.div>

     <motion.div
             initial={{
                x:-100,
                opacity:0.2,

            }}
            whileInView={"animation"}
            variants={{animation:{
                x:0,
                opacity:1,

            }}}
            transition={{
                duration : 0.7
            }} className="py-8 flex flex-wrap md:flex-nowrap">
        <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span className="font-semibold title-font dark:text-white">CATEGORY</span>
          <span className="mt-1 dark:text-gray-500 text-sm">12 Jun 2019</span>
        </div>
        <div className="md:flex-grow">
          <h2 className="text-2xl font-medium dark:text-white title-font mb-2">Bitters hashtag waistcoat fashion axe chia unicorn</h2>
          <p className="leading-relaxed">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
          <a className="base-orange-text inline-flex items-center mt-4">Know More
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div> 
      </motion.div>*/}

      {/* <Component_7 /> */}


      
    </div>

  </div>
</section>
    </div>
  )
}

export default About