import React, { useState } from 'react'
import { Link } from "react-router-dom";

import NavLinks from './NavLinks'

import CartCountIcon from './cartCount';

import {UserModel, LoggedInUserModel, UserProfile} from './UserModel'
import { useSelector } from 'react-redux';
import { RootState } from '../../App/store';

function Header() {
    const userInfo = useSelector((state:RootState)=>state.UserInfo)
    const [mobileNav, setMobileNav] = useState(false);
    const MobNavhandleClick = () => {
        setMobileNav((prev) => !prev);
    };
    const [dashBoard, setDashBoard] = useState(false);

    const DashhandleClick = () => {
        setDashBoard((prev) => !prev);
    };

    const DashhandleClickAway = () => {
        setDashBoard(false);
    };

  return (
      <>


    <nav className="fixed w-full z-50 dark:bg-blur-dark bg-blur-light shadow drop-shadow-md shadow-slate-800 border-b-2 dark:border-neutral-300">
        <div className="container pl-6 py-4 mx-auto ">
            <div className="md:flex md:items-center md:justify-between">
                <div className="flex items-center justify-between">
                    <div className="text-xl font-semibold text-gray-700 flex">
                        <img src="/static/logo192.png" alt="Kallardo Logo" className='w-8 h-8 mr-1' />
                        <Link className="text-2xl font-bold text-orange-500 transition-colors duration-200 transform lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300" to="/">Kallardo</Link>
                    </div>

                    {/* <!-- Mobile menu button --> */}
                    {/* Hamburger Menu */}
                    <div className='flex justify-center align-middle items-center'>
                    
                    <div className='md:hidden block'>
                        <UserProfile DashhandleClick={DashhandleClick} userInfo={userInfo} dashBoard={dashBoard} DashhandleClickAway={DashhandleClickAway}/>
                    </div>
                    
                        {/* <UserModel DashhandleClick={DashhandleClick} userInfo={userInfo} /> */}
                    
                    <div className="flex md:hidden p-1.5 pt-0 ml-2  rounded-md">
                        <button onClick={MobNavhandleClick} type="button" className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400" aria-label="toggle menu">
                            <svg viewBox="0 0 24 24" className="w-8 h-8 fill-current">
                                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
                            </svg>
                        </button>
                    </div>

                    </div>
                    
                </div>

                {/* <!-- Mobile Menu open: "block", Menu closed: "hidden" --> */}
                <div className={`" ${mobileNav ? '':'hidden'} flex-1 md:flex md:items-center md:justify-between mr-5  "`}>
                    <div onClick={()=>{setMobileNav(false)}} className="flex flex-col justify-evenly mx-4 md:flex-row md:row-auto md:items-center h-96 md:h-auto">
                    
                    
                    {/* For Development */}

                    {/* {true &&(
                        <>
                        <NavLinks MobNavhandleClick name='Restaurants'       url='/hotel/choose_hotel' /> 
                        <NavLinks MobNavhandleClick name='Delivery'     url='/deliveryboy/dashboard/' />
                        </>
                    
                    )}  */}
                    
                    

                    {userInfo.isNormalUser &&(
                        <>
                            <NavLinks MobNavhandleClick name='Home' url='/' /> 
                            <NavLinks MobNavhandleClick name='Restaurants' url='/Restaurants' />        
                            <NavLinks MobNavhandleClick name='Orders' url='/Orders' />        
                            <NavLinks MobNavhandleClick name='About' url='/About' />  
                        </>
                    
                    )}

                    {userInfo.isHotelOwner &&(
                        <>
                            <NavLinks MobNavhandleClick name='Manage'       url='/hotel/manage/choose_hotel' /> 
                            <NavLinks MobNavhandleClick name='Dashboard'       url='/hotel/home/dashBoard/choose_hotel' />       
                            <NavLinks MobNavhandleClick name='History'    url='/hotel/history/choose_hotel' />        
                            <NavLinks MobNavhandleClick name='About'        url='/About' />  
                        </>
                    
                    )}
                    
                    {(userInfo.is_superuser || userInfo.is_staff) &&(
                        <>
                            <NavLinks MobNavhandleClick name='Home'         url='/' />        
                            <NavLinks MobNavhandleClick name='Dashboard'       url='/admin_dashboard' /> 
                            <NavLinks MobNavhandleClick name='About'        url='/About' />  
                        </>
                    
                    )}
                    {userInfo.isDeliveryBoy &&(
                        <>
                            <NavLinks MobNavhandleClick name='Choose&nbsp;Orders'     url='/deliveryboy/choose/delivery' />
                            <NavLinks MobNavhandleClick name='My&nbsp;Orders'     url='/deliveryboy/dashboard' />
                            <NavLinks MobNavhandleClick name='History'    url='/deliveryBoy/history' />        
                        </>
                    
                    )}
                    </div>

                    <div className="flex items-center mt-4 md:mt-0">
                        <div className='hidden md:block mx-3'>
                            <CartCountIcon n={0}/>
                        </div>
                        

                        <div className='md:block hidden'>
                            <UserProfile DashhandleClick={DashhandleClick} userInfo={userInfo} dashBoard={dashBoard} DashhandleClickAway={DashhandleClickAway}/>
                        </div>
        

                    </div>
                </div>
            </div>
        </div>
    </nav>
    <DummyHeader/>

    </>




  )
}

function DummyHeader() {
    return (
      <div className='h-20'>
  
      </div>
    )
  }

export default Header