import React from 'react'

function DailyPaymentTable( { data }: any ) {
    
    return (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">

               
                <tr>
                    <th scope="col" className="p-4">
                        <div className="flex items-center">
                            <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                        </div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Restaurant
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                        Profit
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                        To be Paid
                    </th>
                    {/* <th scope="col" className="px-6 py-3">
                        Status
                    </th> */}
                    <th scope="col" className="px-6 py-3 text-center">
                        Pay
                    </th>
                </tr>
            </thead>

            {/* iterate ts based on data */}









            <tbody>
            {data.map( ( data: any ) => {

return (
    <tr key={data.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
    <td className="w-4 p-4">
        <div className="flex items-center">
            <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
        </div>
    </td>
    <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
        <img className="w-10 h-10 rounded-full" src={data.image} alt="Jese image" />
        <div className="pl-3">
            <div className="text-base font-semibold">{data.name}</div>
            <a className="font-normal text-gray-500" href={`tel:${data.phoneNumber}`}  >{data.phoneNumber}</a>
        </div>
    </th>
    
    <td className="px-6 py-4 font-bold text-2xl">
        ₹{data.company_profit?data.company_profit:0}
    </td>
    <td className="px-6 py-4 font-bold text-2xl">
        ₹{data.total_hotel_profit?data.total_hotel_profit:0}
    </td>
    
    {/* <td className="px-6 py-4">
        <div className="flex items-center">
            <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div> Online
        </div>
    </td> */}
    <td className="px-6 py-4 flex justify-center">
        {/* <!-- Modal toggle --> */}
        <div className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
            {/* <button type="button" className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 w-28">Pay Now</button> */}
            <button type="button" className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800  w-28">Paid</button>
        </div>
    </td>
</tr>
)

} )}

            </tbody>
        </table> )
}

export default DailyPaymentTable