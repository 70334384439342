import React, { useState } from 'react';


import { motion } from 'framer-motion';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AvTimerRoundedIcon from '@mui/icons-material/AvTimerRounded';
import { useDispatch } from 'react-redux';
import { ToggleInStoreOTPPopUp } from '../../../../Animations/PopUp/InStorePopUp/InStoreOTPPopUpSlicer';
import { setGlobalData } from '../../../../GlobalSlicer/GlobalSlicer';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { show_FoodPicked_QR } from '../HotelOrdersDashBoard/HotelDashBoardCardList';
import { useNavigate, useParams } from 'react-router-dom';
import PlaceIcon from '@mui/icons-material/Place';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import HomeRoundedIcon from '@mui/icons-material/DeliveryDining';
import { useQueryClient } from '@tanstack/react-query';
import { usePutReq } from '../../../../CustomHooks/putReq';
import JSON_Config from '../../../../Constants/Axios/JSON_Config';
import { PopConstants } from '../../../../Animations/PopUp/PopUpManagerConstants';
import { OrderStatus } from '../../../../Constants/globalConstants';
import { timeFormatter12HR, timeFormatterDate } from '../../../../Functionalities/smallFns';

export default function DeliveryHotelDashboardCard( { id, orderedTime, foodList,
  requestedPickupTime, deliveredTime, status, isAvailable, userName,cookedTime, profit, address_Line_1, address_Line_2
  , pincode, latitude, longitude, deliveryBoy, deliveryBoyNumber, isDelivery }: any ) {


  function StatusButton( { id, hotel_QR_OTP, isPickUp, isDelivery }: any ) {
    const queryClient = useQueryClient()
    const navigate = useNavigate();
    const { hotelID } = useParams();
    const [miniPop, setMiniPop] = useState( false )
    let _deliveredTime = deliveredTime ? timeFormatter12HR( deliveredTime ) : "-"
    

    function toggleMiniPop() {
      setMiniPop( !miniPop )
    }
    function reAssignDeliveryBoy() {
      if( OrderStatus.DELIVERED === status){
        alert('Order already delivered')
        return
      }
      toggleMiniPop()
      navigate( window.location.pathname + PopConstants.CHOOSE_DELIVERY_BOY + "&hotelID=" + hotelID + "&orderID=" + id )
    }



    return (
      <div className=' pt-4   text-lg mr-2 mb-3 flex flex-col w-full'>


        {miniPop &&
          <div  className='absolute bottom-8 right-4 flex flex-row justify-end '>
            <div className="z-10 w-44 mb-1    bg-gray-200 rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
              {
                isDelivery &&
                <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownTopButton">
                  <li onClick={reAssignDeliveryBoy}>
                    <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Reassign Delivery</span>
                  </li>
                </ul>
              }

            </div>
          </div>

        }


        <div className='flex flex-row justify-between w-full'>



          <a target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${ latitude },${ longitude }`}
            className="flex mb-3 md:mb-0 w-28 justify-between text-white bg-blue-700 hover:bg-blue-800  font-medium rounded-lg text-sm px-4 py-2.5 text-center items-center dark:bg-blue-600 dark:hover:bg-blue-700  " >
            <PlaceIcon sx={{ color: "#fff", fontSize: "medium" }} />
            <span>Location</span>
          </a>
          <button onClick={() => { toggleMiniPop() }} 
            className="mb-3 md:mb-0 w-28 flex justify-between text-white bg-blue-700 hover:bg-blue-800  font-medium rounded-lg text-sm px-4 py-2.5 text-center items-center dark:bg-blue-600 dark:hover:bg-blue-700 " type="button">
            Option <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </div>

      </div>

    )
  }

  let _orderedTime = timeFormatter12HR( orderedTime )
  let _cookedTime = timeFormatter12HR( cookedTime )
  let _requestedPickupTime = timeFormatter12HR( requestedPickupTime )
  let _deliveredTime = deliveredTime ? timeFormatter12HR( deliveredTime ) : "-"
  let date = timeFormatterDate( ( orderedTime ) )


  const list = {
    initial: {
      x: -50,
      opacity: 0,
    },
    animation: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.7
      },

    }
  }


  return (

    <motion.div variants={list}
      initial="initial"
      whileInView="animation"
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 1.02 }}
      className="max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-900 w-full">
      <div className="object-cover bg-blue-800 object-center w-full h-56 text-8xl tracking-widest text-center text-white dark:text-gray-100 flex justify-center items-center" >
        {( '' + ( id % 1000 ) ).padStart( 2, '0' )}
      </div>
      <div className="flex items-center px-6 py-3 bg-neutral-900">
        <PersonIcon sx={{ color: "#fff" }} />

        <h3 className="mx-3 text-lg font-semibold text-white">{userName}</h3>
      </div>

      <div className="px-6 py-4 relative h-96 mb-8 w-full flex flex-col justify-between text-gray-700 dark:text-gray-200">
{/* 
        <div className='h-full w-full'>

        </div> */}

        <div className=''>
          <p className="text-xl font-semibold text-gray-800 dark:text-white">{status}</p>

          <p className="py-2 text-gray-700 dark:text-gray-400 whitespace-pre">{foodList}</p>
        </div>

          {/* <div className="flex items-center mt-4 text-gray-700 dark:text-gray-200">
            <CurrencyRupeeRoundedIcon />
    
            <p className="px-2 text-sm">{totalCost}</p>
      </div> */}

          <div className="flex items-center mt-4 text-gray-700 dark:text-gray-200">

            <AccessTimeRoundedIcon />
            <p className="px-2 text-sm"><b>Ordered &nbsp;&nbsp;:</b> {_orderedTime}  </p>
          </div>

          <div className="flex items-center mt-4 ">

            <AvTimerRoundedIcon />
            <p className="px-2 text-sm"><b>Delivered Time :</b> {_deliveredTime}</p>
          </div>
          <div className="flex flex-row justify-start align-top items-start mt-4 ">

            <HomeRoundedIcon />
            <p className="px-2 text-sm"><b>Address :</b> </p>
            <div>
              {address_Line_1},<br />{address_Line_2}, <br />{pincode}
            </div>
          </div>
          <div className="flex flex-row justify-start align-top items-start mt-4 ">

            <DeliveryDiningIcon />
            <div className='flex justify-between  px-2'>
              <b>Delivery Boy &nbsp; :</b>
            </div>
            <div className='flex flex-col  '>
              {deliveryBoy}
              <div><a className='underline' href={`tel:${ deliveryBoyNumber }`}> {deliveryBoyNumber}</a> </div>
            </div>


          </div>
          <div className="flex items-center mt-4 ">

            <PaidOutlinedIcon />
            <div className='flex justify-between text-xl px-2'>
              <b>Total : &nbsp; </b>  ₹ {profit}
            </div>
          </div>
        <StatusButton id={id} isDelivery={isDelivery} />




      </div>
    </motion.div>

  )
}