import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import Loading from '../../../Animations/Loading'
import Error404 from '../../../Components/Error404'

import { snakeCase } from '../../../Functionalities/Formatter/SnakeCase'
import { useDispatch } from 'react-redux'
import { clearAllFoodListRedux } from '../Foods/FoodSlicer'
import React from 'react'
import FoodSearchBar from '../../../Components/SearchBar/FoodSearchBar'

function isHotelAvailable(value:any) {
  return value.isAvailable
}
function isHotelUnAvailable(value:any) {
  return !value.isAvailable
}



export const Hotels = () => {
  const dispatch = useDispatch()
  dispatch(clearAllFoodListRedux())
  const { isLoading, isError,isSuccess , data, error } = useQuery(['fetchHotelsForUsers'], () => {
    return (axios.get('/api/getHotels/'))
    },
    {
      refetchOnMount:true,
      staleTime:30000,//30sec
      cacheTime:30000,
      refetchInterval:30000,
      refetchOnWindowFocus: true,
      
    })
    let availableHotels = []
    let unAvailableHotels = []
    if(isSuccess){
      availableHotels = data.data.hotels.filter(isHotelAvailable)
      unAvailableHotels = data.data.hotels.filter(isHotelUnAvailable)
    }
    
  
  const child = {
    initial:{
        x : -30,
        opacity:0,     
    },
    animation:{
        x:0,
        opacity:1,
        transition:{
          duration : 1,
      }
    },    
  }



  

  return (
    <>
    <FoodSearchBar />
    <motion.div 
     className='text-gray-700 dark:text-gray-300 body-font'>
      <motion.div
            initial={{
                y : -50,
                opacity:0
            }}
            whileInView={"animation"}
            variants={{animation:{
                y:0,
                opacity:1
            }}}
            transition={{
                duration : 0.7
            }} className="text-center mb-6  py-10">
      <h1 className="sm:text-3xl text-2xl font-medium text-center title-font dark:base-orange-text base-orange-text mb-4 ">Restaurants</h1>
      <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto"> Choose your Favourite Restaurant </p>
    </motion.div>
    {/* @ts-ignore */} 

      {isError && <Error404 message={error?.message} />}
      {isLoading && <Loading />  }
      {isSuccess &&
      <div className='grid lg:grid-cols-2 grid-cols-1 justify-items-center dark:bg-gray-800 mb-10'>

      
        {
          isSuccess &&  availableHotels.map((value:any) =>(
            <motion.div 
            initial="initial"
            whileInView="animation" 
            variants={child} 
            key={value.id}
            >
            <Link to={`/Foods/${snakeCase(value.name)}/${value.id}`}  className=" relative md:h-48 max-h-full m-5 flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-neutral-100 dark:border-gray-700 dark:bg-gray-700 dark:hover:bg-zinc-800 ">
              <img className="aspect-square object-cover w-full h-60 md:h-auto rounded-t-lg md:w-48 md:rounded-none md:rounded-l-lg" 
              src={value.image} alt={value.name} />
              
              <div className="block h-40 md:h-full justify-between p-4 leading-normal w-80 sm:w-96 overflow-hidden">
                <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{value.name}</h2>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 overflow-hidden">{value.description}</p>
              </div>

            </Link>  
            </motion.div>
          // )
          ) ) 
          }
        </div>}
        <hr className='border-gray-400' /> 
        
        <motion.div
            initial={{
                y : -50,
                opacity:0
            }}
            whileInView={"animation"}
            variants={{animation:{
                y:0,
                opacity:1
            }}}
            transition={{
                duration : 0.7
            }} className="text-center mb-6  py-10">
      <p className="sm:text-3xl text-2xl font-medium text-center title-font dark:text-white mb-4 ">Currently closed</p>
      <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Checkout other Popular Hotels</p>
    </motion.div>
    {/* @ts-ignore */}
      {isError && <Error404 message={error?.message} />}
      {isLoading && <Loading />  }
      {isSuccess &&

      
<div className='grid lg:grid-cols-2 grid-cols-1 justify-items-center dark:bg-gray-800 mb-10'>
      {isSuccess &&  unAvailableHotels.map((value:any) =>(
            <motion.div 
            initial="initial"
            whileInView="animation" 
            variants={child} 
            key={value.id}
            >
            <div className=" relative md:h-48 max-h-full m-5 flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-neutral-100 dark:border-gray-700 dark:bg-gray-700 dark:hover:bg-zinc-800 ">
              <img className="aspect-square object-cover grayscale w-full h-60 md:h-auto rounded-t-lg md:w-48 md:rounded-none md:rounded-l-lg" 
              src={value.image} alt={value.name}/>
              
              <div className="block h-40 md:h-full justify-between p-4 leading-normal w-80 sm:w-96 overflow-hidden">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{value.name}</h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 overflow-hidden">{value.description}</p>
              </div>

            </div>  
            </motion.div>
          ) ) 
      }
    </div>
        }
    </motion.div>
    
    
    </>
    
  )
}

export default Hotels