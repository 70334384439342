import React, { useState } from 'react'
import { usePostReq } from '../../../CustomHooks/postReq';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { combineAPI_food_categories } from '../../../Functionalities/smallFns';

function AddFood() {
  const { hotelID } = useParams();
  const [disableButton, setDisableButton] = useState( false )

  const { isLoading, isError, isSuccess, data, error } = useQuery( ['fetch-foodCategories-add'], () => {
    return ( axios.get( `/api/get_HotelCategorySuggestion/`,
      {
        params: {
          hotelID: hotelID
        }
      } ) )
  },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: 5000,
      cacheTime: 5000,
      refetchInterval: 5000,

    } )

  let suggestion: any = []
  if ( isSuccess ) {
    suggestion = combineAPI_food_categories( data )
  }
  const [showCategoryInput, setShowCategoryInput] = useState( false )
  const [inputs, setInputs] = useState(
    {
      // No ID for new food
      hotelID: hotelID,
      name: '',
      price: 0,
      isAvailable: true,
      isVeg: false,
      image: null,
      imagePreview: null,
      category: '',
    } );
  const { mutate: PostAddFood } = usePostReq( OnSuccess, () => { setDisableButton( false ) } )
  function OnSuccess() {
    setInputs( {
      hotelID: inputs.hotelID,
      name: '',
      price: 0,
      isAvailable: true,
      isVeg: false,
      image: null,
      imagePreview: null,
      category: '',
    } )
    setShowCategoryInput( false )
    setDisableButton( false )
  }
  const handleChange = ( event: any ) => {
    let name: any, value: any;

    name = event.target.name;
    if ( event.target.name == "image" ) {
      value = event.target.files[0];
      // @ts-ignore
      setInputs( values => ( { ...values, imagePreview: URL.createObjectURL( event.target.files[0] ) } ) )
    }
    if ( name == 'foodName' ) {//Change for Auto fill in input field
      name = 'name'
    }
    if ( event.target.type === 'checkbox' ) {
      // For check Box
      value = event.target.checked;
    } else if ( event.target.type === 'text' || event.target.type === 'number' ) {
      // For Input Form
      value = event.target.value;
    }
    else if ( event.target.type === 'select-one' ) {
      // For Input Form
      value = event.target.value;
      if ( value == 'Other' ) {
        value = ""
        setShowCategoryInput( true )
        setTimeout( () => {
          // @ts-ignore
          document.getElementById( "category_input" ).focus();
        }, 100 );
      }
    }
    setInputs( values => ( { ...values, [name]: value } ) )
  }

  const handleSubmit = ( event: { preventDefault: () => void } ) => {
    event.preventDefault();
    setDisableButton( true )
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const formData = new FormData();

    formData.set( 'hotelID', inputs.hotelID + "" );
    formData.set( 'name', inputs.name );
    formData.set( 'price', inputs.price + "" );
    formData.set( 'isAvailable', inputs.isAvailable ? "true" : "false" );
    formData.set( 'isVeg', inputs.isVeg ? "true" : "false" );
    formData.set( 'category', inputs.category );
    if ( inputs.image ) {
      formData.set(
        "image",
        // @ts-ignore
        inputs.image,
        // @ts-ignore
      );
    }



    const url = `/api/hotels/create/food/`
    PostAddFood( { url, body: formData, config } )

    // @ts-ignore
    document.getElementById( "foodName" ).focus();


  }
  return (
    // Add New Foods
    <form onSubmit={handleSubmit} className="mild-border p-2 py-8 rounded-md relative">


      {/* Food Image Field */}
      <div className="flex justify-center items-center px-2 mb-9">
        <label
          htmlFor="dropzone-file-new-food"// inCase of change ID below with same name
          className="flex  justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div className="flex flex-col sm:flex-row justify-evenly w-full items-center pt-5 pb-6">
            {inputs.imagePreview && <img className="w-36 h-36" src={inputs.imagePreview} alt="" />}

            <div className=" dark:text-gray-100 flex flex-col justify-center items-center ">

              <svg
                aria-hidden="true"
                className="mb-3 w-10 h-10  "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <p className="mb-2 text-sm ">
                <span className="font-semibold">Click to upload</span> or
                drag and drop
              </p>
              <p className="text-xs">
                JPG (Resolution 1:1)
              </p>
            </div>

          </div>
          <input id="dropzone-file-new-food" // inCase of change htmlfor above with same name
            type="file" className="hidden"
            onChange={( e ) => handleChange( e )}
            name='image'
            accept=".jpg, .jpeg, .png" />
        </label>

      </div>
      {/* Food Image Field ends */}

      <div className="grid md:grid-cols-2 w-10/12 ml-3 mb-6">
        <div className="w-full">
          <div className="mb-3">
            <label
              htmlFor="foodName"
              className="relative font-medium text-gray-900 dark:text-gray-300 text-2xl"
            >
              Food Name
            </label>
            <input
              type="text"
              id="foodName"
              name="foodName"
              className="px-3 w-full mt-3  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              value={inputs.name} onChange={( e ) => handleChange( e )}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="mb-3 ">
            <label
              htmlFor="price"
              className="relative  font-medium text-gray-900 dark:text-gray-300 text-2xl "
            >
              Price
            </label>
            <input
              type="number"
              id="price"
              min="1"
              name="price"
              max="10000"
              className="px-3 md:mx-3 w-full mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              onFocus={( e ) => { e.target.select() }}
              value={inputs.price} onChange={( e ) => handleChange( e )}
            />
          </div>
        </div>
      </div>

      <div className="my-6 mx-3 pb-8 flex flex-wrap items-center">
        <div>

          <label
            htmlFor="veg-toggle"
            className="inline-flex relative items-center cursor-pointer mx-2"
          >
            <input
              type="checkbox"
              value=""
              id="veg-toggle"
              className="sr-only peer"
              name="isVeg"
              onChange={( e ) => handleChange( e )} checked={inputs.isVeg}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              Veg
            </span>
          </label>
          <label
            htmlFor="food-availability-toggle"
            className="inline-flex relative items-center cursor-pointer mx-2"
          >
            <input
              type="checkbox"
              value=""
              id="food-availability-toggle"
              className="sr-only peer"
              name="isAvailable"
              onChange={( e ) => handleChange( e )} checked={inputs.isAvailable}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              Available
            </span>
          </label>
        </div>

        <div className=''>
          {!showCategoryInput ?

            ( <select name='category' className='mt-5 md:mt-auto md:ml-6 w-72' value={inputs.category} onChange={( e ) => handleChange( e )}>
              <option value="">Category...</option>
              {suggestion.map( ( option: any ) => (
                <option key={option} value={option}>{option}</option>
              ) )}
            </select> ) :
            (

              <div className='flex flex-col md:ml-6 gap-2  '>
                <div className='dark:text-gray-200'>Enter New Category</div>
                <input
                  type="text"
                  id="category_input"
                  name="category"
                  className="mt-5 md:mt-auto  w-72  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={inputs.category} onChange={( e ) => handleChange( e )}
                />
              </div>

            )
          }

        </div>
      </div>
      {/* <ImageUploader /> */}
      <button
        type="submit" disabled={disableButton}
        className="absolute  mx-5 right-5 bottom-5 w-32 text-white items border-2  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Save
      </button>
    </form>
  )
}

export default AddFood