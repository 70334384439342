import React from 'react'
import { motion } from 'framer-motion';
import Error404 from '../../../Components/Error404';
import Loading from '../../../Animations/Loading';
import DeliveryBoyDashBoardCardList from './DeliveryBoyDashBoardCardList'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Link } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import DeliveryBoyHeader from '../DeliveryBoyHeader';
import { RQ_Constants } from '../../../Constants/Axios/ReactQueryRefetch';
function DelveryBoyDashBoard() {
  const dispatch = useDispatch()

    const { isLoading, isError,isSuccess, data, error } = useQuery(['fetchMyDelivery'], () => {
    return (axios.get(`/api/getDeliveryBoyCurrentOrders/`))
  },RQ_Constants.one_minute)
  return (
    <>
    <DeliveryBoyHeader/>
    
    {isError && <Error404 message={error.message} /> }
    {isLoading && <Loading />  }

      
    

    <div className='dark:bg-gray-800 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-y-10 py-10 px-5 '>
    {
      isSuccess && data?.data.map((value) =>{
        var id =value.id
        var processedFoodList =value.processedFoodList //To avoid name collision
        var userName =value.customerDetails.username
        var phoneNumber=value.customerDetails.phoneNumber
        var altPhoneNumber =value.customerDetails.altPhoneNumber
        var address =value.address
        var hotelName = value.hotelDetails.name
        var hotelAddress = value.hotelDetails.address
        var orderedTime = value.orderedTime
        var total = value.total
        var otp = value.otp
        var hotelImage = value.hotelDetails.image


        return (<DeliveryBoyDashBoardCardList id={id} userName={userName} processedFoodList={processedFoodList} phoneNumber={phoneNumber}
          altPhoneNumber={altPhoneNumber}  hotelName={hotelName} hotelImage={hotelImage}
          hotelAddress={hotelAddress} orderedTime={orderedTime} total={total} otp={otp} dispatch={dispatch}
          deliveryBoy_QR_OTP={value.deliveryBoy_QR_OTP} address_Line_1 ={value.address_Line_1} address_Line_2={value.address_Line_2}
           pincode={value.pincode}  latitude={value.latitude} longitude={value.longitude} landmark={value.landmark}/>)
      }
       ) 
    }
    </div>
    </>
)
}
export default DelveryBoyDashBoard