import React from 'react'

function Breaker({...props}) {
  return (
<div className="inline-flex items-center justify-center w-full" {...props}>
    <div className="w-full h-1 my-8 bg-gray-200 border-0 dark:bg-gray-700"></div>
    <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900"> </span>
</div>  )
}

export default Breaker