import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../App/store'
import { SetTogglePopUpAccount, TogglePopUpAccount } from '../../Animations/PopUp/AccountPopUp/AccountSlicer'
import { useDeleteReq } from '../../CustomHooks/deleteReq'
import { Alert } from '../../Animations/Alerts/AlertSlicer'
import RefreshWindow from '../../Functionalities/RefreshWindow'
import { useNavigate } from 'react-router-dom'

function DeleteAccount() {
    const dispatch = useDispatch()
    const UserInfo = useSelector((state:RootState)=>state.UserInfo)
    const navigate = useNavigate();

    const { mutate:DeleteUser }   = useDeleteReq(()=>{ 
      dispatch(Alert({"status":"success","heading":"Success","message":"Account deleted successfully"}))
      navigate("/");
      setTimeout(RefreshWindow, 3000);
    })
    function btnClicked(){
      const config = {
        headers: {
            'Content-type': 'application/json',
        }
    }
      const body = {}
      const url = '/api/delete/my_account/'
      DeleteUser({url,config,body})
      // const myTimeout = setTimeout(refetchFoodList, 500);
      // setIsCardVisible(false)
      // 
      
  
      
    }
    if(!UserInfo.isAuthenticated){
        dispatch(SetTogglePopUpAccount(true))
    }else{
      dispatch(SetTogglePopUpAccount(false))

    }

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
  <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      {/* <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo">
          Flowbite    
      </a> */}
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl text-center font-semibold  text-[#FF0000] md:text-2xl">
                  Sure delete your account?
              </h1>
              <div className="space-y-4 md:space-y-6">
                  <div>
                      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                      <input disabled={true} value={UserInfo.username} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                  </div>
                  <div>
                      <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                      <input disabled={true} value={UserInfo.email} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                  </div>
                  <div>
                      <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">PhoneNumber</label>
                      <input disabled={true} value={UserInfo.phoneNumber} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                  </div>
                  <div className="flex items-center justify-between">
                      <div className="flex items-start">
                          <div className="ml-3 text-sm">
                            <label htmlFor="remember" className="text-gray-500 dark:text-gray-300"> <b>Note :</b> Only the user details will be deleted. Action is irreversible</label>
                          </div>
                      </div>
                  </div>
                  <button type="submit" onClick={btnClicked} className="w-full bg-[#FF0000] text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Delete Account</button>
              </div>
          </div>
      </div>
  </div>
</section>
  )
}

export default DeleteAccount