import React from 'react'


import { motion } from 'framer-motion';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import PersonIcon from '@mui/icons-material/Person';
import { timeFormatter12HR, timeFormatterDate } from '../../../Functionalities/smallFns';
import DeliveryDiningRoundedIcon from '@mui/icons-material/DeliveryDiningRounded';
import JSON_to_Foodlist from '../../../Functionalities/Data_To_TSX/JSON_to_Foodlist';
import DisplayCard from '../../../Components/Card/DisplayCard';
interface Props{
  id:any,
  address:any,
  userName:any,
  cookedTime:any,
  pickedUpTime:any;
  deliveryBoyName:any;
  orderedTime: any;
  requestedPickupTime: any;
  deliveredTime: any;
  total: any;
  status: any;
  deliveryBoypickedUpTime:any|null;
  hotelName:any|null;
  hotelImg:any|null;
  hotelAddress:any|null;
  processedFoodList:any;
}
function DeliveryBoyHistoryCardList({id,address, userName, cookedTime,pickedUpTime,
  deliveryBoyName, orderedTime, requestedPickupTime, deliveredTime,
  total, status,processedFoodList,deliveryBoypickedUpTime,hotelName,hotelAddress,hotelImg}:Props) {
  
    let _orderedTime = timeFormatter12HR(orderedTime)
    let _cookedTime  =  timeFormatter12HR(cookedTime)
    console.log(cookedTime)
    let _deliveryBoypickedUpTime =timeFormatter12HR(deliveryBoypickedUpTime)
    let _requestedPickupTime =timeFormatter12HR(requestedPickupTime)
    let _userPickedUpTime =requestedPickupTime?timeFormatter12HR(deliveredTime):"-"
    let _deliveredTime = !requestedPickupTime?timeFormatter12HR(deliveredTime):"-"

const date = timeFormatterDate((orderedTime))
  
    const list = {
        initial:{
          x : -50,
          opacity:0,
      },
      animation:{
        x:0,
        opacity:1,
        transition:{
          duration : 0.7
      },
      
    }    
      }
  return (

      <DisplayCard Head={
      <div className='relative'>
        <div className='right-0 absolute text-green-100  bg-blur-dark flex justify-center align-middle py-3 px-4'>
          ID : {id}
        </div>
        <img src={hotelImg} alt={hotelName} className="object-cover object-center w-full h-56" />
        <div className="flex justify-between px-6 py-3 bg-neutral-900">
          <div className='flex justify-center items-center'>
            <PersonIcon sx={{ color: "#fff" }} />

            <h3 className="mx-3 text-lg font-semibold text-white">{userName}</h3>
          </div>

        </div>
      </div>} BodyTop={
        <><div className='right-0 absolute text-gray-800 dark:text-green-100 pr-5 '>
          {date}
        </div><div className=''>
            <p className="text-xl font-semibold text-gray-800 dark:text-white">{status}</p>

            <p className="py-2 text-gray-700 dark:text-gray-400 whitespace-pre">{processedFoodList}</p>
          </div></>

      } BodyLow={
        
        <div className=''>
    
        <div className="flex items-center mt-4 text-gray-700 dark:text-gray-200">
          <DeliveryDiningRoundedIcon />
            <div>
                <p className="px-2 text-sm">
                  <b>Hotel Name : </b> {hotelName} <br />
                </p>
                <p className="px-2 text-sm">
                  <b>Hotel address : </b> {hotelAddress} <br />
                </p>
                <p className="px-2 text-sm">
                  <b>Delivered address : </b> {address} <br />
                </p>
            </div>
                
        </div>
        <div className="flex items-start mt-4 text-gray-700 dark:text-gray-200">
        
        <AccessTimeRoundedIcon />
            <div className="px-2 text-sm flex flex-col w-5/6">
              <div className='flex justify-between'>
                <b>Ordered &nbsp;&nbsp;:</b> {_orderedTime} <br />
              </div>
              <div className='flex justify-between'>
                <b>Cooked &nbsp;&nbsp;:</b> {_cookedTime} <br />
              </div>
              <div className='flex justify-between'>
                <b>Delivery Boy Picked :</b> {_deliveryBoypickedUpTime} <br />
              </div>
              <div className='flex justify-between'>
                <b>Delivered :</b> {_deliveredTime}
              </div>

            </div>
        </div>

        
    
      </div>
      } 

      />
        
      
        
    
        
  )
}

export default DeliveryBoyHistoryCardList