import React, { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { motion, AnimatePresence } from 'framer-motion';
import { isAndroid } from '../Functionalities/smallFns';
import { PLAY_STORE_URL } from '../Constants/globalConstants';
function AndroidRedirect() {
    const [visible,setVisible] = useState(isAndroid());
    // const [visible, setVisible] = useState( true );

    const animation = {
        initial: {
            y: 200,
            opacity: 0,
        },
        animation: {
            y: 0,
            opacity: 1,
        },
        exit: {
            opacity: 0,
        },
    }

    return (
        <div className="fixed bottom-4 z-[500] ">
            <AnimatePresence

                exitBeforeEnter={true}
                onExitComplete={() => null}
            >

                {visible && ( <motion.div
                    variants={animation}
                    initial="initial"
                    animate="animation"
                    exit="exit"

                    className="flex  w-80 max-w-sm overflow-hidden dark:bg-blur-dark bg-blur-light rounded-lg shadow-md m-2">
                    <div className="flex items-center justify-center w-20 dark:bg-gray-200 bg-gray-400">
                        <img className='p-2' src="https://www.kallardo.com/static/logo192.png" alt="" />
                    </div>

                    <div className="px-4 py-2 -mx-3">
                        <div className="mx-3">
                            <span className="font-semibold text-orange-500">Kallardo </span>
                            <p className="text-sm text-gray-800 dark:text-gray-200">Install Android App for better experience</p>
                        </div>
                    </div>
                    <div onClick={() => setVisible( false )} className="absolute right-3 dark:text-gray-400 text-gray-600">
                        <CloseRoundedIcon />
                    </div>
                    <a href={PLAY_STORE_URL} className='absolute right-4 text-orange-600 bottom-1 tracking-widest'>
                        INSTALL
                    </a>
                </motion.div> )}

            </AnimatePresence>
        </div>
    )
}

export default AndroidRedirect