import React, { useState } from 'react';


import { motion } from 'framer-motion';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AvTimerRoundedIcon from '@mui/icons-material/AvTimerRounded';
import { useDispatch } from 'react-redux';
import { ToggleInStoreOTPPopUp } from '../../../../Animations/PopUp/InStorePopUp/InStoreOTPPopUpSlicer';
import { setGlobalData } from '../../../../GlobalSlicer/GlobalSlicer';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { show_FoodPicked_QR } from '../HotelOrdersDashBoard/HotelDashBoardCardList';
import { useNavigate, useParams } from 'react-router-dom';



export default function PickupHotelDashboardCard({id , orderedTime ,foodList,
  requestedPickupTime , deliveredTime ,status , isAvailable, userName,profit,hotel_QR_OTP}:any) {

    
      


    const date = new Date(orderedTime).toLocaleDateString('en-GB');
    const _orderedTime = new Date(orderedTime).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
      let _deliveredTime = undefined
    
    if (deliveredTime) {
      _deliveredTime = new Date(deliveredTime).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
    }
    let _requestedPickupTime = undefined
    console.log(requestedPickupTime);
    
    if (requestedPickupTime) {
      _requestedPickupTime = new Date(requestedPickupTime).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
    }
      
      const list = {
        initial:{
          x : -50,
          opacity:0,
      },
      animation:{
        x:0,
        opacity:1,
        transition:{
          duration : 0.7
      },
      
    }    
      }
    return (
    
    <motion.div variants={list}
    initial="initial"
    whileInView="animation"
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 1.02 }}
    className="max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-900 w-full">
    <div className="object-cover bg-blue-800 object-center w-full h-56 text-8xl tracking-widest text-center text-white dark:text-gray-100 flex justify-center items-center" >
        {(''+(id%1000)).padStart(2, '0')}
    </div> 
    <div className="flex items-center px-6 py-3 bg-neutral-900">
        <PersonIcon sx={{ color: "#fff" }}  />

        <h3 className="mx-3 text-lg font-semibold text-white">{userName}</h3>
    </div>
    
    <div className="px-6 py-4 relative flex flex-col justify-between md:h-80 text-gray-700 dark:text-gray-200">
      <div className='right-0 absolute dark:text-gray-100 pr-5 '>
        {date}
      </div>
      <StatusButton id={id} hotel_QR_OTP={hotel_QR_OTP}/>
      <div className=''>
        <p className="text-xl font-semibold text-gray-800 dark:text-white">{status}</p>
    
        <p className="py-2 text-gray-700 dark:text-gray-400 whitespace-pre">{foodList}</p>
      </div>
        
      <div className=''>
      {/* <div className="flex items-center mt-4 text-gray-700 dark:text-gray-200">
            <CurrencyRupeeRoundedIcon />
    
            <p className="px-2 text-sm">{totalCost}</p>
      </div> */}
    
        <div className="flex items-center mt-4 text-gray-700 dark:text-gray-200">
            
        <AccessTimeRoundedIcon />
            <p className="px-2 text-sm"><b>Ordered &nbsp;&nbsp;:</b> {_orderedTime} <br /><b>Delivered :</b> {_deliveredTime?_deliveredTime:'-'}</p>
        </div>
    
        <div className="flex items-center mt-4 ">
            
        <AvTimerRoundedIcon />
                <p className="px-2 text-sm"><b>Requested Time :</b> {_requestedPickupTime}</p>
        </div>
        <div className="flex items-center mt-4 ">

        <PaidOutlinedIcon />
        <div className='flex justify-between text-xl px-2'>
          <b>Total : &nbsp; </b>  ₹ {profit}
        </div>
        </div>
        <div className='h-20'>{/*! DONT edit .. height for absolute position status button */}         </div>

      </div>
        

        
    </div>
    </motion.div>
          
    )
    }

    function StatusButton( { id,hotel_QR_OTP }:any){
      const dispatch = useDispatch()
      const navigate = useNavigate();
      const { hotelID } = useParams();

      const [miniPop, setMiniPop] = useState(false)
      const [status, setStatus] = useState('Status')
      // const pendingDoubleTap = useDoubleTap((event) => {
      //   pending()
      // });
      // const cookingDoubleTap = useDoubleTap((event) => {
      //   cooking()
      // });
      // const pickUpDoubleTap = useDoubleTap((event) => {
      //   pickUp()
      // });
      function toggleMiniPop() {
        setMiniPop(!miniPop)
      }
      
      function delivered(){
          toggleMiniPop()
          dispatch(ToggleInStoreOTPPopUp())
          dispatch(setGlobalData({currentOrderID:id}))
        }
      
      return(
        <div className='absolute right-0 bottom-0 text-lg mr-2 mb-3 flex flex-col '>
        
    
        { miniPop &&
        <div>
        <div id="dropdownTop" className="z-10 w-44 mb-1 mr-3 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
          <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownTopButton">
            <li onClick={delivered}>
              <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delivered</span>
            </li>
            <li onClick={()=>{show_FoodPicked_QR(id,hotel_QR_OTP, navigate, toggleMiniPop,hotelID)}}>
                <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Show QR</span>
              </li>
          </ul>
      </div>
      </div>
        
        }
    
    
    <div className='flex justify-end'>
      <button onClick={ ()=>{toggleMiniPop()}   } id="dropdownTopButton" data-dropdown-toggle="dropdownTop" data-dropdown-placement="top"
         className="mr-3 mb-3 right-0 md:mb-0 w-28 flex justify-between text-white bg-blue-700 hover:bg-blue-800  font-medium rounded-lg text-sm px-4 py-2.5 text-center items-center dark:bg-blue-600 dark:hover:bg-blue-700 " type="button">
          {status} <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
      </button>
    </div>
    
    </div>
    
      )
    }