import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleInStoreOTPPopUp } from './InStoreOTPPopUpSlicer';
import { usePutReq } from '../../../CustomHooks/putReq';
import { RootState } from '../../../App/store';
import RefreshWindow from '../../../Functionalities/RefreshWindow';
import JSON_Config from '../../../Constants/Axios/JSON_Config';
import { useNavigate,useParams,useSearchParams } from 'react-router-dom';
import { PopConstants } from '../PopUpManagerConstants';
import {  } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query';

function QR_food_picked() {

    const queryClient = useQueryClient()

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams()
    let id = searchParams.get( "id" )//orderID
    let hotel_QR_OTP = searchParams.get( "hotel_QR_OTP" )
    let hotelID = searchParams.get( "hotelID" )
    console.log(hotelID)

    function closePopUp() {

        // Since we have 2 qr otp popups we need to invalidate both
        queryClient.invalidateQueries({ queryKey: ['fetchHotelDashboardValue-'+ hotelID]  })
        queryClient.invalidateQueries({ queryKey: ['fetchPickUpHotelDashboardValue-'+hotelID]  })
        navigate( window.location.pathname.replace( PopConstants.QR_FOOD_PICKED, '' ) )
    }

    // @ts-ignore
    const qrCode = new QRCodeStyling( {
        "backgroundOptions": {
            "color": "#ffffff"
        },
        "backgroundOptionsHelper": {
            "colorType": {
                "gradient": false,
                "single": true
            },
            "gradient": {
                "color1": "#ffffff",
                "color2": "#ffffff",
                "linear": true,
                "radial": false,
                "rotation": "0"
            }
        },
        "cornersDotOptions": {
            "color": "#f97316",
            "type": ""
        },
        "cornersDotOptionsHelper": {
            "colorType": {
                "gradient": false,
                "single": true
            },
            "gradient": {
                "color1": "#000000",
                "color2": "#000000",
                "linear": true,
                "radial": false,
                "rotation": "0"
            }
        },
        "cornersSquareOptions": {
            "color": "#f97316",
            "gradient": null,
            "type": "square"
        },
        "cornersSquareOptionsHelper": {
            "colorType": {
                "gradient": false,
                "single": true
            },
            "gradient": {
                "color1": "#000000",
                "color2": "#000000",
                "linear": true,
                "radial": false,
                "rotation": "0"
            }
        },
        // "data": `https://www.kallardo.com/food_picked/${ id }/${ hotel_QR_OTP }`,
        "data": `{
            "type" : "QR_Food_Picked",
            "id" : ${id},
            "hotel_QR_OTP" : ${hotel_QR_OTP}
        }`,

        "dotsOptions": {
            "color": "#000000",
            "gradient": null,
            "type": "square"
        },
        "dotsOptionsHelper": {
            "colorType": {
                "gradient": false,
                "single": true
            },
            "gradient": {
                "color1": "#6a1a4c",
                "color2": "#6a1a4c",
                "linear": true,
                "radial": false,
                "rotation": "0"
            }
        },
        "height": 500,
        "width": 500,
        "imageOptions": {
            crossOrigin: "anonymous",

            "hideBackgroundDots": true,
            "imageSize": 0.7,
            "margin": 0
        },
        "margin": 10,
        "qrOptions": {
            "errorCorrectionLevel": "H",
            "mode": "Byte",
            "typeNumber": "0"
        },
        "image": "/static/forQR.png"

    } );

    useEffect( () => {
        qrCode.append( document.getElementById( 'QR_Container' ) );
        const qrImage = document.querySelector( '#QR_Container canvas' );
        if ( qrImage ) {
            qrImage.classList.add( 'h-full' )
        }
    }, [] );
    // console.log(qrCode)
    return (
        <>



            {/* // <!-- Main modal --> */}
            <motion.div
                initial={{
                    y: 300,
                }}
                whileInView={"animation"}
                variants={{
                    animation: {
                        y: 0,

                    }
                }}
                transition={{
                    duration: 0.5
                }} className=" fixed py-3 md:py-28 z-40  sm:z-50 w-full md:inset-0 h-modal md:h-full justify-center items-start flex shadow-3xl" role="dialog">
                <form className="relative px-4 pt-4 w-full max-w-sm h-full md:h-auto ">
                    {/* <!-- Modal content --> */}
                    <div className="relative dark:bg-blur-dark bg-blur-light rounded-lg   shadow ">
                        {/* To Do  Add Redux for Close button */}

                        {/* Add sometin for  onlclick */}




                        <button type="button" onClick={closePopUp} className="absolute top-3 right-2.5 text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="py-6 px-6 lg:px-8">
                            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white text-center">Scan the QR</h3>
                            <div className="space-y-6 mild-border rounded-lg p-3">
                                {/* Inner Box */}

                                <div id='QR_Container' className='h-52 flex justify-center items-center '>


                                </div>
                                <button type="button" onClick={closePopUp} className="w-full text-white base-orange hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "> Close </button>
                                <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                                    Let user scan the  <span className="base-orange-text">QR code</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </motion.div>
        </>

    )
}

export default QR_food_picked





