import React from 'react'
import { motion } from 'framer-motion'

function Component_2() {
  return (
    <div className="    px-4 sm:ppx-6 lg:px-8 relative py-26 lg:p-20">
    <div className="relative">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <motion.div 
             initial={{
                x : -50,
                opacity:0.2
            }}
            whileInView={"animation"}
            variants={{animation:{
                x:0,
                opacity:1
            }}}
            transition={{
                duration : 0.5
            }} className="lg:col-start-2 lg:max-w-2xl ml-auto pt-20 md:py-0">
                <h2 className="text-base leading-6 text-orange-500 font-semibold uppercase">
                    Kallardo
                </h2>
                <h4 className="mt-2 text-2xl leading-8 font-extrabold text-gray-900 dark:text-white sm:text-3xl sm:leading-9">
                    Get your favourite foods on your favourite time
                </h4>
                <p className="mt-4 text-lg leading-6 text-gray-500 dark:text-gray-300">
                    You can order your favourite food and mention your pickup time 
                </p>
                <ul className="mt-8 md:grid md:grid-cols-2 gap-6">
                    <li className="mt-6 lg:mt-0">
                        <div className="flex">
                            <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-orange-500 drark:bg-transparent">
                                <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            <span className="ml-4 text-base leading-6 font-medium text-gray-500 dark:text-gray-200">
                                Upto <b className='font-extrabold'>₹300</b> off
                            </span>
                        </div>
                    </li>
                    <li className="mt-6 lg:mt-0">
                        <div className="flex">
                            <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-orange-500 drark:bg-transparent">
                                <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            <span className="ml-4 text-base leading-6 font-medium text-gray-500 dark:text-gray-200">
                                In-store pick up
                            </span>
                        </div>
                    </li>
                    <li className="mt-6 lg:mt-0">
                        <div className="flex">
                            <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-orange-500 drark:bg-transparent">
                                <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            <span className="ml-4 text-base leading-6 font-medium text-gray-500 dark:text-gray-200">
                                Delivery by Restaurant
                            </span>
                        </div>
                    </li>
                    <li className="mt-6 lg:mt-0">
                        <div className="flex">
                            <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-orange-500 drark:bg-transparent">
                                <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            <span className="ml-4 text-base leading-6 font-medium text-gray-500 dark:text-gray-200">
                                Custom pick up time
                            </span>
                        </div>
                    </li>
                </ul>
            </motion.div>
            <div className="py-12 mt-10 lg:-mx-4 relative relative-20 lg:mt-0 lg:col-start-1">
                <div className="relative space-y-4">
                    <div className="flex items-end justify-center lg:justify-start space-x-4">
                        <motion.img
                        initial={{
                            x : -50,
                            opacity:0.2
                        }}
                        whileInView={"animation"}
                        variants={{animation:{
                            x:0,
                            opacity:1
                        }}}
                        transition={{
                            duration : 0.7
                        }} className="rounded-lg shadow-lg w-32 md:w-56" width="200" src="static/home/food_1.jpg" alt="1" />
                        <motion.img
                        initial={{
                            y : -50,
                            opacity:0.2
                        }}
                        whileInView={"animation"}
                        variants={{animation:{
                            y:0,
                            opacity:1
                        }}}
                        transition={{
                            duration : 0.7
                        }} className="rounded-lg shadow-lg w-40 md:w-64" width="260" src="static/home/food_3.jpg" alt="2"/>
                    </div>
                    <div className="flex items-start justify-center lg:justify-start space-x-4 ml-12">
                    <motion.img
                        initial={{
                            y : 50,
                            opacity:0.2
                        }}
                        whileInView={"animation"}
                        variants={{animation:{
                            y:0,
                            opacity:1
                        }}}
                        transition={{
                            duration : 0.7
                        }}  className="rounded-lg shadow-lg w-24 md:w-40" width="170" src="static/home/food_2.jpg" alt="3"/>
                        <motion.img
                        initial={{
                            x : 50,
                            opacity:0.2
                        }}
                        whileInView={"animation"}
                        variants={{animation:{
                            x:0,
                            opacity:1
                        }}}
                        transition={{
                            duration : 0.7
                        }} className="rounded-lg shadow-lg w-32 md:w-56" width="200" src="static/home/food_4.jpg" alt="4"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    )
}

export default Component_2