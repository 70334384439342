import React, { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { motion,AnimatePresence } from 'framer-motion';
function AlertError({heading='Error', message}:({heading:string,message:any})) {
    const [visible,setVisible] = useState(true);
    const animation = {
        initial: {
            x:-200,
            opacity:0,
        },
        animation: {
            x:0,
            opacity:1,
        },
        exit:{
            opacity:0,
        },
      }
    
  return (
    <AnimatePresence 
    
    exitBeforeEnter={true}
    onExitComplete={()=>null}
    >
        
    {visible && (<motion.div
    variants={animation}
    initial="initial"
    animate="animation"
    exit="exit"
    
     className="flex w-80 max-w-sm overflow-hidden dark:bg-blur-dark bg-blur-light rounded-lg shadow-md m-2">
        <div className="flex items-center justify-center w-12 bg-yellow-400">
             <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"/>
            </svg>
        </div>
        
        <div className="px-4 py-2 -mx-3">
            <div className="mx-3">
                <span className="font-semibold text-yellow-400 dark:text-yellow-300">{heading}</span>
                <p className="text-sm text-gray-600 dark:text-gray-200">{message}</p>
            </div>
        </div>
        <div onClick={()=>setVisible(false)} className="absolute right-3 dark:text-gray-400 text-gray-600">
            <CloseRoundedIcon />
        </div>
    </motion.div>)}
    </AnimatePresence>
  )
}

export default AlertError