import React, { useState } from 'react'
import GlassPopUpLarge from '../../../Components/PopUpComponent/GlassPopUpLarge'
import { PopConstants } from '../PopUpManagerConstants'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import Loading from '../../Loading'
import Error404 from '../../../Components/Error404'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { usePutReq } from '../../../CustomHooks/putReq'
import JSON_Config from '../../../Constants/Axios/JSON_Config'

export default function Choose_delivery_boy_PopUp() {
    const [close, setClose] = useState( false )
    const [searchParams, setSearchParams] = useSearchParams()
    let hotelID = searchParams.get( "hotelID" )
    let orderID = searchParams.get( "orderID" )
    let currentTime = new Date().toLocaleTimeString();
    const queryClient = useQueryClient()
    const { isLoading, isError, isSuccess, data, error } = useQuery(
        [`fetchHotelAvailableDeliveryBoys-${hotelID}-${currentTime}`],

        () => {
            return axios.get( `/api/getHotelAvailableDeliveryBoy/${ hotelID }/ ` );
        },
        {
            refetchOnMount: true,
            staleTime: 600000,//10min
            cacheTime: 600000,
            refetchInterval: 600000,
            refetchOnWindowFocus: false,

        }
    ); // Less probelem : In future using useref to fetch only once, useSeachparams to get hotelID calls the function again when value is changed
    const { mutate:PutAssignDeliveryBoy } = usePutReq(()=>{
        setClose(true)
        //Invalidating the query
         queryClient.invalidateQueries({ queryKey: ['fetchDeliveryHotelDashboardValue-'+hotelID]  })  
         queryClient.invalidateQueries({ queryKey: ['fetchHotelDashboardValue-'+hotelID]  })  
    })

    
    function handleClick(deliveryBoyID: any){
        const config = JSON_Config
        const body = JSON.stringify(
            {
                "deliveryBoyID":deliveryBoyID,
                "id":Number(orderID)
            }
        )
        const url = '/api/hotel/hotelAssignDelivery/'
        PutAssignDeliveryBoy({url,body,config})
    }

    return (
        <GlassPopUpLarge title="Choose Delivery Boy" pop_url={PopConstants.CHOOSE_DELIVERY_BOY} close={close}

            body={
                <>
                    {isLoading && <Loading />}
                    {isError && <Error404 />}
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">

                        {isSuccess && data?.data?.map( ( value: any ) =>
                            <tr  key={value.id} className="bg-white border-b dark:bg-gray-900 dark:even:bg-gray-800 dark:border-gray-700 grid grid-cols-8">
                                <td onClick={()=>{handleClick(value.id)}} scope="row" className="py-4 px-1 font-medium   whitespace-nowrap dark:text-white col-span-3">

                                    <div className='flex flex-row justify-center items-center '>
                                        {value.deliveryBoyDetails.username.substring(0, 11)}
                                    </div>
                                </td>
                                <td className="py-4 text-right px-1   flex flex-row col-span-3
                                ">
                                    <a   href={`tel:${ value.deliveryBoyDetails.phoneNumber }`} className='underline dark:text-gray-200'>{value.deliveryBoyDetails.phoneNumber}</a>
                                </td>
                                <td onClick={()=>{handleClick(value.id)}} className='col-span-2 flex flex-row items-center'>
                                        <img onClick={handleClick} className='h-3 px-2' src="/static/logos/bike-delivery.svg" alt="" /> x {value.currentDeliveryCount}
                                </td>
                            </tr>
                        )
                        }
                    </table>

                </>
            } />
    )
}
