import React, { useEffect, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { motion,AnimatePresence } from 'framer-motion';
function AlertSuccess({heading='Success', message}:({heading:string,message:any})) {
    useEffect(() => {
        let audio= new Audio("/static/audio/pop_success_notification_sound.wav")
        audio.play()
      return 
    }, [])

    const [visible,setVisible] = useState(true);
    const animation = {
        initial: {
            x:-200,
            opacity:0,
        },
        animation: {
            x:0,
            opacity:1,
        },
        exit:{
            opacity:0,
        },
      }
    
  return (
    <AnimatePresence 
    
    // initial={false}
    exitBeforeEnter={true}
    onExitComplete={()=>null}
    >
        
    {visible && (<motion.div
    variants={animation}
    initial="initial"
    animate="animation"
    exit="exit"
    
    // transition={{
    //     duration : 0.3
    // }}
     className="flex w-80 max-w-sm overflow-hidden rounded-lg shadow-md dark:bg-blur-dark bg-blur-light m-2">
        <div className="flex items-center justify-center w-12 bg-emerald-500">
            <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"/>
            </svg>
        </div>
        
        <div className="px-4 py-2 -mx-3">
            <div className="mx-3">
                <span className="font-semibold text-emerald-500 dark:text-emerald-400">{heading}</span>
                <p className="text-sm text-gray-600 dark:text-gray-200">{message}</p>
            </div>
        </div>
        <div onClick={()=>setVisible(false)} className="absolute right-3 dark:text-gray-400 text-gray-600">
            <CloseRoundedIcon />
        </div>
    </motion.div>)}
    </AnimatePresence>
  )
}

export default AlertSuccess