import React, { useState } from 'react'

import { useSelector } from 'react-redux';
import { RootState } from '../../App/store';


import { useDispatch } from 'react-redux';
import { FoodListUpdate } from '../../Screen/Users/Foods/FoodSlicer';
import { FoodListItemsFinalUpdate } from '../../Screen/Users/Foods/FoodSlicer';
import { TogglePopUpAccount } from '../../Animations/PopUp/AccountPopUp/AccountSlicer';
function Counter( { id, foodName, price }: { id: number, foodName: string, price: number } ) {
  const count = useSelector( ( state: RootState ) => state.FoodList.FoodList[id] )
  const dispatch = useDispatch()
  const isAuthenticated = useSelector( ( state: RootState ) => state.UserInfo.isAuthenticated )

  function setCount( newCount: number ) {
    dispatch( FoodListUpdate( { id: id, count: newCount } ) )

  }

  return (
    <div onClick={() => {
      if ( !isAuthenticated ) {
        dispatch( TogglePopUpAccount() )
      }
    }}>

      <div className="inline-flex items-center">
        {count == 0 ?
          (
            <div className='py-1 px-5 rounded-md text-white bg-orange-500' onClick={() => setCount( count + 1 )}>
              Add  &nbsp;+
            </div>
          ) :

          <>
            <button onClick={() => count > 0 ? setCount( count - 1 ) : {}}
              className="bg-white rounded-l border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center px-2 py-1 border-r border-gray-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M20 12H4"
                />
              </svg>
            </button>
            <div
              className="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 flex justify-center items-center h-[2.1rem] w-[2.1rem] select-none"
            >
              {count}
            </div>
            <button onClick={() => setCount( count + 1 )}
              className="bg-white rounded-r border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center px-2 py-1 border-r border-gray-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </button>
          </>


        }

      </div>
    </div>
  )
}

export default Counter