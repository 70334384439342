import React, { useState } from 'react';


import { motion } from 'framer-motion';

import { Alert } from '../../../Animations/Alerts/AlertSlicer';
import { ToggleDeliveryPop, ModePopUpDeliveredOTP, ModePopUpUnDeliverableReason } from '../../../Animations/PopUp/DeliveryBoyPopUp/DeliveryBoyPopUpSlicer';


import PersonIcon from '@mui/icons-material/Person';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded';
import { setGlobalData } from '../../../GlobalSlicer/GlobalSlicer';
import DisplayCard from '../../../Components/Card/DisplayCard';
import { PopConstants } from '../../../Animations/PopUp/PopUpManagerConstants';
import { useNavigate } from 'react-router-dom';
import PlaceIcon from '@mui/icons-material/Place';

export default function DashBoardCardList( { id, userName, phoneNumber, altPhoneNumber, hotelName, processedFoodList,
  orderedTime, dispatch, hotelImage, deliveryBoy_QR_OTP, latitude, longitude, address_Line_1,
   address_Line_2, pincode,landmark }: any ) {

  function StatusButton() {
    function ToggleOTPPopUp() {
      dispatch( ToggleDeliveryPop() )
    }
    const navigate = useNavigate();

    const [miniPop, setMiniPop] = useState( false )
    const [status, setStatus] = useState( 'Status' )
    // const pendingDoubleTap = useDoubleTap((event) => {
    //   pending()
    // });
    // const cookingDoubleTap = useDoubleTap((event) => {
    //   cooking()
    // });
    // const pickUpDoubleTap = useDoubleTap((event) => {
    //   pickUp()
    // });
    function toggleMiniPop() {
      setMiniPop( !miniPop )
    }
    function delivered() {
      toggleMiniPop()
      dispatch( ModePopUpDeliveredOTP() )
      ToggleOTPPopUp()
      dispatch( setGlobalData( { deliveryBoyOrderIDforOTP: id } ) )
    }
    function unDelivered() {
      toggleMiniPop()
      dispatch( ModePopUpUnDeliverableReason() )
      ToggleOTPPopUp()
      dispatch( setGlobalData( { deliveryBoyOrderIDforOTP: id } ) )

    }
    function showQR() {
      toggleMiniPop()
      navigate( window.location.pathname + PopConstants.QR_FOOD_DELIVERED + "&orderID=" + id + "&deliveryBoy_QR_OTP=" + deliveryBoy_QR_OTP )

    }
    return (
      <div className='absolute right-0 bottom-0 text-lg mb-3 flex w-full flex-col '>


        <div className='flex w-full  justify-end'>


          {miniPop &&
            <div className="z-10 w-44 mb-1 mr-3 bg-gray-200 rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
              <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownTopButton">
                <li onClick={() => { showQR() }}>
                  <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Show QR</span>
                </li>
                <li onClick={() => { delivered() }}>
                  <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delivered</span>
                </li>
                <li onClick={() => unDelivered()}>
                  <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Unavailable</span>
                </li>
              </ul>
            </div>

          }
        </div>

        <div className='flex flex-row justify-between px-8   items-center align-middle relative'>



          <a target="_blank"  href={`https://www.google.com/maps/dir/?api=1&destination=${ latitude },${ longitude }`}
            className="flex text-white w-28 bg-blue-700 hover:bg-blue-800  font-medium rounded-lg text-sm px-4 py-2.5 text-center items-center dark:bg-blue-600 dark:hover:bg-blue-700 " >
            <PlaceIcon sx={{ color: "#fff", fontSize: "medium" }} />
            <span>Location</span>
          </a>

          <button onClick={() => { toggleMiniPop() }}
            className="flex text-white w-28 bg-blue-700 hover:bg-blue-800  font-medium rounded-lg text-sm px-4 py-2.5 text-center items-center dark:bg-blue-600 dark:hover:bg-blue-700 " type="button">
            {status} <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </div>


      </div>

    )
  }
  let foodName = ''
  let i = 1


  const date = new Date( orderedTime ).toLocaleDateString( 'en-GB' );
  const _orderedTime = new Date( orderedTime ).toLocaleString( 'en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  } );


  const list = {
    initial: {
      x: -50,
      opacity: 0,
    },
    animation: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.7
      },

    }
  }
  return (

    <DisplayCard Head={
      <div className='relative'>
        <div className='right-0 absolute text-green-100  bg-blur-dark flex justify-center align-middle py-3 px-4'>
          ID : {id}
        </div>
        <img src={hotelImage} alt={hotelName} className="object-cover object-center w-full h-56" />
        <div className="flex justify-between px-6 py-3 bg-neutral-900">
          <div className='flex justify-center items-center'>
            <PersonIcon sx={{ color: "#fff" }} />

            <h3 className="mx-3 text-lg font-semibold text-white">{userName}</h3>
          </div>

        </div>
      </div>} BodyTop={

        <><div className='right-0 absolute dark:text-gray-100 pr-5 '>
          {date}
        </div><StatusButton /><div className=''>

            <div className='dark:text-white flex my-2 '>

              <FastfoodRoundedIcon />
              <div className='mx-2 text-md'>
                <p className=" text-gray-700 dark:text-gray-200 whitespace-pre">{processedFoodList}</p>

              </div>
            </div>

          </div></>

      } BodyLow={

        <div className='h-56'>

          <div className="flex items-center mt-4 text-gray-700 dark:text-gray-200">
            <AccessTimeRoundedIcon />
            <p className="px-2 text-sm"><b>Ordered &nbsp;&nbsp;:</b> {_orderedTime} <br /></p>
          </div>
          <div className="flex items-start mt-4 text-gray-700 dark:text-gray-200">
            <CallRoundedIcon />
            <p className="px-2 text-sm"><b>Phone &nbsp;&nbsp;:</b></p>
            <div className='text-blue-600 dark:text-blue-300'>
              <a href={`tel:+${ phoneNumber }`}>      {phoneNumber}</a><br />
              <a href={`tel:+${ altPhoneNumber }`}>   {altPhoneNumber}</a>
            </div>
          </div>
          <div className='dark:text-white flex '  >

            <StoreRoundedIcon />
            <div className='mx-2'>
              {hotelName}
            </div>
          </div>
          <div className='dark:text-white flex my-2 '>

            <HomeRoundedIcon />
            <div className='mx-2 text-sm'>
              {address_Line_1} <br />
              {address_Line_2} <br />
              {pincode} <br />
              Landmark - {landmark}

            </div>
          </div>
        </div>
      } />








  )
}

