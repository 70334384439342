import { useQuery } from '@tanstack/react-query'
import axios from 'axios';
import React from 'react'
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import { Tooltip } from "flowbite-react";

import BookIcon from '@mui/icons-material/Book';
type Props = {
  editOnly?: boolean;
  manage?: boolean;
  dashboard?: boolean;
};
const HotelOwnerHeader: React.FC<Props> = ( { editOnly = false, manage = false, dashboard = false } ) => {
  const { hotelID } = useParams();
  // console.log(manage + "" + dashboard + "" + editOnly)
  const { isLoading, isError, isSuccess, data, error } = useQuery( [`fetchHotelHeader-${ hotelID }`], () => {
    return ( axios.get( `/api/getHotelsFullDetails/${ hotelID }/` ) )
  } )
  return (
    <div className="container px-6 pt-10 md:pt-16 mx-auto flex  flex-col align-middle items-center justify-center ">

      {
        !editOnly && <>
          <motion.div
            initial={{
              x: -50,
              opacity: 0.2
            }}
            whileInView={"animation"}
            variants={{
              animation: {
                x: 0,
                opacity: 1
              }
            }}
            transition={{
              duration: 0.7
            }}
            className="text-3xl font-semibold text-gray-800  capitalize lg:text-4xl dark:text-white flex ">
            <h3 >

              {data?.data?.name?.toUpperCase()}

            </h3>



          </motion.div>
          <div className="mt-2">
            <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
            <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
            <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
          </div>

        </>}


      <div className='dark:bg-blur-dark bg-blur-light rounded-md p-4 w-fit flex flex-row justify-center align-middle'>
        {
          manage &&
          <>
            <Tooltip content="Restaurant Details" placement="top-start">

              <Link to={`/hotel/dashboard/edit/${ hotelID }`} className='   text-blue-600 dark:text-blue-300  '>
                <StoreIcon fontSize='large' />
              </Link>
            </Tooltip>

            {/* edit_list_icon.svg */}
            <Tooltip content="Edit Food" placement="top-start">

            <Link to={`/hotel/dashboard/foodListEdit/${ hotelID }`} className='text-blue-600 dark:text-blue-300 flex justify-center items-center align-middle  '>
              <img className='h-7 mt-1 pl-2 ' src="/static/logos/edit_list_icon.svg" alt="" />
            </Link>
            </Tooltip>
          </>
        }


        {dashboard &&
          <>
            <Tooltip content="New orders" placement="top-start">

              <Link to={`/hotel/dashboard/${ hotelID }`} className='ml-2  text-blue-600 dark:text-blue-300  '>
                <ShoppingCartIcon fontSize='large' />
              </Link>
            </Tooltip>
            {
              ( data?.data.isDineInHotel || data?.data.isPickupHotel ) &&
              <Tooltip content="Dine-in & Pickup">

                <Link to={`/hotel/dashboard/pickupHotel/${ hotelID }`} className=' text-blue-600 dark:text-blue-300  '>
                  <img className='h-8 ml-2' src="/static/logos/dine_in.svg" alt="" />
                </Link>
              </Tooltip>
            }

            {
              data?.data.isDeliveryHotel &&
              <Tooltip content="Delivery Order">

                <Link to={`/hotel/dashboard/deliveryHotel/${ hotelID }`} className=' text-blue-600 dark:text-blue-300  '>
                  <img className='h-7 ml-1 mt-1' src="/static/logos/bike-delivery.svg" alt="" />
                </Link>
              </Tooltip>

            }
            <Tooltip content="All Orders" placement='top-end'>
              <Link to={`/hotel/dashboard/history/${ hotelID }`} className='ml-2 text-blue-600 dark:text-blue-300  '>
                <BookIcon fontSize='large' />
              </Link>
            </Tooltip>

            {/*  */}

            {/* import BookIcon from '@mui/icons-material/Book';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptIcon from '@mui/icons-material/Receipt'; */}
          </>
        }

      </div>
    </div>

  )
}

export default HotelOwnerHeader