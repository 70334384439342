import React, { useState, useEffect } from 'react';

import AlertSuccess from './AlertSuccess';
import AlertInfo  from './AlertInfo';
import AlertError   from './AlertError';
import { useSelector } from 'react-redux';
import { RootState } from '../../App/store';

function AlertContainer(){
    const alertsArray = useSelector((state:RootState)=>state.Alert.totalAlert)

    // function Alert(status: string,heading: string,message: string){
    //     setList([{"status":status,"heading":heading,"message":message}])
    // }
    // // const [list,setList]=useState([
    //     {"status":"success","heading":"Failure","message":"1"},
    //     {"status":"info","heading":"Info","message":"2"},
    //     {"status":"error","heading":"Error","message":"3"}])
    return (
        <div className="fixed bottom-0 z-[500] ">
            {JSON.parse( JSON.stringify(alertsArray)).map((value:any)=>(
                <div key={value.id}>
                    {value.status==='success' && <AlertSuccess heading={value.heading} message={value.message} />}
                    {value.status==='info'    && <AlertInfo    heading={value.heading} message={value.message} />}
                    {value.status==='error'   && <AlertError   heading={value.heading} message={value.message} />}
                </div>

        ))}
        </div>
    )
    
}




export {AlertContainer}