import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
function Component_4() {
  return (
    <>
    <div className="text-gray-600 bg-neutral-200 dark:bg-gray-900  body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <motion.div
            initial={{
                x : -200,
                opacity:0.2
            }}
            whileInView={"animation"}
            variants={{animation:{
                x:0,
                opacity:1
            }}}
            transition={{
                duration : 0.7
            }} className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img className="object-cover object-center rounded" alt="hero" src="static/home/Pizza.jpg" />
            </motion.div>
            <motion.div
            initial={{
                x : 200,
                opacity:0.2
            }}
            whileInView={"animation"}
            variants={{animation:{
                x:0,
                opacity:1
            }}}
            transition={{
                duration : 0.7
            }} className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h2 className="title-font sm:text-4xl text-3xl mb-4 font-medium dark:text-white text-gray-900">Everyone ❤️ food
                <br className="hidden lg:inline-block" /> How about your friend?
            </h2>
            <p className=" dark:text-gray-200 mb-8 leading-relaxed text-left inline	 ">
            Ever felt boring for standing in queue. Well then may be your friend also felt the same.  A friend in need is a friend indeed so order their favourite foods on <h2 className='text-xl inline'>Kallardo</h2>
                </p>
            <div className="flex justify-center">
            <HomeOrderButton />
                {/* <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">About</button> */}
            </div>
            </motion.div>
        </div>
        
    </div>
    



    
    </>
    )
}

export function HomeOrderButton(){
    return  <Link to="Restaurants" className="inline-flex text-white base-orange border-0 py-2 px-6 focus:outline-none hover:bg-orange-600 rounded text-lg">Order Now</Link>

}


export default Component_4