import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

function Component_6() {
  return (
    <section className=" bg-neutral-200 dark:bg-gray-900  dark:text-gray-400 text-gray-600  body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col-reverse lg:flex-row ">
          <motion.div
            initial={{
              x: 200,
              opacity: 0.2,

            }}
            whileInView={"animation"}
            variants={{
              animation: {
                x: 0,
                opacity: 1,

              }
            }}
            transition={{
              duration: 0.7
            }}
            className="h-5/6 lg:w-1/2 object-contain w-full">
            <img className=" rounded-2xl shadow-md	  " src="static/home/Food_delivery.jpg" />
            {/* drop-shadow-2xl */}
          </motion.div>
          <motion.div
            initial={{
              x: -200,
              opacity: 0.2,
            }}
            whileInView={"animation"}
            variants={{
              animation: {
                x: 0,
                opacity: 1,

              }
            }}
            transition={{
              duration: 0.7
            }}

            className="flex items-center justify-center  w-full px-0 md:px-6 py-8  lg:w-1/2" >
            <div className="max-w-xl">
              <h1 className="text-4xl font-semibold text-gray-800 dark:text-white lg:text-5xl">Kallardo <span className="text-orange-500 inline-block ">Delivery</span></h1>

              <p className="my-10 text-md text-gray-600 dark:text-gray-200 lg:text-xl hidden lg:block">
                Kallardo Connects You to Flavorful Journeys from Local Restaurants.
              </p>

              <div className=" flex-col mt-6 space-y-3 lg:space-y-0 lg:flex-row hidden lg:flex">
                <Link to='Restaurants' className="inline-flex justify-center text-white  base-orange hover:bg-orange-600 border-0 py-2 px-6 focus:outline-none rounded-sm text-lg">Order Now</Link>

                <Link to='About' className="inline-flex justify-center lg:mx-4 mx-0 text-gray-700 bg-gray-50 hover:bg-gray-300 border-0 py-2 px-6 focus:outline-none rounded-sm text-lg">About</Link>

              </div>
            </div>
          </motion.div>
        </div>

      </div>
    </section>
  )
}

export default Component_6