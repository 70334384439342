import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleInStoreOTPPopUp } from './InStoreOTPPopUpSlicer';
import { usePutReq } from '../../../CustomHooks/putReq';
import { RootState } from '../../../App/store';
import RefreshWindow from '../../../Functionalities/RefreshWindow';
import JSON_Config from '../../../Constants/Axios/JSON_Config';

function InStoreOTPPopUp() {
    const dispatch = useDispatch()
    const orderID= useSelector((state:RootState)=>state.GlobalData.currentOrderID) ;

    const [inputs, setInputs] = useState({"id":orderID,otp:""})

    const { mutate:PutOrderOTP } = usePutReq(successPutOrderOTP)
    function successPutOrderOTP() {
        TogglePopUp()
        RefreshWindow()
    }
    // let setCartPopUp = 

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let name: any,value: any;
    
    
    
        name = event.target.name;
        value = event.target.value;
        
        
    
        
        setInputs(values => ({...values, [name]: value}))
      }
    
      const handleSubmit = (event: { preventDefault: () => void }) => {
        
        event.preventDefault();
        const config = JSON_Config
        const body = JSON.stringify(inputs)
        const url = '/api/hotel/food/in_store/delivered/'
        PutOrderOTP({url,body,config})
      }

    function TogglePopUp(){
        dispatch(ToggleInStoreOTPPopUp())
    }
    return (
        <>
        
    
    
    {/* // <!-- Main modal --> */}
    <motion.div
                initial={{
                    y : 300,
                }}
                whileInView={"animation"}
                variants={{animation:{
                    y:0,
    
                }}}
                transition={{
                    duration : 0.5
                }} className=" fixed py-3 md:py-28 z-40  sm:z-50 w-full md:inset-0 h-modal md:h-full justify-center items-start flex shadow-3xl"role="dialog">
        <form onSubmit={handleSubmit} className="relative px-4 pt-4 w-full max-w-sm h-full md:h-auto ">
            {/* <!-- Modal content --> */}
            <div className="relative dark:bg-blur-dark bg-blur-light rounded-lg   shadow ">
                {/* To Do  Add Redux for Close button */}
    
                {/* Add sometin for  onlclick */}
    
    
    
                
                <button type="button" onClick={()=>TogglePopUp()} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
                <div className="py-6 px-6 lg:px-8">
                    <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white text-center">Enter OTP</h3>
                    <div className="space-y-6 mild-border rounded-lg p-3"> 
                    {/* Inner Box */}
    
                    <div>
                            <label htmlFor="PhoneNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">OTP</label>
                            <input type="number" name="otp" id="deliveredOTP" value={inputs.otp} onChange={(e)=>handleChange(e)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="2468" required />
                        </div>
                        <button type="submit" className="w-full text-white base-orange hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "> Confirm </button>
                        <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                            Verify OTP from the <span className="base-orange-text">user</span>
                        </div>
    
                    </div>
                </div>
            </div>
        </form>
    </motion.div> 
    </>
    
      )
}

export default InStoreOTPPopUp