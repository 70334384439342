import axios from 'axios'
import React, { useState } from 'react'
import JSON_Config from '../../../../Constants/Axios/JSON_Config'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import TablePaginationZ from './TablePaginationZ';


function HotelProfitTable(  ) {
    const pastSevenDays = [];
    const [weeks, setWeeks] = useState(1)
    for (let i = 0; i <= (7*weeks)-1; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      pastSevenDays.push(date.toISOString().split('T')[0]);
    }
    
    
    return (
        <div   className='flex flex-col items-center  gap-9 '>
        {/* //TODO in future Upgrade <TablePaginationZ page={0}  rowsPerPages={7} count={10}  />  */}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">

               
                <tr className='text-2xl'>
                    <th scope="col" className="px-6 py-3">
                        Date
                    </th>
                    {/* <th scope="col" className="px-6 py-3 ">
                        Tax Paid
                    </th> */}
                    <th scope="col" className="px-6 py-3 ">
                        Orders
                    </th>

                    <th scope="col" className="px-6 py-3 text-green-500 uppercase text-center">
                        Sales
                    </th>
                </tr>
            </thead>

            {/* iterate ts based on data */}









            <tbody>
            {
            pastSevenDays.map((day:any) => {  return <PerDay key={day} day={day} />})  
            }

            </tbody>
        </table> 
        <button type="submit" onClick={()=>{setWeeks(weeks+1)}} className=" text-white w-fit  bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium   text-xl rounded-lg text-sm px-5 py-2.5 text-center ">
             Load More</button>
        </div>
        )
}

function PerDay({day}:any) {
    const url = "/api/get_Hotel_Dashboard_per_day_profit/"
    const { hotelID } = useParams();

    const { isLoading, isError,isSuccess, data, error } = useQuery(['fetchHotelProfit-'+ day + '-'+hotelID], () => {
        return (axios.get(url, {
            params: {
              date:day,
              hotelID:hotelID
            }
          }))
      })
      let formattedDate=""
      if(isSuccess){
        const inputDate = data.data.date;
        const parts = inputDate.split('-');
        formattedDate = `${parts[2]}-${parts[1]}-${parts[0].slice(2)}`;
      }
      
  return (
    <>
    {isSuccess  && 
    <tr  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
    
    <td className=" text-center  lg:px-6 py-4 font-bold text-lg  lg:text-left lg:text-xl">
        {formattedDate}
    </td>
    {/* <td className="px-6 py-4 font text-xl ">
        TODO
    </td> */}
    <td className="px-6 py-4 font text-xl text-center lg:text-left ">
        {data.data.total_orders}
    </td>
    <td className="px-6 py-4 flex justify-center ">
        {/* <!-- Modal toggle --> */}
        <div className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
            <button type="button" className="text-green-700 text-l hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg  px-5 py-2.5 text-center mr-2 mb-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800  w-28"> ₹{data.data.hotel_profit?data.data.hotel_profit:0}</button>
        </div>
    </td>
    
</tr>
}
    </>

  )
}

export default HotelProfitTable